export class Alert {
  type: string
  message: string
  show: boolean

  constructor (obj: Object) {
    const properties = ['type', 'message', 'show']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }
  }

  present () {
    this.show = true
  }

  dismiss () {
    this.show = false
  }
}
