import { UpdateDeviceDTO } from '../interfaces/update-device.dto'
import { CreateDeviceDTO } from '../interfaces/create-device.dto'
import { Station } from './station.class'
import { Customer } from './customer.class'
import { Pos } from './pos.class'

export class Device {
  _id: string
  name: string
  password: string
  active: boolean
  station: Station
  customer: Customer
  creationDate: Date
  pos: Pos

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'name', 'password', 'active']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.station) {
      this.station = new Station(obj.station)
    }

    if (obj.customer) {
      this.customer = new Customer(obj.customer)
    }

    if (obj.pos) {
      this.pos = new Pos(obj.pos)
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }
  }

  toUpdateDTO (): UpdateDeviceDTO {
    return {
      _id: this._id,
      active: this.active,
      station: this.station && this.station._id,
      customer: this.customer && this.customer._id,
      pos: this.pos && this.pos.id
    }
  }

  toCreateDTO (): CreateDeviceDTO {
    return {
      name: this.name,
      password: this.password,
      active: this.active,
      station: this.station && this.station._id,
      customer: this.customer && this.customer._id,
      pos: this.pos && this.pos.id
    }
  }
}
