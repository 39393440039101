import { Amount } from './amount.class'
import { PaymentProvider } from './payment-provider.class'

export class Payment {
  _id: string
  shopperMail: string
  locale: string
  countryCode: string
  amount: Amount
  paymentMethodDetails: any[]
  browserInfo: any
  paymentsResponses: any[]
  pspReference: string
  eventCode: string
  paymentNotification: any[]
  updateDate: Date
  paymentProvider: PaymentProvider
  terminalApiRequest: any
  terminalApiResponse: any

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['shopperMail', 'locale', 'countryCode',
      'paymentMethodDetails', 'browserInfo', 'paymentsResponses',
      'pspReference', 'eventCode', 'paymentNotification', 'paymentProvider',
      'terminalApiRequest', 'terminalApiResponse'
    ]

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.id) {
      this._id = obj.id
    }

    if (obj.amount) {
      this.amount = new Amount(obj.amount)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }
  }

  public toString (): string {
    return this._id
  }
}
