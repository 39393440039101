export class Car {
  _id: string
  manufacturer: string
  name: string
  consumption: number
  capacity: number
  maxSpeed: number
  active: boolean
  creationDate: Date
  updateDate: Date

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'manufacturer', 'name', 'consumption',
      'capacity', 'maxSpeed', 'active']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }

  }

  toString (): string {
    return `${this.manufacturer} ${this.name}`
  }
}
