import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../environments/environment'

import { Response } from '../classes/response.class'
import { OrderProduct } from '../classes/order-product.class'
import { Order } from '../classes/order.class'

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private orderUrl = `${environment.baseUrl}${environment.orderContext.order}`

  constructor (
    private _http: HttpClient
  ) { }

  public search (form: Object): Observable<Response<Order[]>> {
    return this._http
      .post<Response<OrderProduct[]>>(`${this.orderUrl}/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<Order[]>(
          message,
          data && data.map((order) => new Order(order))
        )
      ))
  }

  public deleteOrder (_id: string): Observable<Response<Order>> {
    return this._http
      .delete<Response<Order>>(`${this.orderUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Order>(message, new Order(data))
      ))
  }

  public getOrder (_id: string): Observable<Response<Order>> {
    return this._http
      .get<Response<Order>>(`${this.orderUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Order>(message, new Order(data))
      ))
  }

  public getInvoice (_id: string): Observable<Response<File>> {
    const headers = new HttpHeaders({ Accept: 'application/pdf' })

    return this._http
      .get(
        `${this.orderUrl}/invoice/${_id}`,
        { headers, responseType: 'blob', observe: 'response' }
      )
      .pipe(map((response) => {
        const filename = response.headers
          .get('content-disposition')
          .match(/filename=\"(.*)\"/)[1]

        return new Response<File>('OK', new File(
          [response.body],
          filename,
          { type: 'application/pdf' }
        ))
      }
    ))
  }

  public getInvoiceSample (customerId): Observable<Response<File>> {
    const headers = new HttpHeaders({ Accept: 'application/pdf' })

    return this._http
      .get(
        `${this.orderUrl}/invoice/sample/${customerId}`,
        { headers, responseType: 'blob', observe: 'response' }
      )
      .pipe(map((response) => {
        const filename = response.headers
          .get('content-disposition')
          .match(/filename=\"(.*)\"/)[1]

        return new Response<File>('OK', new File(
          [response.body],
          filename,
          { type: 'application/pdf' }
        ))
      }
    ))
  }
}
