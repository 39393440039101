import { Component, Inject, OnDestroy, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { UserService } from '../../../services/user.service'
import { UtilityService } from '../../../services/utility.service'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { Privilege } from 'src/app/classes/privilege.class'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Evccid } from '../../../classes/evccid.class'
import { RoleService } from 'src/app/services/role.service'
import { Role } from 'src/app/classes/role.class'
import { Authn } from 'src/app/classes/authn.class'

@Component({
  selector: 'app-edit-user-access',
  templateUrl: './edit-user-access.component.html',
  styleUrls: ['./edit-user-access.component.scss']
})

export class EditUserAccessComponent implements OnDestroy {
  @ViewChild(ModalComponent)
  leaveModal: ModalComponent

  @ViewChild('rolesComponent')
  rolesComponent: CheckboxContainerComponent

  public roles: Role[] = []

  public authn: string
  private subscriptions: Subscription[] = []
  public updateMode = false
  public loading = false
  public alert: Alert
  public privileges: Privilege[] = []
  public originalEvccids: Evccid[] = []

  public createUserForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    _id: new FormControl(undefined),
    roles: new FormControl([])
  })

  public editUserAccessForm: FormGroup = new FormGroup({
    email: new FormControl({ value: '', disabled: true }),
    _id: new FormControl(undefined),
    roles: new FormControl([])
  })

  constructor (
    private _translate: TranslateService,
    private _user: UserService,
    private _role: RoleService,
    public _utility: UtilityService,
    public dialogRef: MatDialogRef<EditUserAccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Authn
  ) {
    this.init()
  }

  public async init () {
    this.loading = true
    await this.getRoles()
    await this.setUpdatePage()
  }

  async setUpdatePage (): Promise<void> {
    await this.patchValueIntoForm(this.data)
    this.loading = false
  }

  ngOnDestroy () {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  public async saveData (): Promise<void> {
    this.loading = true

    try {
      const { value } = this.editUserAccessForm

      const response =  await this._user.editUserAccess({
        ...value,
        roles: this.rolesComponent.selected
        }).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(`ALERT.MESSAGE.USER_UPDATED`)
      })
      if (!this.updateMode) {
        this.createUserForm.reset()
      }
      this.createUserForm.markAsPristine()
      this.loading = false
      this.alert.present()
      // delay since there is an alert needed to be read first
      setTimeout(() => this.dialogRef.close(true), 1000)
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  public cancel () {
    this.dialogRef.close()
  }

  public async patchValueIntoForm (authn: Authn): Promise<void> {
    if (authn.roles && authn.roles.length) {
      setTimeout(() => {
        this.rolesComponent.setSelected(authn.roles.map(({ _id }) => _id))
      }, 0)
    }

    this.editUserAccessForm.patchValue(authn)
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    this.loading = false
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  private async getRoles (page = 0): Promise<void> {
    try {
      const response = await this._role
        .searchRole({ page, sort: { name: 'asc' } })
        .toPromise()

      if (response.valid) {
        this.roles = this.roles.concat(response.data.data)
      } else {
        this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      if (response.data.hasNext) {
        this.getRoles(page + 1)
      }
    } catch (error) {
      console.log('this error!', error)
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }
}
