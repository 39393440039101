import { Component, OnDestroy, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { Privilege } from 'src/app/classes/privilege.class'
import { PrivilegeService } from 'src/app/services/privilege.service'
import { UtilityService } from '../../../services/utility.service'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'

@Component({
  selector: 'app-create-privilege',
  templateUrl: './create-privilege.component.html',
  styleUrls: ['./create-privilege.component.scss']
})

export class CreatePrivilegeComponent
  extends FormCanDeactivate
  implements OnDestroy {
  @ViewChild(ModalComponent) leaveModal: ModalComponent

  private subscriptions: Subscription[] = []
  public updateMode = false
  public loading = false
  public alert: Alert

  public createPrivilegeForm: FormGroup = new FormGroup({
    _id: new FormControl(''),
    name: new FormControl('', Validators.required)
  })

  constructor (
    private route: ActivatedRoute,
    private _privilege: PrivilegeService,
    private _translate: TranslateService,
    public _utility: UtilityService
  ) {
    super()
    this.init()
  }

  public async init () {
    this.loading = true
    await this.setUpdatePage()
  }

  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe(url => {
      if (!url[0].path.includes('update')) {
        this.loading = false
        return
      }
      this.updateMode = true

      this.route.params.subscribe(async ({ _id }) => {
        await this.patchValueIntoForm(_id)
        this.loading = false
      })
    })
  }

  ngOnDestroy () {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  public async saveData (): Promise<void> {
    this.loading = true

    try {
      const value = this.createPrivilegeForm.value

      const privilege = new Privilege({
        ...value
      })

      const response = (this.updateMode)
        ? await this._privilege.updatePrivilege({
          ...privilege
        }).toPromise()
        : await this._privilege.createPrivilege({
          ...privilege
        }).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.PRIVILEGE_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })

      if (!this.updateMode) {
        this.createPrivilegeForm.reset()
      }

      this.createPrivilegeForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      const response = await this._privilege.getPrivilege(_id).toPromise()
      if (response.valid) {
        const privilege = response.data

        this.createPrivilegeForm.patchValue({
          ...privilege
        })
      } else {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
            + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    this.loading = false
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createPrivilegeForm]
  }
}
