import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from '../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent extends FormCanDeactivate implements OnInit {
  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public preferencesForm: FormGroup = new FormGroup({
    locale: new FormControl('en')
  })
  public alert: Alert
  public loading = false

  constructor (
    private _translate: TranslateService,
    private _auth: AuthService
  ) { super() }

  async ngOnInit () {
    this.preferencesForm.patchValue({ locale: this._auth.user$.value.locale })
  }

  async submit (): Promise<void> {
    try {
      this.loading = true
      const user = this._auth.user$.value
      const { locale } = this.preferencesForm.value

      // we set evs and privileges to undefined
      // as we do not want to update them
      const form = {
        ...user,
        authn: user.authn._id,
        locale,
        evs: undefined,
        privileges: undefined
      }
      const result = await this._auth.updateProfile(form)
        .toPromise()

      this._translate.use(result.data.locale)
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.PREFERENCES_UPDATED`)
      })
      this.preferencesForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.error.message)}`
        )
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.preferencesForm]
  }
}
