import { Component, ViewChild, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog, MatDialogConfig, MatPaginator, MatTableDataSource } from '@angular/material'
import { TranslateService } from '@ngx-translate/core'
import { DeviceService } from 'src/app/services/device.service'
import { Device } from 'src/app/classes/device.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { filter, switchMap } from 'rxjs/operators'
import { CreateDeviceComponent } from '../create/create-device.component'
import { PosService } from 'src/app/services/pos.service'
import { StationService } from 'src/app/services/station.service'
import { CustomerService } from 'src/app/services/customer.service'
import { DateFormat } from 'src/app/enums/date-format.enum'

@Component({
  selector: 'app-search-device',
  templateUrl: './search-device.component.html',
  styleUrls: ['./search-device.component.scss']
})
export class SearchDeviceComponent implements OnInit {
  public displayedColumns: string[]
  public dataSource: MatTableDataSource<Device>
  public loading = false
  public modal: Modal
  public alert: Alert
  public searchDeviceForm: FormGroup = new FormGroup({
    name: new FormControl(undefined),
    customer: new FormControl(undefined),
    station: new FormControl(undefined),
    pos: new FormControl(undefined),
    active: new FormControl(undefined)
  })
  public customers: any
  public stations: any
  public pos: any

  public DateFormat = DateFormat

  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor (
    private _customer: CustomerService,
    private _station: StationService,
    private _pos: PosService,
    private _translate: TranslateService,
    private _device: DeviceService,
    private _dialog: MatDialog
  ) { }

  async ngOnInit () {
    const customers = await this._customer.getAll().toPromise()
    this.customers = customers.data.data

    const station = await this._station.eloadedStation().toPromise()
    this.stations = station.data.data

    const pos = await this._pos.getAll().toPromise()
    this.pos = pos.data
  }

  public async create () {
    try {
      const dialogRef = this._dialog.open(CreateDeviceComponent, {
        panelClass: 'maxh-100-vh'
      })
      dialogRef.afterClosed()
        .pipe(
          filter((data) => !!data),
          switchMap((data) => this._device.createDevice(new Device(data))))
        .subscribe(deviceResponse => {
          if (deviceResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${deviceResponse.message}`
            })
            this.alert.present()
          }
          this.search()
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async update (device: Device) {
    try {
      const dialogConfig = new MatDialogConfig()
      dialogConfig.data = device
      dialogConfig.panelClass = 'maxh-100-vh'
      const dialogRef = this._dialog.open(CreateDeviceComponent, dialogConfig)
      dialogRef.afterClosed()
        .pipe(
          filter((data) => {
            return !!data
          }),
          switchMap((data) => {
            return this._device.updateDevice(new Device(data))
          })
        )
        .subscribe(deviceResponse => {
          if (deviceResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${deviceResponse.message}`
            })
            this.alert.present()
          }
          this.search()
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public showModal (el: Device): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_DEVICE'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_DEVICE')} ${el.name}?`,
      buttons: [
        {
          title: this._translate.instant('BUTTON.DELETE')
        }
      ],
      data: el._id
    })
    this.modal.confirm()
  }

  public applyFilter (filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public async delete (_id: string) {
    try {
      const result = await this._device.deleteDevice(_id).toPromise()
      if (result.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${result.message}`
        })
        this.alert.present()
        return
      }
      this.dataSource.data = this.dataSource.data.filter(d => d._id !== _id)
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.DEVICE_DELETED')
      })
      this.alert.present()
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async search () {
    this.loading = true
    try {
      const { value } = this.searchDeviceForm
      const device = {
        ...value
      }
      Object.keys(device).forEach(k => {
        if (device[k] === undefined) {
          delete device[k]
        }
      })
      const result = await this._device.searchDevice(device).toPromise()
      if (result.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${result.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }
      this.displayedColumns = [
        'NAME',
        'CUSTOMER',
        'ACTIVE',
        'STATION',
        'POS',
        'CREATIONDATE',
        'BUTTONS'
      ]
      result.data.data.forEach(el => {
        if (el.customer) {
          el.customer = this.customers.find(e => el.customer._id === e._id)
        }

        if (el.pos) {
          el.pos = this.pos.find(e => el.pos.id === e.id)
        }

        if (el.station) {
          el.station = this.stations.find(e => el.station._id === e._id)
        }
      })
      this.dataSource = new MatTableDataSource(result.data.data)
      this.dataSource.paginator = this.paginator
      this.loading = false
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }
}
