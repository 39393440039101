import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Response } from '../classes/response.class'
import { Car } from '../classes/car.class'

import { environment } from '../../environments/environment'
import { DataPage } from '../classes/data-page.class'

@Injectable({ providedIn: 'root' })
export class CarService {
  private baseUrl = `${environment.baseUrl}${environment.accountContext.car}`

  constructor (private http: HttpClient) { }

  public createCar (form: Car): Observable<Response<Car | null>> {
    return this.http.post<Response<Car | null>>(this.baseUrl, form)
      .pipe(map(({ message, data }) => (data)
        ? new Response<Car>(message, new Car(data))
        : new Response<null>(message, null)))
  }

  public searchCar (form?: Object): Observable<Response<DataPage<Car>>> {
    return this.http
      .post<Response<DataPage<Car>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
      !data ? new Response<undefined>( message, undefined)
      : new Response<DataPage<Car>>(
        message,
        new DataPage({ ...data, data: data.data.map(c => new Car(c)) })
        ))
      )
  }

  public updateCar (car: Car): Observable<Response<Car | null>> {
    return this.http.patch<Response<Car | null>>(`${this.baseUrl}`, car)
      .pipe(map(({ data, message }) => (data)
        ? new Response<Car>(message, new Car(data))
        : new Response<null>(message, null)))
  }

  public getCar (_id: string): Observable<Response<Car | null>> {
    return this.http.get<Response<Car | null>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) => (data)
        ? new Response<Car>(message, new Car(data))
        : new Response<null>(message, null)))
  }

  public deleteCar (_id: string): Observable<Response<Car | null>> {
    return this.http.delete<Response<Car | null>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) => (data)
        ? new Response<Car>(message, new Car(data))
        : new Response<null>(message, null)))
  }
}
