export enum FacilityType {
  HOTEL,
  RESTAURANT,
  CAFE,
  MALL,
  SUPERMARKET,
  SPORT,
  RECREATION_AREA,
  NATURE,
  MUSEUM,
  BIKE_SHARING,
  BUS_STOP,
  TAXI_STAND,
  TRAM_STOP,
  METRO_STATION,
  TRAIN_STATION,
  AIRPORT,
  PARKING_LOT,
  CARPOOL_PARKING,
  FUEL_STATION,
  WIFI
}
