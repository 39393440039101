import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class FileService {
  public multipart (obj, form?, namespace?): FormData {
    const formData = form || new FormData()

    for (const property in obj) {
      if (
        obj[property] !== undefined &&
        obj[property] !== null &&
        (
          typeof obj[property] !== 'string' ||
          (typeof obj[property] === 'string' && obj[property].trim() !== '')
        )
      ) {
        const field = (namespace) ? `${namespace}[${property}]` : property

        if (
          typeof obj[property] === 'object' &&
          !(obj[property] instanceof File || obj[property] instanceof Blob)
        ) {
          this.multipart(obj[property], formData, field)
        } else {
          formData.append(field, obj[property])
        }
      }
    }

    return formData
  }

  public base64toFile (
    base64: string,
    contentType: string,
    filename: string
  ): File {
    return new File(
      [this.b64toBlob(base64, contentType)],
      filename,
      { type: contentType })
  }

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   *
   * @param b64Data - Pure base64 string without contentType
   * @param contentType - the content type of the file i.e (image/jpeg -
   *                      image/png - text/plain)
   * @param sliceSize - SliceSize to process the byteCharacters
   * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
   * @return Blob
   */
  public b64toBlob (b64Data: string, contentType: string): Blob {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  }
}
