import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Role } from '../classes/role.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private baseUrl = `${environment.baseUrl}${environment.archContext.role}`

  constructor (private http: HttpClient) { }

  public createRole (form: Role): Observable<Response<Role>> {
    return this.http
      .post<Response<Role>>(this.baseUrl, form)
      .pipe(map(({ message, data }) =>
        new Response<Role>(message, new Role(data))
      ))
  }

  public searchRole (form?: Object): Observable<Response<DataPage<Role>>> {
    return this.http
      .post<Response<DataPage<Role>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
      new Response<DataPage<Role>>(
        message,
        new DataPage({ ...data, data: data.data.map(r => new Role(r)) })
    )))
  }

  public updateRole (role: Role): Observable<Response<Role>> {
    return this.http
      .patch<Response<Role>>(`${this.baseUrl}`, role)
      .pipe(map(({ data, message }) =>
        new Response<Role>(message, new Role(data))
      ))
  }

  public getRole (_id: string): Observable<Response<Role>> {
    return this.http
      .get<Response<Role>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ data, message }) =>
        new Response<Role>(message, new Role(data))
      ))
  }

  public deleteRole (_id: string): Observable<Response<Role>> {
    return this.http
      .delete<Response<Role>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Role>(message, new Role(data))
      ))
  }
}
