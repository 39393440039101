export class PlainValue {
  constructor (
    public readonly value: number
  ) {
    this.value = value || 0
  }
}

export class ValueChangeValues {
  public difference
  public isPositiveChange
  public isNegativeChange
  public isNoChange

  constructor (
    public readonly startValue: number,
    public readonly endValue: number
  ) {
    this.difference = this.endValue - this.startValue
    this.isPositiveChange = this.difference > 0
    this.isNegativeChange = this.difference < 0
    this.isNoChange = this.difference === 0
  }
}

export class AvgMinMaxValues {
  constructor (
    public readonly avg: number,
    public readonly min: number,
    public readonly max: number
  ) { }
}
