import { Component, OnDestroy, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { CarService } from 'src/app/services/car.service'
import { Modal } from 'src/app/classes/modal.class'
import { Car } from 'src/app/classes/car.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { DateFormat } from 'src/app/enums/date-format.enum'

@Component({
  selector: 'app-search-car',
  templateUrl: './search-car.component.html',
  styleUrls: ['./search-car.component.scss']
})
export class SearchCarComponent implements OnDestroy {

  public DateFormat = DateFormat

  public carsForm: FormGroup
  private subscriptions: Subscription[] = []
  public displayedColumns: string[]
  public dataSource: any
  public loading = false
  public modal: Modal
  public alert: Alert
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor (
    private fb: FormBuilder,
    private _car: CarService,
    private _translate: TranslateService
  ) {
    this.searchForm()
  }

  ngOnDestroy () {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  public async search (page?: number) {
    try {
      this.loading = true
      if (this.carsForm.invalid) {
        return this.markFormGroupTouched(this.carsForm)
      }
      const car = new Car(this.carsForm.value)
      const query = {}
      if (page) {
        query['page'] = page
        query['manufacturer'] = car.manufacturer
        query['name'] = car.name
        query['capacity'] = car.capacity
        query['consumption'] = car.consumption
        query['maxSpeed'] = car.maxSpeed
        query['active'] = car.active
      }
      const success = await this._car.searchCar(page ? query : car)
      .toPromise()

      if (!success.isValid()) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${success.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }
      this.displayedColumns = [
        'NAME',
        'CAPACITY',
        'CONSUMPTION',
        'MAX_SPEED',
        'CREATION_DATE',
        'UPDATE_DATE',
        'ACTIVE',
        'BUTTONS']

      if (success.data.page === 1) {
        this.dataSource = new MatTableDataSource(success.data.data)
      } else {
        this.dataSource = new MatTableDataSource(
        this.dataSource.data.concat(success.data.data))
      }
      this.dataSource.paginator = this.paginator

      if (success.data.hasNext) {
        this.search((page || 0) + 1)
      } else {
        this.loading = false
      }
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }

  private searchForm (): void {
    this.carsForm = this.fb.group({
      manufacturer: [undefined],
      name: [undefined],
      capacity: [undefined, [Validators.min(1)]],
      consumption: [undefined, [Validators.min(1)]],
      maxSpeed: [undefined, [Validators.min(1)]],
      active: [undefined]
    })
  }

  public checkRequired () {
    return !Object.values(this.carsForm.value)
      .some(field => !!(field === false || (field || '').toString().trim()))
  }

  public applyFilter (filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public checkTouched () {
    return Object.values(this.carsForm.controls).every(field => field.touched)
  }

  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public showModal (el: Car): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_CAR'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_CAR')} ${el.name}?`,
      buttons: [
        {
          title: this._translate.instant('BUTTON.DELETE')
        }
      ],
      data: el._id
    })

    this.modal.confirm()
  }

  deleteCar (data) {
    const sub = this._car.deleteCar(data).subscribe(success => {
      if (!success.isValid()) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${success.message}`
        })
        this.alert.present()
        return
      }
      this.dataSource.data = this.dataSource.data.filter(d => d._id !== data)
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.CAR_DELETED')
      })
      this.alert.present()

    }, error => {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    })

    this.subscriptions.push(sub)
  }
}
