import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  public isLoading: boolean
  @Input() loader = false
  @Input() search = false

  public toggleLoader () {
    this.isLoading = this.loader
  }
}
