import { Permission } from './permission.class'

export class Role {
  _id: string
  name: string
  permissions: Array<Permission | string>
  creationDate: Date
  updateDate: Date

  constructor (obj: Object) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'name']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['permissions']) {
      this.permissions = obj['permissions']
        .map((permission) => (typeof permission === 'object')
          ? new Permission(permission)
          : permission
        )
    }

    if (obj['creationDate']) {
      this.creationDate = new Date(obj['creationDate'])
    }

    if (obj['updateDate']) {
      this.updateDate = new Date(obj['updateDate'])
    }
  }

  public getPermissionsId (): string[] {
    return this.permissions
      .map((permission) => (typeof permission === 'object')
        ? permission._id
        : permission
      )
  }

  public toString (): string {
    return this.name
  }
}
