import { Component, ViewChild } from '@angular/core'
import { FormGroup, Validators, FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { PermissionService } from '../../../../services/permission.service'
import { ActivityService } from '../../../../services/activity.service'
import { UtilityService } from '../../../../services/utility.service'
import { Permission } from '../../../../classes/permission.class'
import { Activity } from '../../../../classes/activity.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from 'src/app/guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'

@Component({
  selector: 'app-create-permission',
  templateUrl: './create-permission.component.html',
  styleUrls: ['./create-permission.component.scss']
})
export class CreatePermissionComponent extends FormCanDeactivate {
  @ViewChild('activitiesComponent') activitiesComponent: CheckboxContainerComponent
  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public createPermissionForm: FormGroup = new FormGroup ({
    _id: new FormControl(''),
    label: new FormControl('', Validators.required),
    active: new FormControl('', Validators.required),
    app: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required)
  })
  public remoteActivities: Activity[] = []
  public updateMode = false
  public loading = true
  public alert: Alert
  public apps: { label: string; value: string }[] = [
    { label: 'App Admin', value: 'app-admin' },
    { label: 'App Mobile', value: 'app-mobile' },
    { label: 'App Dashboard', value: 'app-dashboard' },
    { label: 'App Public', value: 'app-public' },
    { label: 'App Self-Service', value: 'app-self-service' },
    { label: 'API integration', value: 'api' }
  ]

  constructor (
    private _translate: TranslateService,
    private _activity: ActivityService,
    private _permission: PermissionService,
    public _utility: UtilityService,
    private route: ActivatedRoute
  ) {
    super()
    this.init()
  }

  async init () {
    this.loading = true
    await this.getActivites()
    this.setUpdatePage()
  }

  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe(url => {
      if (!url[0].path.includes('update')) {
        this.loading = false
        return
      }
      this.updateMode = true

      this.route.params.subscribe(async ({ _id }) => {
        await this.patchValueIntoForm(_id)
        this.loading = false
      })
    })
  }

  public async saveData (): Promise<void> {
    this.loading = true
    try {
      const permission = new Permission({
        ...this.createPermissionForm.value,
        activities: this.activitiesComponent.selected
      })

      const response = (this.updateMode)
        ? await this._permission.updatePermission(permission).toPromise()
        : await this._permission.createPermission(permission).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.PERMISSION_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })
      if (!this.updateMode) {
        this.createPermissionForm.reset()
      }
      this.createPermissionForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  async getActivites (page?: number) {
    try {
      const response = await this._activity.searchActivity({ page }).toPromise()
      if (response.invalid) {
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
        return
      }
      if (!page) {
        this.remoteActivities = response.data.data.sort((a1, a2) =>
          a1.toString().localeCompare(a2.toString()))
      } else {
        this.remoteActivities = this.remoteActivities.concat(response.data.data)
        this.remoteActivities.sort((a1, a2) =>
          a1.toString().localeCompare(a2.toString()))
      }
      if (response.data.hasNext) {
        this.getActivites((page || 0) + 1)
      }
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      const response = await this._permission.getPermission(_id).toPromise()
      if (response.valid) {
        const permission = response.data

        if (permission.activities.length) {
          this.activitiesComponent.setSelected(permission.getActivitiesId())
        }

        return this.createPermissionForm.patchValue(permission)
      } else {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')} ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private openErrorAlert (message: string): void {
    this.loading = false
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createPermissionForm]
  }
}
