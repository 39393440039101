export class Pos {
  id: string
  label: string
  posId: string

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this.id = obj
      return
    }

    const properties = ['id', 'posId', 'label']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }
  }
}
