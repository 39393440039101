import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Device } from '../classes/device.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({ providedIn: 'root' })
export class DeviceService {
  private baseUrl = `${environment.baseUrl}${environment.accountContext.device}`

  constructor (private http: HttpClient) { }

  public search (form): Observable<Response<DataPage<Device>>> {
    return this.http
      .post<Response<DataPage<Device>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) => new Response<DataPage<Device>>(
        message,
        new DataPage({ ...data, data: data.data.map(c => new Device(c)) })
      )))
  }

  public getAll (): Observable<Response<DataPage<Device>>> {
    return this.http
      .get<Response<DataPage<Device>>>(this.baseUrl)
      .pipe(map(({ message, data }) => new Response<DataPage<Device>>(
        message,
        new DataPage({ ...data, data: data.data.map(c => new Device(c)) })
      )))
  }

  public createDevice (device: Device): Observable<Response<Device>> {
    return this.http
      .post<Response<Device>>(this.baseUrl, device.toCreateDTO())
      .pipe(map(({ message, data }) =>
        new Response<Device>(message, new Device(data))
      ))
  }

  public deleteDevice (_id: string): Observable<Response<Device>> {
    return this.http
      .delete<Response<Device>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Device>(message, new Device(data))
      ))
  }

  public getDevice (_id: string): Observable<Response<Device>> {
    return this.http
      .get<Response<Device>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Device>(message, new Device(data))
      ))
  }

  public searchDevice (device): Observable<Response<DataPage<Device>>> {
    return this.http
      .post<Response<DataPage<Device>>>(`${this.baseUrl}/search`, device)
      .pipe(map(({ message, data }) =>
        new Response<DataPage<Device>>(
          message,
          data && new DataPage(
            { ...data, data: data.data.map(d => new Device(d)) }
          ))
      ))
  }

  public updateDevice (device: Device): Observable<Response<Device>> {
    return this.http
      .patch<Response<Device>>(`${this.baseUrl}/${device._id}`, device.toUpdateDTO())
      .pipe(map(({ message, data }) =>
        new Response<Device>(message, new Device(data))
      ))
  }
}
