import { Component, OnDestroy, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core'
import { Modal } from 'src/app/classes/modal.class'
import { Subject, Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnChanges, OnDestroy {
  @Input() modal: Modal
  @Output() modalData = new EventEmitter<Object | null>()
  @Output() closed = new EventEmitter<boolean>()
  @ViewChild('launchModal') button: ElementRef<HTMLElement>
  @ViewChild('dismiss') buttonDismiss: ElementRef<HTMLElement>

  // Variables for leave alert if unsaved changes
  @Input() isLeaveAlert
  private observableModal = new Subject<boolean>()
  private subscription: Subscription

  constructor (
    public _translate: TranslateService
  ) { }

  ngOnDestroy () {
    if (this.isLeaveAlert && this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  present () {
    setTimeout(() => {
      this.button.nativeElement.click()
    }, 500)
  }

  confirm () {
    setTimeout(() => {
      this.button.nativeElement.click()
    }, 500)
  }

  dismiss () {
    setTimeout(() => {
      this.buttonDismiss.nativeElement.click()
    }, 500)
  }

  ngOnChanges () {
    if (this.modal) {
      switch (this.modal.type) {
        case 'present':
          this.present()
          break
        case 'confirm':
          this.confirm()
          break
        default:
          this.dismiss()
          break
      }
    }
  }

  action (data?) {
    this.modalData.emit(data)
  }

  // if the subscriber wants to be notified if the modal was closed
  close () {
    this.closed.emit(true)
  }

  public showModal (): any {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.UNSAVED_CHANGES'),
      body: this._translate.instant('MODAL.BODY.UNSAVED_CHANGES'),
      buttons: [{ title: this._translate.instant('BUTTON.LEAVE') }],
      data: true
    })

    this.modal.confirm()

    this.confirm()

    return new Promise<boolean>(resolve => {
      this.subscription = this.observableModal
        .subscribe(result => resolve(result))
    })
  }

  // emit when Modal gets closed or submitted
  public handleConfirm (data) {
    this.observableModal.next(data)
  }
}
