import { Component, Input, SimpleChanges, OnChanges } from '@angular/core'
import { FormGroup, FormArray, FormControl } from '@angular/forms'

@Component({
  selector: 'app-checkbox-container',
  templateUrl: './checkbox-container.component.html',
  styleUrls: ['./checkbox-container.component.scss']
})
export class CheckboxContainerComponent implements OnChanges {
  @Input() elements: any[]
  @Input() outputField: string

  public sessionRand = Math.floor(Math.random() * 128)

  checkboxesContainer = new FormGroup({
    search: new FormControl(),
    elements: new FormArray([])
  })
  selected: string[] = []
  touched = false

  reset () {
    this.checkboxesContainer.patchValue({ search: '' })
    this.setSelected([])
    this.touched = false
  }

  setSelected (sel: string[]) {
    const { elements } = this.checkboxesContainer.value

    const newElements = elements.map((e, i) =>
      sel.join(' ').includes(this.elements[i][this.outputField]))

    this.checkboxesContainer.patchValue({ elements: newElements })
    this.updateSelected()
  }

  ngOnChanges (changes: SimpleChanges) {
    this.elements.forEach(element =>
      (this.checkboxesContainer.controls.elements as FormArray)
        .push(new FormControl(false)))
  }

  updateSelected () {
    const formValue = this.checkboxesContainer.value.elements

    this.selected = this.elements
      .filter((e, i) => formValue[i])
      .map(e => e[this.outputField])
  }

  touch () {
    this.touched = true
  }

  isVisible (e: any): boolean {
    return !this.checkboxesContainer.get('search').value ||
      new RegExp(this.checkboxesContainer.get('search').value, 'i').test(e)
  }

  setAll (value: boolean) {
    const { elements } = this.checkboxesContainer.value
    const newElements = elements
      .map((e, i) => this.isVisible(this.elements[i]) ? value : e)
    this.checkboxesContainer.patchValue({ elements: newElements })
    this.updateSelected()
    this.touched = true
  }

}
