import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, timer } from 'rxjs'
import { map, retry, switchMap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Customer } from '../classes/customer.class'
import { FileService } from './file.service'
import { DataPage } from '../classes/data-page.class'
import { Transaction } from '../classes/transaction.class'
import { OrderService } from './order.service'
import { ExcelService } from './excel.service'
import JSZip from 'jszip'

@Injectable({ providedIn: 'root' })
export class CustomerService {
  private baseUrl = `${environment.baseUrl}${environment.accountContext.customer}`

  constructor (
    private http: HttpClient,
    private _file: FileService,
    private _order: OrderService,
    private _excel: ExcelService
  ) { }

  public search (form): Observable<Response<DataPage<Customer>>> {
    return this.http
      .post<Response<DataPage<Customer>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) => new Response<DataPage<Customer>>(
        message,
        new DataPage({ ...data, data: data.data.map(c => new Customer(c)) }))))
  }

  public searchMyOrder (form): Observable<Response<DataPage<Transaction>>> {
    return this.http
      .post<Response<DataPage<Transaction>>>(`${this.baseUrl}/me/order/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<DataPage<Transaction>>(
          message, new DataPage<Transaction>({
            ...data,
            data: data.data.map(c => new Transaction(c))
          })))
      )
  }

  public async exportCustomerCSV (customer: Customer, form) {
    const data = await this.searchOrders(customer._id, form)
    const dataExport = data.map(({ order, _id, energy, evseid }) => {
      const email = order && order.user && order.user.authn.email
      const price  = order && order.products[0].price.decimalValue
      const payment = order && order.payment
      const paymentProvider = payment && payment.paymentProvider &&
        payment.paymentProvider.name

      const device = payment && payment.terminalApiRequest
        ? 'Terminal'
        : email && email.toLowerCase().startsWith('energyboxx')
          ? 'Cashier'
          : price === 0
            ? 'PayByEV'
            : (paymentProvider || '').toLowerCase() !== 'adyen'
              ? 'External'
              : 'App'

      let orderFields = {}
      if (order) {
        orderFields = {
          invoiceNumber: order && order.invoice ? order.invoice.number : '-',
          date: order && order.creationDate.toISOString(),
          brutto: order && order.realAmount.decimalValue,
          netto: order && Math
            .round((order.realAmount.value / 119) * 100) / 100,
          user: order && order.user && order.user.authn.email || 'Guest',
          paymentReference: order && order.payment ? order.payment._id : '-',
          PSPreference: order && order.payment ? order.payment.pspReference : '-',
          orderNumber: order && order.orderNumber,
          id: order && order._id,
          tariff: `${price} €`,
          allowedPower: order && order.products[0].product.split('bis ')[1],
          paymentProvider,
          device
        }
      }
      return ({
        ...orderFields,
        transactionRef: _id,
        energy: Math.round(energy * 100) / 100,
        evseid
      })
    })
    const knownTransactions: any = {}

    for (const data of dataExport) {
      let order: Transaction
      if (knownTransactions[data.transactionRef]) {
        order = knownTransactions[data.transactionRef]
      } else {
        const orderDetail = await this.getOrder(customer, data.transactionRef)
          .toPromise()
          .catch(() => {/* do nothing */})
        order = orderDetail && orderDetail.data
        if (!order) {
          continue
        }
        knownTransactions[data.transactionRef] = order
      }
      data.startDate = order.startDate && order.startDate.toISOString()
      data.stopDate = order.stopDate && order.stopDate.toISOString()
      data.initialSoC = order.initialSoC
      data.finalSoC = order.soc
      data.evccid = order.evccid
      data.minCurrent = Math.min(
        ...(order.data.map(td => td.presentCurrent).filter(e => e)))
      data.maxCurrent = Math.max(
        ...(order.data.map(td => td.presentCurrent).filter(e => e <= 65000)))
      data.minVoltage = Math.min(
        ...(order.data.map(td => td.presentVoltage).filter(e => e)))
      data.maxVoltage = Math.max(
        ...(order.data.map(td => td.presentVoltage).filter(e => e <= 65000)))
    }

    const zip = new JSZip()
    // Push csv file into zip
    const month = `${form.start.getMonth() + 1}`.padStart(2, '0')
    const year = `${(form.start as Date).getFullYear()}`

    zip.file(
      `export-${customer.name}-${month}-${year}.csv`,
      this.getBlob(dataExport))

    // Push xlsx file into zip
    zip.file(
      `export-${customer.name}-${month}-${year}.xlsx`,
      this._excel.getBlobExcel(dataExport, 'export'))

    // Add invoices folder
    const invoices = zip.folder('invoices')

    for (const c of dataExport) {
      if (!c.invoiceNumber || c.invoiceNumber === '-') {
        continue
      }
      const response = await this._order.getInvoice(c.id).toPromise()
      invoices.file(response.data.name, (response.data as any).arrayBuffer())
    }
    zip.generateAsync({ type: 'base64' }).then((content) => {
      const link = document.createElement('a')
      link.href = 'data:application/zip;base64,' + content
      link.download = `export-${customer.name}-${month}-${year}.zip`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  private async searchOrders (customer: string, form, page = 1) {
    const r = await this.http.post<Response<DataPage<Transaction>>>(
      `${this.baseUrl}/${customer}/order/search`, { ...form, page })
      .toPromise()
    const response = new Response(
      r.message,
      new DataPage({
        ...r.data,
        data: r.data.data.map(t => new Transaction(t))
      })
    )
    if (response.invalid) {
      return
    }
    const { data } = response
    if (data.hasNext) {
      const next = await this.searchOrders(customer, form, page + 1)
      return [...data.data, ...next]
    } else {
      return data.data
    }
  }

  public getAll (): Observable<Response<DataPage<Customer>>> {
    return this.http
      .get<Response<DataPage<Customer>>>(this.baseUrl)
      .pipe(map(({ message, data }) => new Response<DataPage<Customer>>(
        message,
        new DataPage({ ...data, data: data.data.map(c => new Customer(c)) })
      )))
  }

  public createCustomer (customer: Customer): Observable<Response<Customer>> {
    return this.http
      .post<Response<Customer>>(
        this.baseUrl,
        this._file.multipart(customer.toCreateDTO())
      )
      .pipe(map(({ message, data }) =>
        new Response<Customer>(message, data && new Customer(data))
      ))
  }

  public deleteCustomer (_id: string): Observable<Response<Customer>> {
    return this.http
      .delete<Response<Customer>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Customer>(message, new Customer(data))
      ))
  }

  public getMe (): Observable<Response<Customer>> {
    return this.http
      .get<Response<Customer>>(`${this.baseUrl}/me/`)
      .pipe(map(({ message, data }) =>
        new Response<Customer>(message, new Customer(data))
      ))
  }

  public getCustomer (_id: string): Observable<Response<Customer>> {
    return this.http
      .get<Response<Customer>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Customer>(message, new Customer(data))
      ))
  }

  public searchCustomer (customer): Observable<Response<DataPage<Customer>>> {
    return this.http
      .post<Response<DataPage<Customer>>>(`${this.baseUrl}/search`, customer)
      .pipe(map(({ message, data }) =>
        new Response<DataPage<Customer>>(
          message,
          data && new DataPage(
            { ...data, data: data.data.map(c => new Customer(c)) }
          ))
      ))
  }

  public updateCustomer (customer: Customer): Observable<Response<Customer>> {
    return this.http
      .patch<Response<Customer>>(
        `${this.baseUrl}/${customer._id}`,
        this._file.multipart(customer.toUpdateDTO())
      )
      .pipe(map(({ message, data }) =>
        new Response<Customer>(message, data && new Customer(data))
      ))
  }

  public getMyOrder (_id: string): Observable<Response<Transaction>> {
    return timer(0, 5000).pipe(
      switchMap(() =>
        this.http.get<Response<Transaction>>(`${this.baseUrl}/me/order/${_id}`)
      ),
      retry(3),
      map(({ message, data }) =>
        new Response<Transaction>(message, new Transaction(data || {}))
      )
    )
  }

  public getOrder (customer: Customer, _id: string)
  : Observable<Response<Transaction>> {
    return this.http
      .get<Response<Transaction>>(`${this.baseUrl}/${customer._id}/order/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Transaction>(message, new Transaction(data || {}))
      ))
  }

  convertToCSV (objArray, headerList: any[]) {
    const array = typeof objArray !== 'object'
      ? JSON.parse(objArray)
      : objArray
    const row = headerList
      .reduce((r, header) => r + `${header},`, 'S.No,')
      .slice(0, -1)

    return array.reduce((s, r, i) =>
      `${s}${headerList.reduce((l, header) => l + `,${r[header]}`, `${(i + 1)}`)}\r\n`
      , `${row}\r\n`)
  }

  getBlob (data) {
    const allFields = data.reduce((acc, d) => {
      Object.keys(d).forEach(k => {
        if (!acc.includes(k)) { acc.push(k) }
      })
      return acc
    }, [])

    const csvData = this.convertToCSV(data, allFields)

    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csvcharset=utf-8' })
    return blob
  }
}
