import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'

import { Response } from '../classes/response.class'
import { Vendor } from '../classes/vendor.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  private baseUrl = `${environment.baseUrl}${environment.locationContext.vendor}`

  constructor (private http: HttpClient) { }

  public createVendor (vendor: Vendor): Observable<Response<Vendor>> {
    return this.http
      .post<Response<Vendor>>(this.baseUrl, vendor)
      .pipe(map(({ message, data }) =>
        new Response<Vendor>(message, new Vendor(data))))
  }

  public deleteVendor (_id: string): Observable<Response<Vendor>> {
    return this.http
      .delete<Response<Vendor>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Vendor>(message, new Vendor(data))))
  }

  public getVendor (_id: string): Observable<Response<Vendor>> {
    return this.http
      .get<Response<Vendor>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Vendor>(message, new Vendor(data))))
  }

  public searchVendor (form?: Object): Observable<Response<DataPage<Vendor>>> {
    return this.http
      .post<Response<DataPage<Vendor>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
        !data ? new Response<undefined>( message, undefined)
      : new Response<DataPage<Vendor>>(
        message,
        new DataPage({ ...data, data: data.data.map(v => new Vendor(v)) })
      ))
    )
  }

  public updateVendor (vendor: Vendor): Observable<Response<Vendor>> {
    return this.http
      .patch<Response<Vendor>>(`${this.baseUrl}/${vendor._id}`, vendor)
      .pipe(map(({ message, data }) =>
        new Response<Vendor>(message, new Vendor(data))))
  }
}
