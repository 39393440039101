export class Vendor {
  _id: string
  name: string
  description: string
  active: boolean
  creationDate: Date
  updateDate: Date

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['name', 'description', 'active', '_id']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['creationDate']) {
      this.creationDate = new Date(obj['creationDate'])
    }

    if (obj['updateDate']) {
      this.updateDate = new Date(obj['updateDate'])
    }
  }
}
