import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core'
import { UtilityService } from 'src/app/services/utility.service'

@Component({
  selector: 'app-name-value-pair',
  templateUrl: './name-value-pair.component.html',
  styleUrls: ['./name-value-pair.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NameValuePairComponent implements OnInit {

  @Input() public name: string

  @ViewChild(TemplateRef) template

  constructor (
    private vcRef: ViewContainerRef,
    private _utility: UtilityService
  ) { }

  ngOnInit () {
    this.vcRef.createEmbeddedView(this.template)
    this.name = this._utility.toTranslationString('LABEL', this.name)
  }
}
