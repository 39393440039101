import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Activity } from '../classes/activity.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private baseUrl = `${environment.baseUrl}${environment.archContext.activity}`

  constructor (private http: HttpClient) { }

  public createActivity (form: Activity): Observable<Response<Activity| null>> {
    return this.http
      .post<Response<Activity>>(this.baseUrl, form)
      .pipe(map(({ message, data }) => {
        if (data) {
          return new Response<Activity>(message, new Activity(data))
        }
        return new Response<null>(message, null)
      }
      ))
  }

  public searchActivity (form?: any):
  Observable<Response<DataPage<Activity>>> {
    return this.http
      .post<Response<DataPage<Activity>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
      !data ? new Response<undefined>( message, undefined)
      : new Response<DataPage<Activity>>(
        message,
        new DataPage({ ...data, data: data.data.map(a => new Activity(a)) })
        ))
      )
  }

  public updateActivity (activity: Activity): Observable<Response<Activity>> {
    return this.http
      .patch<Response<Activity>>(`${this.baseUrl}`, activity)
      .pipe(map(({ message, data }) =>
        new Response<Activity>(message, new Activity(data))
      ))
  }

  public getActivity (_id: string): Observable<Response<Activity>> {
    return this.http
      .get<Response<Activity>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Activity>(message, new Activity(data))
      ))
  }

  public deleteActivity (_id: string): Observable<Response<Activity>> {
    return this.http
      .delete<Response<Activity>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Activity>(message, new Activity(data))
      ))
  }
}
