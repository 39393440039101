import { Component, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { UtilityService } from '../../../services/utility.service'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'

import { TranslateService } from '@ngx-translate/core'

import { Car } from 'src/app/classes/car.class'
import { Alert } from 'src/app/classes/alert.class'

import { CarService } from 'src/app/services/car.service'
import { AlertType } from 'src/app/enums/alert-type.enum'

@Component({
  selector: 'app-create-car',
  templateUrl: './create-car.component.html',
  styleUrls: ['./create-car.component.scss']
})

export class CreateCarComponent extends FormCanDeactivate {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public carsForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    manufacturer: new FormControl(undefined, Validators.required),
    name: new FormControl(undefined, Validators.required),
    capacity: new FormControl(
      undefined,
      [Validators.required, Validators.min(1)]
    ),
    consumption: new FormControl(
      undefined,
      [Validators.required, Validators.min(1)]
    ),
    maxSpeed: new FormControl(
      undefined,
      [Validators.required, Validators.min(1)]
    ),
    active: new FormControl(true)
  })

  public updateMode = false
  public loading = false
  public alert: Alert

  constructor (
    private _car: CarService,
    private _translate: TranslateService,
    public _utility: UtilityService,
    private route: ActivatedRoute,
  ) {
    super()
    this.loading = true
    this.setUpdatePage()
  }

  // Take care of checking if the url is an update url and if is present the _id
  // in that case load the element from the server
  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe(url => {
      if (!url[0].path.includes('update')) {
        this.loading = false
        return
      }
      this.updateMode = true

      this.route.params.subscribe(async ({ _id }) => {
        await this.patchValueIntoForm(_id)
        this.loading = false
      })
    })
  }

  public async saveData (): Promise<void> {
    this.loading = true
    try {
      const car = new Car(this.carsForm.value)

      const response = (this.updateMode)
        ? await this._car.updateCar(car).toPromise()
        : await this._car.createCar(car).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.CAR_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })
      if (!this.updateMode) {
        this.carsForm.reset()
      }
      this.carsForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    this.loading = false
    return this.alert.present()
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      const response = await this._car.getCar(_id).toPromise()
      return (response.valid)
        ? this.carsForm.patchValue(response.data)
        : this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
    } catch (error) {
      console.log(error)
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.carsForm]
  }

}
