import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Input() required: string
  @Input() data: any[]
  @Input() searchAttribute: string
  @Input() returnAttribute: string
  @Input() label: string
  @Input() placeholder: string
  @Output() selectedInfo = new EventEmitter<Object>()
  public search = ''
  public selected
  public touched = false
  public predictions: any[] = []
  public labelClass

  ngOnInit () {
    this.labelClass = `label${(this.required !== undefined) ? '-required' : ''} text-left mb-0`
  }

  public updateItems (event) {
    this.search = event
    this.predictions = this.data.filter((item) =>
      new RegExp(this.search, 'i').test(item[this.searchAttribute])
    )
  }

  public takeSelection (obj) {
    this.predictions = []
    this.search = obj[this.searchAttribute]
    this.selected = obj
    this.selectedInfo.emit(obj)
  }

  public reset () {
    this.search = ''
    this.touched = false
    this.selected = null
    this.predictions = []
  }
}
