import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Permission } from '../classes/permission.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private baseUrl = `${environment.baseUrl}${environment.archContext.permission}`

  constructor (private http: HttpClient) { }

  public createPermission (form: Permission): Observable<Response<Permission>> {
    return this.http
      .post<Response<Permission>>(this.baseUrl, {
        ...form,
        activities: form.getActivitiesId()
      })
      .pipe(map(({ message, data }) =>
        new Response<Permission>(message, new Permission(data))
      ))
  }

  public searchPermission (
    form?: any
  ): Observable<Response<DataPage<Permission>>> {
    return this.http
      .post<Response<DataPage<Permission>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
      !data ? new Response<undefined>( message, undefined)
      : new Response<DataPage<Permission>>(
        message,
        new DataPage({ ...data, data: data.data.map(p => new Permission(p)) })
        ))
      )
  }

  public updatePermission (
    permission: Permission
  ): Observable<Response<Permission>> {
    const updated = { ...permission, activities: permission.getActivitiesId() }
    return this.http
      .patch<Response<Permission>>(`${this.baseUrl}`, updated)
      .pipe(map(({ data, message }) =>
        new Response<Permission>(message, new Permission(data))
      ))
  }

  public getPermission (_id: string): Observable<Response<Permission>> {
    return this.http
      .get<Response<Permission>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ data, message }) =>
        new Response<Permission>(message, new Permission(data))
      ))
  }

  public deletePermission (_id: string): Observable<Response<Permission>> {
    return this.http
      .delete<Response<Permission>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Permission>(message, new Permission(data))
      ))
  }
}
