import { Component, ViewChild, OnDestroy } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { FormGroup, Validators, FormArray, FormControl } from '@angular/forms'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { BannerService } from 'src/app/services/banner.service'
import { UtilityService } from 'src/app/services/utility.service'
import { Banner } from '../../../classes/banner.class'
import { BannerMessage } from 'src/app/interfaces/banner-message'
import { DateFormat } from 'src/app/enums/date-format.enum'

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent extends FormCanDeactivate implements OnDestroy {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public bannerForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    description: new FormControl(),
    messages: new FormArray([new FormGroup({
      locale: new FormControl('de', Validators.required),
      message: new FormControl('', Validators.required),
    }), new FormGroup({
      locale: new FormControl('en'),
      message: new FormControl(''),
    }), new FormGroup({
      locale: new FormControl('it'),
      message: new FormControl(''),
    })]),
    expireDate: new FormControl(),
    active: new FormControl(true, Validators.required)
  })
  private subscriptions: Subscription[] = []
  public loading = false
  public updateMode = false
  public alert: Alert

  public DateFormat = DateFormat

  constructor (
    private _translate: TranslateService,
    private _banner: BannerService,
    public _utility: UtilityService
  ) {
    super()
    this.init()

  }

  get messagesControl (): FormArray {
    return this.bannerForm.get('messages') as FormArray
  }

  public async init () {
    this.loading = true
    await this.patchValueIntoForm()
    this.loading = false
  }

  ngOnDestroy () {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  public checkMessages () {
    const messages = this.bannerForm.value.messages
    const newMessagesArray: Array<BannerMessage> = []
    messages.forEach(element => {
      if (element.message && element.message !== '') {
        newMessagesArray.push(element)
      }
    })
    return newMessagesArray
  }

  public async saveData () {
    this.loading = true

    try {
      const banner = new Banner({
        ...this.bannerForm.value
      })

      banner.messages = this.checkMessages()

      const response = await this._banner.putBanner(banner).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }

      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.ECHARGER_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })

      this.bannerForm.markAsPristine()
      this.loading = false

      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  public async patchValueIntoForm (): Promise<void> {
    try {
      const response = await this._banner.getBanner().toPromise()
      if (response.data) {
        this.bannerForm.patchValue(response.data)
        this.updateMode = true
      }

    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.bannerForm]
  }

}
