import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({ providedIn: 'root' })
export class SiteService {
  private baseUrl = `${environment.baseUrl}${environment.locationContext.site}`

  constructor (private http: HttpClient) { }

  public getSite (_id: string): Observable<Response<any>> {
    return this.http
      .get<Response<any>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<any>(message, data)
      ))
  }

  public updateSite (form: any): Observable<Response<any>> {
    return this.http
      .patch<Response<any>>(`${this.baseUrl}/${form._id}`, form)
      .pipe(map(({ message, data }) =>
        new Response<any>(message, data)
      ))
  }

  public createSite (form: Object): Observable<Response<any>> {
    return this.http
      .post<Response<any>>(`${this.baseUrl}`, form)
      .pipe(map(({ message, data }) =>
        new Response<any>(message, data)
      ))
  }

  public searchSite (form: Object): Observable<Response<DataPage<any>>> {
    return this.http
      .post<Response<DataPage<any>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
        !data ? new Response<undefined>(message, undefined)
          : new Response<DataPage<any>>(message, new DataPage(data)))
      )
  }

  public deleteSite (_id: string): Observable<Response<any>> {
    return this.http
      .delete<Response<any>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<any>(message, data)
      ))
  }
}
