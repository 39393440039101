import { Component, EventEmitter, OnInit, Input, Output, OnChanges, SimpleChanges } from '@angular/core'
import { ViewChild } from '@angular/core'
import {} from 'googlemaps'
import { Subject } from 'rxjs'
import { Coords } from 'src/app/classes/coords.class'

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss']
})
export class LocationPickerComponent implements OnInit, OnChanges {
  @Input() center: Coords
  @Input() station: Coords
  @Input() marker: Coords
  @Input() zoom = 18
  @Input() minZoom = 15

  @Output() position = new EventEmitter<Coords>()

  private _map = new Subject<any>()
  private _stationMarker

  defaultCenter: Coords = new Coords({
    lat: 48.3666179,
    lng: 11.0064708
  })

  @ViewChild('map') mapElement: any

  map: google.maps.Map
  infoWindow

  private locationMarker: google.maps.Marker
  private locationListener: google.maps.MapsEventListener

  ngOnInit () {
    const mapOptions: google.maps.MapOptions = {
      center: new google.maps.LatLng(
        this.center && this.center.lat && this.center.lng
          ? this.center
          : this.defaultCenter
      ),
      zoom: this.zoom,
      minZoom: this.minZoom,
      tilt: 0,
      mapTypeId: google.maps.MapTypeId.HYBRID,
    }
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions)

    this._map.next(this.map)
    this.addMapListeners()
  }

  ngOnChanges (changes: SimpleChanges) {
    if (this.station && this.station.lat && this.station.lng) {
      if (this.map) {
        this.addStation()
      } else {
        const sub = this._map.subscribe(() => {
          sub.unsubscribe()
          this.addStation()
        })
      }
    }
    if (this.marker) {
      if (this.map) {
        this.addMarker()
      } else {
        const sub = this._map.subscribe(() => {
          sub.unsubscribe()
          this.addMarker()
        })
      }
    }
    if (changes.center) {
      if (changes.center.currentValue && !changes.center.previousValue) {
        if (this.map) {
          this.map.setCenter(new google.maps.LatLng(this.center))
        } else {
          const sub = this._map.subscribe(() => {
            sub.unsubscribe()
            this.map.setCenter(new google.maps.LatLng(this.center))
          })
        }
      }
    }
  }

  private addStation () {
    if (this._stationMarker) {
      this._stationMarker.setMap(null)
    }
    this._stationMarker = new google.maps.Marker({
      position: this.station,
      map: this.map,
      icon: `assets/icons/pin-icon.svg`
    })

    const { lat, lng } = this.station

    const infowindow = this.createInfoWindow(lat, lng)

    this._stationMarker.addListener('click', () => {
      infowindow.open(this.map, this._stationMarker)
    })
  }

  private addMarker () {
    if (this.locationMarker) {
      this.locationMarker.setMap(null)
    }
    this.locationMarker = new google.maps.Marker({
      position: new google.maps.LatLng(this.marker),
      map: this.map,
    })

    const { lat, lng } = this.marker

    const infowindow = this.createInfoWindow(lat, lng)

    this.locationListener = this.locationMarker.addListener('click', () => {
      infowindow.open(this.map, this.locationMarker)
    })
  }

  private addMapListeners () {
    this.map.addListener('click', (mapsMouseEvent) => {
      if (this.locationMarker) {
        this.locationMarker.setMap(null)
        google.maps.event.removeListener(this.locationListener)
      }

      this.locationMarker = new google.maps.Marker({
        position: mapsMouseEvent.latLng,
        map: this.map,
      })

      const lat = mapsMouseEvent.latLng.lat()
      const lng = mapsMouseEvent.latLng.lng()

      this.position.emit(new Coords({ lat, lng }))

      const infowindow = this.createInfoWindow(lat, lng)

      this.locationListener = this.locationMarker.addListener('click', () => {
        infowindow.open(this.map, this.locationMarker)
      })
    })
  }

  private createInfoWindow (lat, lng): google.maps.InfoWindow {
    return new google.maps.InfoWindow({
      content:
      `<div>
        <h4>Latitude: ${lat}</h4>
        <h4>Longitude: ${lng} </h4>
      </div>`
    })
  }
}
