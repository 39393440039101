export class Response<T> {
  constructor (
    public message: string,
    public data: T
  ) { }

  isValid (): boolean {
    return this.message === 'OK'
  }

  get valid (): boolean {
    return this.message === 'OK'
  }

  get invalid (): boolean {
    return this.message !== 'OK'
  }
}
