import { Component, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog, MatDialogConfig, MatPaginator, MatTableDataSource } from '@angular/material'
import { TranslateService } from '@ngx-translate/core'
import { PaymentproviderService } from 'src/app/services/paymentprovider.service'
import { PaymentProvider } from 'src/app/classes/payment-provider.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { Observable } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'
import { Operator } from 'src/app/classes/operator.class'
import { CreatePaymentproviderComponent } from '../create/create-payment-provider.component'
import { DateFormat } from 'src/app/enums/date-format.enum'

@Component({
  selector: 'app-search-paymentprovider',
  templateUrl: './search-payment-provider.component.html',
  styleUrls: ['./search-payment-provider.component.scss']
})
export class SearchPaymentproviderComponent {
  public displayedColumns: string[]
  public dataSource: MatTableDataSource<PaymentProvider>
  public loading = false
  public modal: Modal
  public alert: Alert
  public searchPaymentproviderForm: FormGroup = new FormGroup({
    name: new FormControl(undefined),
    active: new FormControl(undefined),
  })

  @ViewChild(MatPaginator) paginator: MatPaginator
  public filteredOperators: Observable<Operator[]>

  public DateFormat = DateFormat

  constructor (
    private _translate: TranslateService,
    private _paymentprovider: PaymentproviderService,
    private _dialog: MatDialog
  ) { }

  public async create () {
    try {
      const dialogRef = this._dialog.open(CreatePaymentproviderComponent, {
        panelClass: 'maxh-100-vh'
      })
      dialogRef.afterClosed()
        .pipe(
          filter((data) => !!data),
          switchMap((data) => this._paymentprovider
            .createPaymentprovider(new PaymentProvider(data))
          )
        )
        .subscribe(paymentproviderResponse => {
          if (paymentproviderResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${paymentproviderResponse.message}`
            })
            this.alert.present()
          }

          this.search()
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async update (paymentprovider: PaymentProvider) {
    try {
      const dialogConfig = new MatDialogConfig()
      dialogConfig.data = paymentprovider
      const dialogRef = this._dialog
        .open(CreatePaymentproviderComponent, dialogConfig)
      dialogRef.afterClosed()
        .pipe(
          filter((data) => !!data),
          switchMap((data) => this._paymentprovider
            .updatePaymentprovider(new PaymentProvider(data))
          )
        )
        .subscribe(paymentproviderResponse => {
          if (paymentproviderResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${paymentproviderResponse.message}`
            })
            this.alert.present()
          }

          this.search()
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public showModal (el: PaymentProvider): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_CUSTOMER'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_CUSTOMER')} ${el.name}?`,
      buttons: [
        {
          title: this._translate.instant('BUTTON.DELETE')
        }
      ],
      data: el._id
    })
    this.modal.confirm()
  }

  public applyFilter (filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public async delete (_id: string) {
    try {
      const result = await this._paymentprovider
        .deletePaymentprovider(_id).toPromise()
      if (result.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${result.message}`
        })
        this.alert.present()
        return
      }
      this.dataSource.data = this.dataSource.data.filter(d => d._id !== _id)
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.CUSTOMER_DELETED')
      })
      this.alert.present()
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async search () {
    this.loading = true
    try {
      const { value } = this.searchPaymentproviderForm
      const paymentprovider = {
        ...value
      }
      Object.keys(paymentprovider).forEach(k => {
        if (paymentprovider[k] === undefined) {
          delete paymentprovider[k]
        }
      })
      const result = await this._paymentprovider
        .searchPaymentprovider(paymentprovider).toPromise()
      if (result.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${result.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }
      this.displayedColumns = [
        'NAME',
        'ACTIVE',
        'DESCRIPTION',
        'CREATIONDATE',
        'BUTTONS'
      ]
      this.dataSource = new MatTableDataSource(result.data.data)
      this.dataSource.paginator = this.paginator
      this.loading = false
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }
}
