import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Station } from '../classes/station.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({ providedIn: 'root' })
export class StationService {
  private baseUrl = `${environment.baseUrl}${environment.stationContext.station}`

  constructor (private http: HttpClient) { }

  public getStation (_id: string): Observable<Response<Station>> {
    return this.http
      .get<Response<Station>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Station>(message, new Station(data))
      ))
  }

  public updateStation (form: Object): Observable<Response<Station>> {
    return this.http
      .patch<Response<Station>>(`${this.baseUrl}`, form)
      .pipe(map(({ message, data }) =>
        new Response<Station>(message, new Station(data))
      ))
  }

  public createStation (form: Object): Observable<Response<Station>> {
    return this.http
      .post<Response<Station>>(`${this.baseUrl}`, form)
      .pipe(map(({ message, data }) =>
        new Response<Station>(message, new Station(data))
      ))
  }

  public searchStation (form: Object): Observable<Response<DataPage<Station>>> {
    return this.http
      .post<Response<DataPage<Station>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
        !data ? new Response<undefined>(message, undefined)
          : new Response<DataPage<Station>>(
            message,
            new DataPage({ ...data, data: data.data.map(d => new Station(d)) }))
      )
      )
  }

  public eloadedStation (): Observable<Response<DataPage<Station>>> {
    return this.http
      .get<Response<DataPage<Station>>>(`${this.baseUrl}/eloaded`)
      .pipe(map(({ message, data }) =>
        !data ? new Response<undefined>(message, undefined)
          : new Response<DataPage<Station>>(
            message,
            new DataPage({ ...data, data: data.data.map(d => new Station(d)) }))
      )
      )
  }

  public deleteStation (_id: string): Observable<Response<Station>> {
    return this.http
      .delete<Response<Station>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Station>(message, new Station(data))
      ))
  }
}
