import { Injectable } from '@angular/core'
import { Coords } from '../classes/coords.class'

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  autocompleteService: any = new google.maps.places.AutocompleteService()
  geocoder = new google.maps.Geocoder()

  /**
   * Methods related to retrieving Autocomplete predictions.
   * @param address The user entered address string.
   */
  getPlacePredictions (address) {
    return new Promise((resolve, reject) => {
      const query = { input: address }

      this.autocompleteService
        .getPlacePredictions(query, (predictions, status) => {
          if (status === 'OK') {
            resolve(predictions)
          } else {
            reject(status)
          }
        }
      )
    })
  }

  /**
   * Method for converting between an address and a LatLng.
   * @param address Address to geocode
   */
  geocodeAddress (address: string) {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({ address }, (result, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          console.log(result[0])

          resolve(result[0])
        } else {
          reject(status)
        }
      })
    })
  }

  /**
   * Method for converting between an Coords to string address.
   * @param location Coords to geodecode
   */
  geocodeLocation (location: Coords) {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({ location }, (result, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(result[0])
        } else {
          reject(status)
        }
      })
    })
  }

}
