import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, timer } from 'rxjs'
import { map, switchMap, retry } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Transaction } from '../classes/transaction.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({ providedIn: 'root' })
export class AdminService {
  private baseUrl = `${environment.baseUrl}${environment.accountContext.admin}`

  constructor (private http: HttpClient) { }

  public searchAdminOrders (form): Observable<Response<DataPage<Transaction>>> {
    return this.http
      .post<Response<DataPage<Transaction>>>(`${this.baseUrl}/order/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<DataPage<Transaction>>(
          message,
          new DataPage<Transaction>({
            ...data,
            data: data.data.map(c => new Transaction(c))
          })))
      )
  }

  public getOrder (_id: string): Observable<Response<Transaction>> {
    return this.http
      .get<Response<Transaction>>(`${this.baseUrl}/order/${_id}`)
      .pipe(
        map(({ message, data }) =>
          new Response<Transaction>(message, new Transaction(data || {}))
        ))
  }

  public getAdminOrder (_id: string): Observable<Response<Transaction>> {
    return timer(0, 5000).pipe(
      switchMap(() =>
        this.http.get<Response<Transaction>>(`${this.baseUrl}/order/${_id}`)
      ),
      retry(3),
      map(({ message, data }) =>
        new Response<Transaction>(message, new Transaction(data || {}))
      )
    )
  }
}
