import { Component, OnInit, ViewChild } from '@angular/core'
import {} from 'googlemaps'
import { TranslateService } from '@ngx-translate/core'
import { StationService } from 'src/app/services/station.service'
import * as MarkerClusterer from '@google/markerclusterer'
import { Station } from 'src/app/classes/station.class'
import { LoadingService } from 'src/app/services/loading.service'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @ViewChild('map') mapElement: any

  map: google.maps.Map
  infoWindow

  private markers = {}
  private markerCluster: any
  public stationDetail: Station

  constructor (
    private translate: TranslateService,
    private _station: StationService,
    private _loading: LoadingService
  ) { }

  ngOnInit () {
    const mapOptions = {
      center: new google.maps.LatLng(48.406631483783, 10.4392335833313),
      zoom: 10,
      minZoom: 7,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions)
    this.getGeoPos()
    this.getStations()
  }

  async requestStations () {
    let stations: Station[] = []
    let complete = false
    let page = 0

    while (!complete) {
      const results = await this._station.searchStation({ active: true })
        .toPromise()
      if (results.invalid) {
        throw new Error(results.message)
      }
      stations = stations.concat(results.data.data)
      complete = !results.data.hasNext
      page += 1
    }
    return stations
  }

  getStations () {
    const listener = this.map.addListener('idle', async () => {
      try {
        listener.remove()
        const stations = await this.requestStations()

        this.markerCluster = new MarkerClusterer(
          this.map, [], { imagePath: 'assets/icons/clusters/m' })

        stations.forEach((station: Station) => {
          if (!this.markers[station._id]) {
            const marker = this.createMarkerStation(station, this.map)
            this.markers[station._id] = { station, marker }
            this.markerCluster.addMarker(marker)

            marker.addListener('click', ((thisStation) => {
              return () => {
                this.centerPosition(thisStation.coords, false)
                this.getStationDetail(thisStation)
              }
            })(station))
          }
        })

        if (this.markerCluster) {
          this.markerCluster.redraw()
          this.markerCluster.setMaxZoom(20)
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  createMarkerStation (station, map): google.maps.Marker {
    return new google.maps.Marker({
      position: station.coords, icon: `assets/icons/pin-icon.svg`, map })
  }

  async getStationDetail (station) {
    try {
      this._loading.isLoading.next(true)

      const results = await this._station.getStation(station._id).toPromise()
      if (results.invalid) {
        throw new Error(results.message)
      }
      this.stationDetail = results.data
      this._loading.isLoading.next(false)
    } catch (error) {
      this._loading.isLoading.next(false)
      console.log(error)
    }
  }

  centerPosition (center, zoom) {
    this.map.setCenter(new google.maps.LatLng(center.lat, center.lng))
    if (zoom) {
      this.map.setOptions({ zoom: 10 })
    }
  }

  private getGeoPos () {
    this.infoWindow = new google.maps.InfoWindow

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }

        this.infoWindow.setPosition(pos)
        this.infoWindow.setContent(this.translate.instant('TEXT.YOU_ARE_HERE'))
        this.map.setCenter(pos)
      }, (error) => {
        this.handleLocationError(true, this.infoWindow, this.map.getCenter())
      })
    } else {
      // Browser doesn't support Geolocation
      this.handleLocationError(false, this.infoWindow, this.map.getCenter())
    }
  }

  private handleLocationError (browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos)
    infoWindow.setContent(browserHasGeolocation ?
      'Error: The Geolocation service failed.' :
      'Error: Your browser doesn\'t support geolocation.')
  }
}
