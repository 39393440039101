import { Authn } from './authn.class'
import { Privilege } from './privilege.class'
import { Evccid } from './evccid.class'

export class User {
  authn: Authn
  _id: string
  firstname: string
  lastname: string
  birthday: Date
  genre: string
  locale: string
  newsletter: boolean
  updateDate: Date
  privileges: Privilege[]
  evccids: Evccid[]
  evs: any[]

  constructor (obj: Object) {
    if (!obj) {
      return
    }

    if (typeof obj === 'string') {
      this.authn = new Authn(obj)
      return
    }

    const properties = ['_id', 'firstname', 'lastname', 'genre', 'locale',
      'newsletter', 'updateDate', 'privileges', 'evs']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['authn']) {
      this.authn = (obj['authn'] instanceof Authn)
        ? obj['authn']
        : new Authn(obj['authn'])
    }

    if (obj['birthday']) {
      this.birthday = new Date(obj['birthday'])
    }

    if (obj['evccids']) {
      this.evccids = obj['evccids']
        .map((evccid) => new Evccid(evccid))
    }
  }

  public getPrivilegesId (): string[] {
    return this.privileges
      .map((privilege: any) =>
        ((typeof privilege === 'object') && privilege._id) || privilege
      )
  }

  get fullName (): string {
    return this.firstname && this.lastname &&
      `${this.firstname} ${this.lastname}`
  }
}
