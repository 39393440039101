import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { PaymentProvider } from '../classes/payment-provider.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({ providedIn: 'root' })
export class PaymentproviderService {
  private baseUrl = `${environment.baseUrl}${environment.accountContext.paymentprovider}`

  constructor (private http: HttpClient) { }

  public search (form): Observable<Response<DataPage<PaymentProvider>>> {
    return this.http
      .post<Response<DataPage<PaymentProvider>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) => new Response<DataPage<PaymentProvider>>(
        message,
        new DataPage(
          { ...data, data: data.data.map(c => new PaymentProvider(c)) }
        )
      )))
  }

  public getAll (): Observable<Response<DataPage<PaymentProvider>>> {
    return this.http
      .get<Response<DataPage<PaymentProvider>>>(this.baseUrl)
      .pipe(map(({ message, data }) => new Response<DataPage<PaymentProvider>>(
        message,
        new DataPage(
          { ...data, data: data.data.map(c => new PaymentProvider(c)) }
        )
      )))
  }

  public createPaymentprovider (paymentprovider: PaymentProvider):
    Observable<Response<PaymentProvider>> {
    return this.http
      .post<Response<PaymentProvider>>(
        this.baseUrl,
        paymentprovider.toCreateDTO()
      )
      .pipe(map(({ message, data }) =>
        new Response<PaymentProvider>(message, new PaymentProvider(data))
      ))
  }

  public deletePaymentprovider (_id: string):
    Observable<Response<PaymentProvider>> {
    return this.http
      .delete<Response<PaymentProvider>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<PaymentProvider>(message, new PaymentProvider(data))
      ))
  }

  public getPaymentprovider (_id: string):
    Observable<Response<PaymentProvider>> {
    return this.http
      .get<Response<PaymentProvider>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<PaymentProvider>(message, new PaymentProvider(data))
      ))
  }

  public searchPaymentprovider (paymentprovider):
    Observable<Response<DataPage<PaymentProvider>>> {
    return this.http
      .post<Response<DataPage<PaymentProvider>>>(`${this.baseUrl}/search`, paymentprovider)
      .pipe(map(({ message, data }) => new Response<DataPage<PaymentProvider>>(
        message,
        data && new DataPage(
          { ...data, data: data.data.map(pp => new PaymentProvider(pp)) }
        )
      )))
  }

  public updatePaymentprovider (paymentprovider: PaymentProvider):
    Observable<Response<PaymentProvider>> {
    return this.http
      .patch<Response<PaymentProvider>>(`${this.baseUrl}/${paymentprovider._id}`, paymentprovider.toUpdateDTO())
      .pipe(map(({ message, data }) =>
        new Response<PaymentProvider>(message, new PaymentProvider(data))
      ))
  }
}
