import { Component, Input, OnChanges, ViewChild, ElementRef } from '@angular/core'
import { Alert } from 'src/app/classes/alert.class'

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnChanges {
  @Input() alert: Alert
  @ViewChild('alertLaunch') button: ElementRef<HTMLElement>
  flag = false

  present () {
    if (!this.flag) {
      setTimeout(() => {
        document.getElementById('alert').style.display = 'block'
        // document.getElementById('alert')
        //   .scrollIntoView({behavior: 'smooth', inline: 'nearest'})
        // this.flag = true
        // setTimeout(() => {
        // document.getElementById('header')
        //   .scrollIntoView({behavior: 'smooth', inline: 'nearest'})

        // }, 1000)
      }, 500)
    }
  }

  dismiss () {
    setTimeout(() => {
      document.getElementById('alert').style.display = 'none'
      this.flag = false
    }, 500)
  }

  ngOnChanges () {
    if (this.alert) {
      if (this.alert.show) {
        this.present()
      } else {
        this.dismiss()
      }
    }
  }
}
