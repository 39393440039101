import { Amount } from './amount.class'

export class OrderProductDetails {
  type: string
  transaction: string
  current: number
  chargeTime: number
  targetSoC: number
  evseid: string
  evccid: string
  initialSoC: number
  finalSoC: number
  maxPower: number
  stopDate: Date

  constructor (obj: any = {}) {
    const properties = ['evseid', 'evccid', 'type', 'transaction', 'current',
      'chargeTime', 'targetSoC', 'initialSoC', 'finalSoC', 'maxPower']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.stopDate) {
      this.stopDate = obj.stopDate
    }
  }
}

export class OrderProduct {
  product: string
  quantity: number
  price: Amount
  totalPrice: Amount
  details: OrderProductDetails

  constructor (obj: any = {}) {
    if (obj.product) {
      this.product = obj.product
    }

    if (obj.quantity !== undefined) {
      this.quantity = obj.quantity
    }

    if (obj.price !== undefined) {
      this.price = new Amount(obj.price)
    }

    if (obj.totalPrice !== undefined) {
      this.totalPrice = new Amount(obj.totalPrice)
    }

    if (obj.details) {
      this.details = new OrderProductDetails(obj.details)
    }
  }

  equals (b: OrderProduct): boolean {
    return this.product === b.product &&
      this.price.equals(b.price) &&
      this.quantity === b.quantity
  }

  get description (): string {
    return this.details.current
      ? `${this.price} per kWh`
      : 'Fixed price'
  }
}
