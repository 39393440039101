import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Privilege } from '../classes/privilege.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {

  private accountUrl = `${environment.baseUrl}${environment.accountContext.account}`

  constructor (
    private http: HttpClient
  ) { }

  public createPrivilege (form: Object): Observable<Response<Privilege>> {
    return this.http
      .post<Response<Privilege>>(`${this.accountUrl}/privilege`, form)
      .pipe(map(({ message, data }) =>
        new Response<Privilege>(message, new Privilege(data))
      ))
  }

  public getPrivilege (_id: string): Observable<Response<Privilege>> {
    return this.http
      .get<Response<Privilege>>(`${this.accountUrl}/privilege/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Privilege>(message, new Privilege(data))
      ))
  }

  public updatePrivilege (form: Object): Observable<Response<Privilege>> {
    return this.http
      .patch<Response<Privilege>>(`${this.accountUrl}/privilege`, form)
      .pipe(map(({ message, data }) =>
        new Response<Privilege>(message, new Privilege(data))
      ))
  }

  public deletePrivilege (_id: string): Observable<Response<Privilege>> {
    return this.http
      .delete<Response<Privilege>>(`${this.accountUrl}/privilege/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Privilege>(message, new Privilege(data))
      ))
  }

  public searchPrivilege (form?: Object)
    : Observable<Response<DataPage<Privilege>>> {
    return this.http
      .post<Response<DataPage<Privilege>>>(`${this.accountUrl}/privilege/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<DataPage<Privilege>>(
          message,
          new DataPage({ ...data, data: data.data.map(p => new Privilege(p)) })
      )))
  }
}
