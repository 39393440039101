import { Injectable } from '@angular/core'
import * as XLSX from 'xlsx'
import * as _ from 'lodash'

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=UTF-8'

@Injectable()
export class ExcelService {

  public getBlobExcel (json: any[], excelFileName: string): Blob {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX
      .write(workbook, { bookType: 'xlsx', type: 'array' })
    return this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  private saveAsExcelFile (buffer: any, fileName: string): Blob {
    return new Blob([buffer], {
      type: EXCEL_TYPE
    })
  }
}
