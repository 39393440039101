import { Coords } from './coords.class'
import { ParkZone } from '../interfaces/park-zone'
import { StationAddress } from '../interfaces/station-address'
import { Customer } from './customer.class'

export class Park {
  _id: string
  name: string
  address: StationAddress
  formattedAddress: string
  coords: Coords
  peakParkPower: number
  offerEnergy: boolean
  zones: ParkZone[]
  creationDate: Date
  updateDate: Date

  constructor (obj: Object) {
    const properties = ['_id', 'name', 'address', 'formattedAddress',
      'peakParkPower', 'offerEnergy', 'zones']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['coords']) {
      this.coords = new Coords(obj['coords'])
    }

    if (obj['creationDate']) {
      this.creationDate = new Date(obj['coords'])
    }

    if (obj['updateDate']) {
      this.updateDate = new Date(obj['coords'])
    }
  }

  get customer (): Customer {
    if (!this.zones || !this.zones.length) {
      return
    }
    const csts = this.zones.filter(z => z.customer)
    if (csts.length) {
      return csts[0].customer
    }
  }
}
