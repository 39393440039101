import { ParkStation } from '../interfaces/park-station'
import { Offer } from 'src/app/interfaces/zone-offer'
import { Customer } from './customer.class'
import { Coords } from './coords.class'

export class Zone {
  _id: string
  parkName: string
  peakParkPower: number
  offerEnergy: boolean
  station: ParkStation
  name: string
  coords: Coords
  peakZonePower: number
  peakEchargePower: number
  continuingEchargePower: number
  offers: Offer[]
  echargers: string[]
  creationDate: Date
  updateDate: Date
  customer: Customer

  constructor (obj: Object) {
    const properties = ['_id', 'parkName', 'peakParkPower', 'offerEnergy',
      'name', 'coords', 'peakZonePower', 'peakEchargePower', 'station',
      'continuingEchargePower', 'offers', 'echargers']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['creationDate']) {
      this.creationDate = new Date(obj['creationDate'])
    }

    if (obj['updateDate']) {
      this.updateDate = new Date(obj['updateDate'])
    }

    if (obj['customer']) {
      this.customer = new Customer(obj['customer'])
    }
  }
}
