import { Component, ViewChild } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { PermissionService } from '../../../../services/permission.service'
import { Permission } from '../../../../classes/permission.class'
import { ActivityService } from '../../../../services/activity.service'
import { Activity } from '../../../../classes/activity.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'
import { DateFormat } from 'src/app/enums/date-format.enum'

@Component({
  selector: 'app-search-permission',
  templateUrl: './search-permission.component.html',
  styleUrls: ['./search-permission.component.scss']
})
export class SearchPermissionComponent {
  @ViewChild('activitiesComponent') activitiesComponent: CheckboxContainerComponent
  @ViewChild(MatPaginator) paginator: MatPaginator

  public searchPermissionForm: FormGroup = new FormGroup({
    label: new FormControl(),
    app:  new FormControl(),
    active: new FormControl(),
    description: new FormControl()
  })
  public displayedColumns = ['LABEL', 'APP', 'ACTIVE', 'DESCRIPTION', 'ACTIVITIES',
    'CREATIONDATE', 'UPDATEDATE', 'BUTTONS']

  public dataSource: MatTableDataSource<Permission>
  public loading = false
  public activities: Activity[] = []
  public modal: Modal
  public alert: Alert
  public apps: { label: string; value: string }[] = [
    { label: 'App Admin', value: 'app-admin' },
    { label: 'App Mobile', value: 'app-mobile' },
    { label: 'App Dashboard', value: 'app-dashboard' },
    { label: 'App Public', value: 'app-public' },
    { label: 'App Self-Service', value: 'app-self-service' },
    { label: 'API integration', value: 'api' }
  ]

  public DateFormat = DateFormat

  constructor (
    private _translate: TranslateService,
    private _permission: PermissionService,
    private _activity: ActivityService
  ) {
    this.getActivites()
  }

  public async search (page?: number): Promise<void> {
    try {
      this.loading = true
      const query = this.searchPermissionForm.value
      if (page) {
        query['page'] = page
      }
      query.activities = this.activitiesComponent.selected

      const response = await this._permission
        .searchPermission(query).toPromise()

      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }

      if (response.data.page === 1) {
        this.dataSource = new MatTableDataSource(response.data.data)
      } else {
        this.dataSource = new MatTableDataSource(
        this.dataSource.data.concat(response.data.data))
      }
      this.dataSource.paginator = this.paginator

      if (response.data.hasNext) {
        this.search((page || 0) + 1)
      } else {
        this.loading = false
      }
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  async getActivites (page?: number) {
    try {
      const response = await this._activity.searchActivity({ page }).toPromise()
      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`
        })
        this.alert.present()
        this.loading = false
        return
      }
      if (!page) {
        this.activities = response.data.data.sort((a1, a2) =>
          a1.toString().localeCompare(a2.toString()))
      } else {
        this.activities = this.activities.concat(response.data.data)
        this.activities.sort((a1, a2) =>
          a1.toString().localeCompare(a2.toString()))
      }
      if (response.data.hasNext) {
        this.getActivites((page || 0) + 1)
      }
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`
      })
      this.alert.present()
      this.loading = false
    }
  }

  public showModal (el: Permission): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_PERMISSION'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_PERMISSION')} ${el.description}?`,
      buttons: [{ title: this._translate.instant('BUTTON.DELETE') }],
      data: el._id
    })

    this.modal.confirm()
  }

  public async delete (_id: string): Promise<boolean> {
    try {
      const response = await this._permission.deletePermission(_id).toPromise()

      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        return false
      }

      this.dataSource.data = this.dataSource.data.filter(d => d._id !== _id)
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.PERMISSION_DELETED')
      })
      this.alert.present()
      return true
    } catch (error) {
      return false
    }
  }
}
