import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { Alert } from 'src/app/classes/alert.class'
import { ModalComponent } from '../../../components/modal/modal.component'
import { PaymentProvider } from 'src/app/classes/payment-provider.class'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-create-paymentprovider',
  templateUrl: './create-payment-provider.component.html',
  styleUrls: ['./create-payment-provider.component.scss']
})
export class CreatePaymentproviderComponent implements OnInit {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public loading = true
  public alert: Alert

  // tslint:disable-next-line: member-ordering
  public createPaymentproviderForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    name: new FormControl('', [Validators.required]),
    password: new FormControl(
      '',
      [Validators.required, Validators.minLength(5)]
    ),
    passwordConfirm: new FormControl(
      '',
      [Validators.required, this.matchPassword('password')]
    ),
    description: new FormControl(''),
    active: new FormControl(true)
  })

  constructor (
    public dialogRef: MatDialogRef<CreatePaymentproviderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  async ngOnInit () {
    this.setUpdatePage()
    this.loading = false
  }

  async setUpdatePage (): Promise<void> {
    if (this.data) {
      const paymentprovider: PaymentProvider = new PaymentProvider(this.data)
      this.createPaymentproviderForm.get('name').disable()
      this.createPaymentproviderForm.removeControl('password')
      this.createPaymentproviderForm.removeControl('passwordConfirm')
      this.createPaymentproviderForm.patchValue(paymentprovider)
    }
  }

  get updateMode (): boolean {
    return !!this.data
  }

  private matchPassword (passwordControlName: string):
    (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null =>
      (
        !control.value ||
        !control.parent.get(passwordControlName).value ||
        control.parent.get(passwordControlName).value === control.value
      )
        ? null
        : { matchPassword: true }
  }

  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public submit () {
    if (this.createPaymentproviderForm.invalid) {
      return this.markFormGroupTouched(this.createPaymentproviderForm)
    }

    this.loading = true
    this.dialogRef.close(this.createPaymentproviderForm.value)
  }

  public cancel () {
    this.dialogRef.close()
  }
}
