export class Plug {
  _id: string
  dataSmall: string
  dataLong: string
  active: boolean
  creationDate: Date
  updateDate: Date

  constructor (obj: Object) {
    const properties = ['_id', 'dataSmall', 'dataLong',
      'active']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['creationDate']) {
      this.creationDate = new Date(obj['creationDate'])
    }

    if (obj['updateDate']) {
      this.updateDate = new Date(obj['updateDate'])
    }
  }

  public toString (): string {
    return this.dataLong
  }
}

export interface EchargerPlug {
  plug?: Plug
  number: number
  power?: number
  phases?: number
}
