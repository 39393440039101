import { Directive, HostListener, Input } from '@angular/core'
import { BackNavigationService } from '../services/back-navigation.service'

@Directive({
  selector: '[appBackButton]',
})
export class BackButtonDirective {
  constructor (private navigation: BackNavigationService) {}

  @Input() fallbackUrl: string

  @HostListener('click')
  onClick (): void {
    this.navigation.back(this.fallbackUrl)
  }
}
