export class Invoicing {
  _id: string
  logo: File
  header: string[]

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['logo', 'header']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }
  }
}
