import { Component, OnInit, OnDestroy, HostListener } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { Operator } from './classes/operator.class'
import { LoadingService } from './services/loading.service'
import { AuthService } from './services/auth.service'
import { filter, first } from 'rxjs/operators'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public user$ = this._auth.user$
  private subscriptions: Subscription[] = []
  public loading = false
  public operator: Operator

  constructor (
    public _translate: TranslateService,
    private _loading: LoadingService,
    private _auth: AuthService
    ) {
    // this language will be used as a fallback when a translation isn't found
    // in the current language
    _translate.setDefaultLang('en')

    // const lang = navigator.language.split('-')[0]
    // the lang to use, if the lang isn't available, it will use the current
    // loader to get them
    // console.log(/(de|en|it)/gi.test(lang) ? lang : 'en')
    // this.useBrowserLocale(lang)
  }

  ngOnInit () {
    this._auth.user$
      .pipe(filter(u => !!u), first())
      .subscribe(({ locale }) => this._translate.use(locale))
    const lang = navigator.language.split('-')[0]
    this.useBrowserLocale(lang)

    this.subscriptions.push(
      this._loading.isLoading.subscribe(isLoading => {
        this.loading = isLoading
      })
    )
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadApp ($event: any) {
    console.log('unloadApp')
    console.log(localStorage.getItem('loginInfo'))
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
    if (!loginInfo || !loginInfo.remember) {
      localStorage.removeItem('token')
    }
  }

  ngOnDestroy () {
    this.subscriptions.forEach(sub => sub.unsubscribe())
    console.log('app destroy')
  }

  public async useBrowserLocale (lang: string) {
    await this. _translate
      .use(/(de|en|it)/gi.test(lang) ? lang : 'en')
      .toPromise()
  }
}
