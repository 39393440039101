import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core'
import { Place } from 'src/app/classes/place.class'
import { Coords } from 'src/app/classes/coords.class'
import { Prediction } from 'src/app/interfaces/prediction'
import { GoogleMapsService } from 'src/app/services/google-maps.service'

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnChanges {
  @Input() addressItems: boolean
  @Input() required: string
  @Input() disabled = false
  @Input() coords: Coords
  @Input() address: string
  @Output() placeInfo = new EventEmitter<Place>()
  addressItem: Place = new Place({
    country: null,
    city: null,
    coords: Coords,
    number: null,
    street: null,
    zip: null
  })
  componentForm: any = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    country: 'long_name',
    postal_code: 'short_name'
  }
  search: any
  predictions: Prediction[] | string = ''
  backup = {
    coords: {
      lat: 0,
      lng: 0
    },
    address: ''
  }
  public labelClass

  constructor (private mapsUtility: GoogleMapsService) {
  }

  ngOnInit () {
    this.labelClass = `label${(this.required !== undefined) ? '-required' : ''} text-left mb-0`
  }

  ngOnChanges () {
    if (this.address === ' ') {
      return
    }
    if (
      this.backup.coords &&
      (
        this.coords && this.coords.lat && this.coords.lng && (
          this.backup.coords.lat !== this.coords.lat ||
          this.backup.coords.lng !== this.coords.lng
        )
      ) || (
        this.address && this.backup.address !== this.address
      )
    ) {
      this.backup = {
        coords: {
          lat: this.coords.lat,
          lng: this.coords.lng
        },
        address: this.address
      }

      if (!this.address) {
        this.generatePlace(this.coords)
      } else {
        this.search = this.address
      }
    } else {
      this.search = this.address
    }
  }

  onPlaceSelected (place: Place) {
    this.placeInfo.emit(place)
  }

  public updateAddressItem (newObj) {
    this.search = newObj
    this.addressItem = new Place({})
    this.placeInfo.emit(this.addressItem)

    if (newObj === '') {
      return
    }

    this.mapsUtility.getPlacePredictions(newObj)
      .then((predictions: any) => {
        this.predictions = predictions
      })
      .catch((error) => {
        console.log(error)
        // DO EXACTLY NOTHING
      })
  }

  public takePlace (prediction: Prediction) {
    this.predictions = []
    this.search = prediction.description
    this.mapsUtility.geocodeAddress(prediction.description)
      .then((results: any) => {
        const addressComponent = results.address_components
        const location = new Coords({
          lat: results.geometry.location.lat(),
          lng: results.geometry.location.lng()
        })
        this.addressItem.coords = location
        for (let i = 0; i < addressComponent.length; i++) {
          const addressType = addressComponent[i].types[0]
          if (this.componentForm[addressType]) {
            const val = addressComponent[i][this.componentForm[addressType]]
            switch (addressType) {
              case 'country':
                this.addressItem.country = val
                break
              case 'locality':
                this.addressItem.city = val
                break
              case 'postal_code':
                this.addressItem.zip = val
                break
              case 'route':
                this.addressItem.street = val
                break
              case 'street_number':
                this.addressItem.number = val
                break
              default:

            }
          } else {
            console.log('error geocode object')
          }
        }
        this.placeInfo.emit(this.addressItem)
      })
  }

  public generatePlace (coords: Coords) {
    this.mapsUtility.geocodeLocation(coords).then((results: any) => {
      this.search = results.formatted_address
      const addressComponent = results.address_components
      const location = new Coords({
        lat: results.geometry.location.lat(),
        lng: results.geometry.location.lng()
      })
      this.addressItem.coords = location
      for (let i = 0; i < addressComponent.length; i++) {
        const addressType = addressComponent[i].types[0]
        if (this.componentForm[addressType]) {
          const val = addressComponent[i][this.componentForm[addressType]]
          switch (addressType) {
            case 'country':
              this.addressItem.country = val
              break
            case 'locality':
              this.addressItem.city = val
              break
            case 'postal_code':
              this.addressItem.zip = val
              break
            case 'route':
              this.addressItem.street = val
              break
            case 'street_number':
              this.addressItem.number = val
              break
            default:

          }
        } else {
          console.log('error geocode object')
        }
      }
      this.placeInfo.emit(this.addressItem)
    })
  }
}
