import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { Park } from '../classes/park.class'
import { Zone } from '../classes/zone.class'
import { Response } from '../classes/response.class'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  private baseUrl =
    `${environment.baseUrl}${environment.echargeContext.zone}`

  constructor (private http: HttpClient) { }

  public createPark (form: any): Observable<Response<Zone[] | null>> {
    return this.http
      .post<Response<Zone[] | null>>(`${this.baseUrl}/park`, form)
      .pipe(map(({ message, data }) =>
        new Response<Zone[]>(message, data && data.map(zone => new Zone(zone)))
      ))
  }

  public getZone (_id: string): Observable<Response<Zone>> {
    return this.http
      .get<Response<Zone>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Zone>(message, new Zone(data))
      ))
  }

  public searchZone (form: any = {}): Observable<Response<Zone[]>> {
    return this.http
      .post<Response<Zone[]>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<Zone[]>(message, data && data.map(zone => new Zone(zone)))
      ))
  }

  public updatePark (park: any): Observable<Response<Zone[] | null>> {
    return this.http
      .patch<Response<Zone[] | null>>(`${this.baseUrl}/park`, park)
      .pipe(map(({ data, message }) =>
        new Response<Zone[]>(message, data && data.map(zone => new Zone(zone)))
      ))
  }

  public deletePark (_id: string): Observable<Response<any | null>> {
    return this.http
      .delete<Response<Park | null>>(`${this.baseUrl}/park/${_id}`)
      .pipe(map(({ message, data }) => new Response<any>(message, data)))
  }

  public deleteZone (_id: string): Observable<Response<any | null>> {
    return this.http
      .delete<Response<Park | null>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) => new Response<any>(message, data)))
  }
}
