import { Pipe, PipeTransform } from '@angular/core'
import { Place } from '../classes/place.class'

@Pipe({
  name: 'toFormattedAddress'
})
export class ToFormattedAddressPipe implements PipeTransform {
  transform (place: Place): string {
    let formattedAddress = ''

    if (place) {
      if (place.street && place.number) {
        formattedAddress += `${place.street} ${place.number}, `
      }

      if (place.city) {
        formattedAddress += `${place.city}, `
      }

      if (place.country) {
        formattedAddress += place.country
      }
    }

    return formattedAddress
  }
}
