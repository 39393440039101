import { Component, OnInit } from '@angular/core'
import { AdminService } from 'src/app/services/admin.service'
import { AuthService } from '../../services/auth.service'
import moment from 'moment'
import { CustomerService } from 'src/app/services/customer.service'

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  summary = { todaysOrders: 0, stationCount: 0, userCount: 0 }

  constructor (
    private _authn: AuthService,
    private _admin: AdminService,
    private _customer: CustomerService
  ) { }

  ngOnInit () {
    this._authn.summary().subscribe(
      ({ message, data }) => {
        if (message === 'OK' && data) {
          this.summary = { ...this.summary, ...data }
        }
        console.log(data)
      }, error => console.log(error))
    this.getTodaysOrders()
  }

  async getTodaysOrders () {
    const isCustomer = await this._customer.getMe().toPromise()
      .catch(() => { /* do nothing */})

    const start = `${moment().format(`YYYY-MM-DD`)}T00:00:00.000Z`
    const end = `${moment().add(1, 'day').format(`YYYY-MM-DD`)}T00:00:00.000Z`

    if (!!isCustomer) {
      const customerOrders = await this._customer
        .searchMyOrder({ start, end }).toPromise()
      this.summary.todaysOrders = (customerOrders.data || []).length
      return
    }
    const adminOrders = await this._admin
      .searchAdminOrders({ start, end }).toPromise()
    this.summary.todaysOrders = (adminOrders.data || []).length
  }

}
