export const baseUrls = {
  archContext: {
    authn: '/authns',
    activity: '/activities',
    role: '/roles',
    permission: '/permissions',
    banner: '/banner'
  },
  accountContext: {
    account: '/account',
    customer: '/account/customer',
    device: '/account/device',
    paymentprovider: '/account/paymentprovider',
    admin: '/account/admin',
    car: '/account/car'
  },
  mailContext: {
    mail: '/mail'
  },
  locationContext: {
    charge: `/location/charge`,
    echarger: `/location/echarger`,
    plug: `/location/plug`,
    site: `/location/site`,
    vendor: `/location/vendor`,
    connection: `/location/echarger/connection`,
    transaction: `/location/echarger/transactions`
  },
  echargeContext: {
    zone: '/echarge/zone'
  },
  orderContext: {
    order: '/order',
    pos: '/order/pos'
  },
  chargingWeb: {
    baseUrl: 'http://localhost:4300',
    station: '/station',
    charging: '/charge'
  }
}
