import { Component, OnDestroy, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import { FormGroup, Validators, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ActivityService } from '../../../../services/activity.service'
import { UtilityService } from '../../../../services/utility.service'
import { Activity } from '../../../../classes/activity.class'
import { ActivatedRoute } from '@angular/router'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from 'src/app/guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'

@Component({
  selector: 'app-create-activity',
  templateUrl: './create-activity.component.html',
  styleUrls: ['./create-activity.component.scss']
})

export class CreateActivityComponent
  extends FormCanDeactivate
  implements OnDestroy {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public createActivityForm: FormGroup = new FormGroup ({
    _id: new FormControl(''),
    url: new FormControl('', Validators.required),
    method: new FormControl('GET', Validators.required),
    urlRegex: new FormControl('', Validators.required)
  })
  private subscriptions: Subscription[] = []
  public updateMode = false
  public loading = false
  public alert: Alert

  constructor (
    private _translate: TranslateService,
    private _activity: ActivityService,
    public _utility: UtilityService,
    private route: ActivatedRoute
  ) {
    super()
    this.loading = true
    this.setUpdatePage()
  }

  ngOnDestroy () {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe(url => {
      if (!url[0].path.includes('update')) {
        this.loading = false
        return
      }
      this.updateMode = true

      this.route.params.subscribe(async ({ _id }) => {
        await this.patchValueIntoForm(_id)
        this.loading = false
      })
    })
  }

  public async saveData (): Promise<void> {
    this.loading = true
    try {
      const activity = new Activity(this.createActivityForm.value)

      const response = (this.updateMode)
        ? await this._activity.updateActivity(activity).toPromise()
        : await this._activity.createActivity(activity).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.ACTIVITY_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })
      if (!this.updateMode) {
        this.createActivityForm.reset()
      }
      this.createActivityForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      const response = await this._activity.getActivity(_id).toPromise()
      return (response.valid)
        ? this.createActivityForm.patchValue(response.data)
        : this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    this.loading = false
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createActivityForm]
  }
}
