import { Activity } from './activity.class'

export class Permission {
  _id: string
  label: string
  app: string
  active: boolean
  description: string
  activities: Array<Activity>
  creationDate: Date
  updateDate: Date

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'label', 'app', 'active', 'description']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.activities) {
      this.activities = obj.activities.map(a => new Activity(a))
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }
  }

  getActivitiesId (): string[] {
    return this.activities.map(({ _id }) => _id)
  }

  public toString (): string {
    return `${this.app} ${this.label}`
  }
}
