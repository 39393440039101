import { Component, ViewChild } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ZoneService } from 'src/app/services/zone.service'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Park } from 'src/app/classes/park.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { Customer } from 'src/app/classes/customer.class'
import { CustomerService } from 'src/app/services/customer.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-search-park',
  templateUrl: './search-park.component.html',
  styleUrls: ['./search-park.component.scss']
})
export class SearchParkComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator

  public chargingWebUrl = `${environment.chargingWeb.baseUrl}${environment.chargingWeb.station}`

  public searchParkForm: FormGroup = new FormGroup({
    name: new FormControl(),
    peakParkPower: new FormControl(undefined, Validators.min(1)),
    customer: new FormControl(undefined)
  })

  public displayedColumns: string[] =
    ['NAME', 'PEAKPARKPOWER', 'CUSTOMER', 'BUTTONS']
  public dataSource: MatTableDataSource<Park>

  private zones: Array<any> = []

  public values = []
  public loading = false
  public loadingSearch = false
  public showAll = false

  public modal: Modal
  public alert: Alert
  public customers: Customer[]

  constructor (
    private _translate: TranslateService,
    private _customer: CustomerService,
    private _zone: ZoneService
    ) {
    this.getCustomers()
  }

  private async getCustomers (page = 0): Promise<void> {
    try {
      const response = await this._customer
        .searchCustomer({ page }).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.customers = !page
        ? response.data.data
        : this.customers.concat(response.data.data)

      if (response.data.hasNext) {
        this.getCustomers(page++)
      }
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public async search () {
    try {
      this.loadingSearch = true
      const value = this.searchParkForm.value
      const query = {
        parkName: value.name,
        peakParkPower: value.peakParkPower,
        customer: value.customer
      }

      // all zones that match the querys
      const response = await this._zone.searchZone(query).toPromise()
      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        this.loadingSearch = false
        return
      }

      // get park information out of zones
      this.zones = response.data
        .map(({ _id , station, name, parkName, peakParkPower }) =>
          ({ zoneId: _id, station, name, parkName, peakParkPower }))

      const parks: Park[] = []

      if (!this.showAll) {
        const uniqueZones = this.zones.filter((zone, index, self) =>
          zone.station &&
          index === self.findIndex((z) => (
            z.parkName === zone.parkName &&
            z.peakParkPower === zone.peakParkPower
          ))
        )

        uniqueZones.forEach((e) => {
          parks.push(new Park({
            ...e,
            name: e.parkName,
            _id: e.station._id,
            zones: [response.data.find(z => z._id === e.zoneId)]
          }))
        })
      } else {
        this.zones.forEach((e) => {
          if (!e.station) {
            parks.push(new Park({ ...e, _id: e.zoneId }))
          }
        })
      }
      this.dataSource = new MatTableDataSource(parks)
      this.dataSource.paginator = this.paginator
      this.loadingSearch = false
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loadingSearch = false
    }
  }

  public showModal (pl: Park): void {
    this.modal = new Modal({
      title: this.showAll
        ? this._translate.instant('MODAL.TITLE.DELETE_ZONE')
        : this._translate.instant('MODAL.TITLE.DELETE_PARK'),
      body: this.showAll
        ? `${this._translate.instant('MODAL.BODY.DELETE_ZONE')} ${pl.name}?`
        : `${this._translate.instant('MODAL.BODY.DELETE_PARK')} ${pl.name}?`,
      buttons: [{ title: this._translate.instant('BUTTON.DELETE') }],
      data: this.showAll ? { _id: pl._id, name: pl.name } :  pl._id
    })

    this.modal.confirm()
  }

  public async delete (data: any): Promise<boolean> {
    try {
      if (!this.showAll) {
        const response = await this._zone.deletePark(data).toPromise()
        if (response.invalid) {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
          })
          this.alert.present()
          return false
        }

        this.dataSource.data = this.dataSource.data.filter(d => d._id !== data)
        this.alert = new Alert({
          type: AlertType.SUCCESS,
          message: this._translate.instant('ALERT.MESSAGE.PARK_DELETED')
        })
        this.alert.present()
        return true
      } else {
        const response = await this._zone.deleteZone(data._id).toPromise()
        if (response.invalid) {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
          })
          this.alert.present()
          return false
        }

        this.dataSource.data = this.dataSource.data
          .filter(d => !(d._id === data._id && d.name === data.name))
        this.alert = new Alert({
          type: AlertType.SUCCESS,
          message: this._translate.instant('ALERT.MESSAGE.ZONE_DELETED')
        })
        this.alert.present()
        return true
      }
    } catch (error) {
      console.log(error)
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      return false
    }
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

}
