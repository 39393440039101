import { Component, ViewChild } from '@angular/core'
import { FormGroup, Validators, FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Role } from '../../../../classes/role.class'
import { RoleService } from '../../../../services/role.service'
import { UtilityService } from '../../../../services/utility.service'
import { Permission } from '../../../../classes/permission.class'
import { PermissionService } from '../../../../services/permission.service'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from 'src/app/guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent extends FormCanDeactivate {
  @ViewChild('permissionsComponent') permissionsComponent: CheckboxContainerComponent
  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public createRoleForm: FormGroup = new FormGroup ({
    _id: new FormControl(''),
    name: new FormControl('', Validators.required)
  })
  public permissions: Permission[] = []
  public depth: undefined[][] = []
  public updateMode = false
  public loading = false
  public alert: Alert

  constructor (
    private _translate: TranslateService,
    private _permission: PermissionService,
    public _utility: UtilityService,
    private _role: RoleService,
    private route: ActivatedRoute
  ) {
    super()
      // necessary since patchvalue depends on on the other two to  finish
    this.init()
  }

  async init () {
    this.loading = true
    this.permissions = (await this.getPermissions()).sort((p1, p2) =>
      p1.toString().localeCompare(p2.toString()))
    this.setUpdatePage()
  }

  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe(url => {
      if (!url[0].path.includes('update')) {
        this.loading = false
        return
      }
      this.updateMode = true

      this.route.params.subscribe(async ({ _id }) => {
        await this.patchValueIntoForm(_id)
        this.loading = false
      })
    })
  }

  public async saveData (): Promise<void> {
    this.loading = true
    try {
      const role = new Role({
        ...this.createRoleForm.value,
        permissions: this.permissionsComponent.selected,
      })

      const response = (this.updateMode)
        ? await this._role.updateRole(role).toPromise()
        : await this._role.createRole(role).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.ROLE_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })
      if (!this.updateMode) {
        this.createRoleForm.reset()
        this.permissionsComponent.reset()
      }
      this.createRoleForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      const response = await this._role.getRole(_id).toPromise()
      if (response.valid) {
        const role = response.data

        if (role.permissions && role.permissions.length) {
          this.permissionsComponent.setSelected(role.getPermissionsId())
        }

        return this.createRoleForm.patchValue(role)
      } else {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
        + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      console.log(error)
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  async getPermissions (page: number = 0) {
    try {
      const response = await this._permission.searchPermission({ page })
        .toPromise()
      if (response.invalid) {
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
        return
      }
      if (response.data.hasNext) {
        const next = await this.getPermissions(page + 1)
        return [...response.data.data, ...next]
      } else {
        return response.data.data
      }
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private openErrorAlert (message: string): void {
    this.loading = false
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createRoleForm]
  }
}
