import { Component, Inject, OnDestroy, ViewChild } from '@angular/core'
import { FormControl, FormGroup, FormArray, Validators, ValidationErrors, AbstractControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { User } from '../../../classes/user.class'
import { UserService } from '../../../services/user.service'
import { UtilityService } from '../../../services/utility.service'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { PrivilegeService } from 'src/app/services/privilege.service'
import { Privilege } from 'src/app/classes/privilege.class'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Evccid } from '../../../classes/evccid.class'

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})

export class CreateUserComponent implements OnDestroy {
  @ViewChild(ModalComponent)
  leaveModal: ModalComponent

  @ViewChild('privilegesComponent')
  privilegesComponent: CheckboxContainerComponent

  public authn: string
  private subscriptions: Subscription[] = []
  public updateMode = false
  public loading = false
  public alert: Alert
  public privileges: Privilege[] = []
  public originalEvccids: Evccid[] = []

  public createUserForm: FormGroup = new FormGroup({
    authn: new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      _id: new FormControl(undefined)
    }),
    locale: new FormControl (undefined),
    password: new FormControl('', [Validators.required, Validators.minLength(5)]),
    passwordConfirm: new FormControl('', [Validators.required]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    genre: new FormControl(undefined),
    privileges: new FormArray([]),
    newsletter: new FormControl(false),
    evs: new FormControl([])
  },
    this.matchPassword('password', 'passwordConfirm')
  )

  constructor (
    private _translate: TranslateService,
    private _user: UserService,
    private _privilege: PrivilegeService,
    public _utility: UtilityService,
    public dialogRef: MatDialogRef<CreateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.init()
  }

  public async init () {
    this.loading = true
    await this.getPrivileges()
    await this.setUpdatePage()
  }

  async setUpdatePage (): Promise<void> {
    if (!this.data) {
      this.loading = false
      return
    }
    this.updateMode = true
    this.createUserForm.removeControl('passwordConfirm')
    this.createUserForm.removeControl('password')
    const authnV = this.createUserForm.controls.authn as FormGroup
    authnV.controls.email.disable()

    await this.patchValueIntoForm(this.data.authn._id)
    this.loading = false
  }

  ngOnDestroy () {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  public async saveData (): Promise<void> {
    this.loading = true

    try {
      const value = this.createUserForm.value

      const user = new User({
        ...value,
        genre: value.genre && value.genre.toUpperCase()
      })

      const response = (this.updateMode)
        ? await this._user.updateUser({
          ...user,
          privileges: this.privilegesComponent.selected,
          evs: value.evs.map(ev => ({
            ...ev,
            evccid: ev.evccid._id,
            model: ev.model && ev.model._id
          })),
        }).toPromise()
        : await this._user.createUser({
          ...user,
          ...user.authn,
          password: value.password
        }).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.USER_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })
      if (!this.updateMode) {
        this.createUserForm.reset()
      }
      this.createUserForm.markAsPristine()
      this.loading = false
      this.alert.present()
      // delay since there is an alert needed to be read first
      setTimeout(() => this.dialogRef.close(true), 1000)
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  public cancel () {
    this.dialogRef.close()
  }

  public deleteEv (evccid: Evccid) {
    const { evs } = this.createUserForm.value
    this.createUserForm.patchValue({
      evs: evs.filter(ev => ev.evccid._id !== evccid._id)
    })
  }

  public removeAll (): void {
    this.createUserForm.patchValue({ evs: [] })
  }

  public resetEvs (): void {
    this.createUserForm.patchValue({ evs: this.originalEvccids })
  }

  public submit () {
    if (this.createUserForm.invalid) {
      return this._utility.markFormGroupTouched(this.createUserForm)
    }
    this.loading = true
    return this.dialogRef.close(this.createUserForm.value)
  }

  private matchPassword (
    password: string,
    confirm: string
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null =>
      (
        !control.value ||
        !control.value[password] ||
        !control.value[confirm] ||
        control.value[password] === control.value[confirm]
      )
        ? null
        : { matchPassword: true }
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      const response = await this._user.getUser(_id).toPromise()
      if (response.invalid) {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      const user = response.data

      if (user.privileges && user.privileges.length) {
        this.privilegesComponent.setSelected(user.getPrivilegesId())
      }
      // It is needed that the value of evccids to be stored in the FE
      // so the user will be able to reset it to its original db saved value.
      if (user.evs && user.evs.length) {
        this.originalEvccids = [...user.evs]
      }

      this.createUserForm.patchValue({
        ...user,
        genre: user.genre && user.genre.toUpperCase()
      })
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    this.loading = false
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  private async getPrivileges (page = 0): Promise<void> {
    try {
      const response = await this._privilege
        .searchPrivilege({ page, sort: { name: 'asc' } })
        .toPromise()

      if (response.valid) {
        this.privileges = this.privileges.concat(response.data.data)
      } else {
        this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      if (response.data.hasNext) {
        this.getPrivileges(page + 1)
      }
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }
}
