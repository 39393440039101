import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Plug } from '../classes/plug.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({
  providedIn: 'root'
})
export class PlugService {
  private baseUrl = `${environment.baseUrl}${environment.echargeContext.echarge}${environment.plugContext.plug}`

  constructor (private http: HttpClient) {}

  public createPlug (form: Plug): Observable<Response<Plug>> {
    return this.http
      .post<Response<Plug>>(this.baseUrl, form)
      .pipe(
        map(({ message, data }) => new Response<Plug>(message, new Plug(data)))
      )
  }

  public searchPlug (form?: Object): Observable<Response<DataPage<Plug>>> {
    return this.http
      .post<Response<DataPage<Plug>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
        !data ? new Response<undefined>( message, undefined)
          : new Response<DataPage<Plug>>(
            message,
            new DataPage({ ...data, data: data.data.map(d => new Plug(d)) })
          ))
      )
  }

  public updatePlug (plug: Plug): Observable<Response<Plug>> {
    return this.http
      .patch<Response<Plug>>(this.baseUrl, plug)
      .pipe(
        map(({ message, data }) => new Response<Plug>(message, new Plug(data)))
      )
  }

  public getPlug (_id: string): Observable<Response<Plug>> {
    return this.http
      .get<Response<Plug>>(`${this.baseUrl}/${_id}`)
      .pipe(
        map(({ message, data }) => new Response<Plug>(message, new Plug(data)))
      )
  }

  public deletePlug (_id: string): Observable<Response<Plug>> {
    return this.http
      .delete<Response<Plug>>(`${this.baseUrl}/${_id}`)
      .pipe(
        map(({ message, data }) => new Response<Plug>(message, new Plug(data)))
      )
  }

  public getAll (): Observable<Response<Plug[]>> {
    return this.http
      .get<Response<Plug[]>>(`${this.baseUrl}/all`)
      .pipe(
        map(({ message, data }) => new Response<Plug[]>(
          message,
          data && data.map((plug) => new Plug(plug))
        ))
      )
  }
}
