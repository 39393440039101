import { Component, ViewChild, OnInit, EventEmitter } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Options, LabelType, ChangeContext } from '@angular-slider/ngx-slider'
import moment, { Moment } from 'moment'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { CustomerService } from 'src/app/services/customer.service'
import { Transaction } from 'src/app/classes/transaction.class'
import { AdminService } from 'src/app/services/admin.service'
import { DateFormat } from 'src/app/enums/date-format.enum'
import { TransactionService } from 'src/app/services/transaction.service'
import { ExcelService } from 'src/app/services/excel.service'
import JSZip from 'jszip'
import { environment } from 'src/environments/environment'
import { environment as prodEnvironment } from 'src/environments/environment.prod'

interface State {
  name: string,
  precharge: boolean
}

interface DateRange {
  start: Moment,
  end: Moment
}

@Component({
  selector: 'app-charging-history-admin',
  templateUrl: './charging-history-admin.component.html',
  styleUrls: ['./charging-history-admin.component.scss']
})
export class ChargingHistoryAdminComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator

  public DateFormat = DateFormat

  public customers: any
  public states: State[] = [
    { name: 'option.canceled_charge_history', precharge: true },
    { name: 'option.completed_charge_history', precharge: false }
  ]
  public defaultState = this.states[1].precharge
  private stateFormControl = new FormControl(this.defaultState)
  public searchOrderForm: FormGroup = new FormGroup({
    email: new FormControl(),
    orderNumber: new FormControl(),
    evccid: new FormControl(),
    evseid: new FormControl(),
    customer: new FormControl(),
    prechargeOnly: this.stateFormControl
  })

  public displayedColumns = ['ORDERNUMBER', 'USER', 'CUSTOMER', 'EVSEID',
    'STATUS', 'QUANTITY', 'DATE', 'BUTTONS']
  public dataSource: MatTableDataSource<Transaction>

  loadingSearch = false

  public modal: Modal
  public alert: Alert

  // Time slider values
  // One day in milliseconds
  public options: Options = {
    floor: 0,
    ceil: 86399999,
    step: 1000,
    disabled: true,
    hideLimitLabels: true,
    translate: (value: number, label: LabelType): string => {
      if (
        !this.selected ||
        !this.selected.start ||
        this.selected.start.startOf('day').toISOString() ===
          this.selected.end.startOf('day').toISOString()
      ) {
        return new Date(value).toISOString().slice(11, 16)
      } else {
        switch (label) {
          case LabelType.High:
            return this.crossed
              ? 'Start: ' + new Date(value).toISOString().slice(11, 16)
              : 'End: ' + new Date(value).toISOString().slice(11, 16)
          case LabelType.Low:
            return this.crossed
              ? 'End: ' + new Date(value).toISOString().slice(11, 16)
              : 'Start: ' + new Date(value).toISOString().slice(11, 16)
          default:
            return new Date(value).toISOString().slice(11, 16)
        }
      }
    }
  }

  // date range
  public defaultDateRange: DateRange = {
    start: moment().add(-1, 'w'),
    end: moment()
  }
  public selected: DateRange = this.defaultDateRange

  // when user puts low value over high value
  public crossed = false
  public sliderValues: number[] = [0, 86399999]
  public minValue = this.sliderValues[0]
  public maxValue = this.sliderValues[1]
  public manualRefresh: EventEmitter<void> = new EventEmitter<void>()

  constructor (
    private _translate: TranslateService,
    private _transaction: TransactionService,
    private _customer: CustomerService,
    public _admin: AdminService,
    public _excel: ExcelService
  ) { }

  public isProdEnv (): boolean {
    return environment.baseUrl === prodEnvironment.baseUrl
  }

  async ngOnInit () {
    const customer = await this._customer.getAll().toPromise()
    this.customers = customer.data.data
  }

  public async search () {
    try {
      this.loadingSearch = true
      const values = this.searchOrderForm.value
      const form: any = {}

      form.prechargeOnly = values.prechargeOnly

      if (values.orderNumber && !form.prechargeOnly) {
        form.orderNumber = values.orderNumber
      }

      if (values.email && !form.prechargeOnly) {
        form.user = values.email
      }

      if (values.evseid) {
        form.evseid = values.evseid
      }

      if (values.evccid) {
        form.evccid = values.evccid
      }

      if (values.customer) {
        form.customer = values.customer
      }

      let startString: string
      let endString: string
      if (this.selected.start) {
        const startDateString = this.transformDate(this.selected.start)
          .toISOString().slice(0, 10)

        const endDateString = this.transformDate(this.selected.end)
          .toISOString().slice(0, 10)

        const isDifferentDate = startDateString !== endDateString

        const startSliderDate =
          new Date(this.sliderValues[this.crossed && isDifferentDate ? 1 : 0])

        const stopSliderDate =
          new Date(this.sliderValues[this.crossed && isDifferentDate ? 0 : 1])

        startString = `${startDateString}T${startSliderDate.toISOString().slice(11, 16)}`

        endString = `${endDateString}T${stopSliderDate.toISOString().slice(11, 16)}`

        form['start'] = new Date(startString).toISOString()
        form['end'] = new Date(endString).toISOString()
      }

      const response = await this.searchOrders(form)
      console.log(response)
      const transactions = response.map((transaction) => {
        transaction.customer = transaction.customer &&
          transaction.customer._id &&
          this.customers.find(({ _id }) =>
            _id.toString() === transaction.customer._id.toString()
          )
        return transaction
      })

      this.dataSource = new MatTableDataSource(transactions)
      this.dataSource.paginator = this.paginator
      this.loadingSearch = false
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`)
      this.loadingSearch = false
    }
  }

  private async searchOrders (form, page = 1) {
    const response = await this._admin.searchAdminOrders({ ...form, page })
      .toPromise()
    if (response.invalid) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`)
      this.loadingSearch = false
      return
    }
    const { data } = response
    if (data.hasNext) {
      const next = await this.searchOrders(form, page + 1)
      return [...data.data, ...next]
    } else {
      return data.data
    }
  }

  public resetForm (): void {
    this.resetSlider()
    this.searchOrderForm.reset({
      prechargeOnly: this.defaultState
    })
  }

  public transformDate (dateIn): Date {
    const date = new Date(dateIn)
    date.setDate(date.getDate() + 1)
    return date
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  public async onExport () {
    const data = this.dataSource.data.map(t => ({
      transactionID: t._id,
      startDateTimeUtc: t.startDate.toISOString(),
      stopDateTimeUtc: (t.stopDate && t.stopDate.toISOString()) ||
        (t.order && t.order.products[0].details.stopDate),
      startSOC: t.initialSoC ||
        (t.order && t.order.products[0].details.initialSoC),
      stopSOC: t.soc || (t.order && t.order.products[0].details.finalSoC),
      totalEnergyInKWh: t.energy,
      user: t.order && t.order.user && t.order.user.authn.email,
      evseid: t.evseid,
      Evccid: t.evccid || (t.order && t.order.products[0].details.evccid),
      orderID: t.order && t.order._id,
      brutto: t.order && t.order.realAmount.decimalValue,
      netto: t.order &&
        (t.order.realAmount.decimalValue / 119 * 100).toFixed(2),
      price: t.order && t.order.products[0].price.decimalValue,
      tariff: t.order && t.order.products[0].details.maxPower
    }))
    for (const t of data) {
      if (!t.stopDateTimeUtc) {
        const res = await this._transaction
          .getTransaction(t.transactionID).toPromise()
          // ignore error as the transaction is missing.
          .catch(e => { console.log(e) })
        if (res && res.message === 'OK') {
          const { stopDate, startDate } = res.data
          t.stopDateTimeUtc = stopDate && stopDate.toISOString()
          t.startDateTimeUtc = startDate.toISOString()
          if (!t.stopSOC) {
            t.stopSOC = res.data.soc
          }
        }
      }
    }
    const zip = new JSZip()
    zip.file(`export.xlsx`, this._excel.getBlobExcel(data, 'export'))

    zip.generateAsync({ type: 'base64' }).then((content) => {
      const link = document.createElement('a')
      link.href = 'data:application/zip;base64,' + content
      link.download = `export.zip`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  // Due to the way Angular 2+ handles change detection, we have to create a new
  // options object. */
  onChangeReadOnly (): void {
    this.options = {
      ...this.options,
      disabled: !(this.selected && this.selected.start && this.selected.end)
    }
  }

  public onUserChange (changeContext: ChangeContext): void {
    if (changeContext.value !== this.sliderValues[0] &&
      changeContext.highValue !== this.sliderValues[1]) {
      this.crossed = !this.crossed
      this.manualRefresh.emit()
      this.sliderValues = [changeContext.value, changeContext.highValue]
    }
  }

  public onUserChangeEnd (changeContext: ChangeContext): void {
    this.sliderValues = [changeContext.value, changeContext.highValue]
  }

  public resetSlider () {
    this.minValue = 0
    this.maxValue = 86399999
    this.sliderValues = [0, 86399999]
    this.crossed = false
    this.options = Object.assign({}, this.options, { disabled: true })
    this.selected = this.defaultDateRange
  }
}
