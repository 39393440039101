import { Injectable } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

interface FileType {
  magicNumber?: RegExp,
  mimeType: string,
}

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  // https://en.wikipedia.org/wiki/Magic_number_(programming)#In_files
  private fileTypes: { [key: string]: FileType } = {
    jpeg: {
      magicNumber: /^\/9j\/[4AAQSkZJRgAB | 7g | 2w]/,
      mimeType: 'image/jpeg'
    },
    png: {
      magicNumber: /^iVBORw0KGgo/,
      mimeType: 'image/png'
    },
    svg: {
      mimeType: 'image/svg+xml'
    },
  }

  constructor (
    private _domSanitizer: DomSanitizer
  ) {
  }

  public base64ToUrl (base64: string): SafeUrl {

    for (const type in this.fileTypes) {
      if (
        this.fileTypes[type].magicNumber &&
        base64.match(this.fileTypes[type].magicNumber)
      ) {
        return this.getUrlFromMimeTypeAndBase64(
          this.fileTypes[type].mimeType,
          base64
        )
      }
    }

    // case: svg from server
    if (!base64.match(/^data:/)) {
      const url = this.getUrlFromMimeTypeAndBase64(
        this.fileTypes.svg.mimeType,
        base64
      )

      return this._domSanitizer.bypassSecurityTrustUrl(url)
    }

    // case: image selected
    return this._domSanitizer.bypassSecurityTrustUrl(base64)
  }

  private getUrlFromMimeTypeAndBase64 (
    mimeType: string,
    base64: string
  ): string {
    return `data:${mimeType};base64,${base64}`
  }
}
