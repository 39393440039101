import { Component, OnDestroy, ViewChild } from '@angular/core'
import { EchargerService } from 'src/app/services/echarger.service'
import { ZoneService } from 'src/app/services/zone.service'
import { FormGroup, Validators, FormArray, FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { UtilityService } from 'src/app/services/utility.service'
import { StationService } from 'src/app/services/station.service'
import { ActivatedRoute, Router } from '@angular/router'
import { Echarger } from 'src/app/classes/echarger.class'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Zone } from 'src/app/classes/zone.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { Station } from 'src/app/classes/station.class'
import { DateFormat } from 'src/app/enums/date-format.enum'

@Component({
  selector: 'app-link-zone',
  templateUrl: './link-zone.component.html',
  styleUrls: ['./link-zone.component.scss']
})
export class LinkZoneComponent extends FormCanDeactivate implements OnDestroy {

  @ViewChild(ModalComponent) leaveModal: ModalComponent
  @ViewChild(MatPaginator) paginator: MatPaginator

  public zoneForm = new FormGroup({
    _id: new FormControl(undefined),
    name: new FormControl(undefined),
    echargers: new FormArray([new FormGroup({
      echarger: new FormControl(undefined)
    })]),
    peakZonePower: new FormControl(undefined),
    peakEchargePower: new FormControl(undefined),
    continuingEchargePower: new FormControl(undefined),
    maxChargePower: new FormControl(undefined),
    unitPrice: new FormControl(undefined)
  })
  private subscriptions: Subscription[] = []
  public echargerList: Echarger[] = []
  public station: Station
  public zone: Zone

  // search station

  public searchStationForm: FormGroup = new FormGroup({
    name: new FormControl()
  })

  public displayedColumns: string[] = ['NAME', 'ADDRESS', 'ACTIVE', 'BUTTONS']
  public dataSource: MatTableDataSource<Station>

  public updateMode
  public loading = false
  public alert: Alert

  public DateFormat = DateFormat

  constructor (
    private _zone: ZoneService,
    private _translate: TranslateService,
    private _echarger: EchargerService,
    private _station: StationService,
    public _utility: UtilityService,
    private router: Router,
    private route: ActivatedRoute) {
    super()
    this.init()
  }

  public async init () {
    this.loading = true
    await this.setUpdatePage()
  }

  ngOnDestroy () {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  async setUpdatePage (): Promise<void> {
    this.route.params.subscribe(async ({ _id }) => {
      await this.getEchargers()
      await this.patchValueIntoForm(_id)
      this.loading = false
    })
    return
  }

  public async saveData (_id: string): Promise<void> {
    this.loading = true
    try {
      const query: any = {
        ...this.zone,
        zones: [{ ...this.zone, station: _id }],
        station: _id,
        created: true
      }

      query.zones.forEach((zone) => {
        zone.echargers = zone.echargers.map((e) => e = e.echarger)
      })

      const response = await this._zone.createPark(query).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.PARK_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })
      this.zoneForm.markAsPristine()
      this.loading = false
      this.router.navigateByUrl(`/update-park/${_id}`)
      return this.alert.present()
    } catch (error) {
      this.loading = false
      console.log(error)
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  private async getEchargers (
    page: number = 0,
     accumulatedEchargers: Echarger[] = []) {
    try {
      // Prepare the search query with the expiration date and current page
      const query = { expireDate: UtilityService.expireDate, page }

      const response = await this._echarger.searchEcharger(query).toPromise()

      if (response.valid) {
        // Accumulate eChargers from the current page
        accumulatedEchargers = accumulatedEchargers.concat(response.data.data)

        // Check if there's another page to fetch
        if (response.data.hasNext) {
          // Recursively fetch the next page
          return this.getEchargers(page + 1, accumulatedEchargers)
        } else {
          // If no more pages, process the accumulated eChargers
          const coupledEchargers = []

          this.echargerList = accumulatedEchargers
            .filter(echarge => coupledEchargers
              .findIndex((ce) => ce.echarger === echarge._id.toString()) < 0
            )
            .sort((p1, p2) => p1.name.localeCompare(p2.name))
        }
      } else {
        // Handle invalid response
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.loading = false
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
      this.loading = false
    }
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      // search for all zones since it is needed to only show echargers that
      // don't already have a zone coupled
      const response = await this._zone.getZone(_id).toPromise()
      if (response.valid) {
        this.zone = response.data
        const data: any = this.zone
        // controls for echarges
        const echarges = (this.zoneForm['controls'].echargers as FormArray)
        if (this.zone.echargers.length) {
          this.zone.echargers.forEach((echarger, index) => {
            echarges.push(new FormGroup({
              echarger: new FormControl(null, Validators.required),
            }))
          })
        }

        data.echargers = data.echargers.map((e) => ({ echarger: e }))
        this.zoneForm.patchValue({ ...data })
        this.zoneForm.disable()
        this.zoneForm.markAsPristine()
        return
      } else {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public async search (page?: number): Promise<void> {
    try {
      this.loading = true
      const values = this.searchStationForm.value
      const query = {}

      if (page) { query['page'] = page }
      if (values.name) { query['name'] = values.name }

      const response = await this._station.searchStation(query).toPromise()

      if (response.invalid) {
        if (this.dataSource.data.length) {
          this.alert = new Alert({
            type: AlertType.WARNING,
            message: `${this._translate.instant('ALERT.MESSAGE.WARNING')}` +
              `${this._translate.instant('ALERT.MESSAGE.PARTIAL_RESUTS')}`
          })
        } else {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
          })
        }
        this.alert.present()
        this.loading = false
        return
      }

      if (response.data.page === 1) {
        this.dataSource = new MatTableDataSource(response.data.data)
      } else {
        this.dataSource = new MatTableDataSource(
          this.dataSource.data.concat(response.data.data))
      }
      this.dataSource.paginator = this.paginator

      if (response.data.hasNext) {
        this.search((page || 0) + 1)
      } else {
        this.loading = false
      }
    } catch (error) {
      if (
        this.dataSource &&
        this.dataSource.data &&
        this.dataSource.data.length
      ) {
        this.alert = new Alert({
          type: AlertType.WARNING,
          message: `${this._translate.instant('ALERT.MESSAGE.WARNING')}` +
            `${this._translate.instant('ALERT.MESSAGE.PARTIAL_RESUTS')}`
        })
      } else {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
        })
      }
      this.alert.present()
      this.loading = false
    }
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  private openErrorAlert (message: string): void {
    this.loading = false
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  // here necessary and can not be used from service since addressValidation
  // also needs to be set
  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.zoneForm]
  }

  public isEmpty (obj) {
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== '') {
        return false
      }
    }
    return true
  }

  public hasDuplicates<T> (arr: T[]): boolean {
    return new Set(arr).size < arr.length
  }

}
