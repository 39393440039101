import { Component, ViewChild } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { MatPaginator, MatTableDataSource } from '@angular/material'

import { TranslateService } from '@ngx-translate/core'

import { PrivilegeService } from 'src/app/services/privilege.service'

import { Privilege } from 'src/app/classes/privilege.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'

@Component({
  selector: 'app-search-privilege',
  templateUrl: './search-privilege.component.html',
  styleUrls: ['./search-privilege.component.scss']
})
export class SearchPrivilegeComponent {
  public searchPrivilegeForm: FormGroup = new FormGroup({
    name: new FormControl()
  })

  public displayedColumns: string[] = ['NAME', 'BUTTONS']
  public dataSource: MatTableDataSource<Privilege>
  public loading = false
  public modal: Modal
  public alert: Alert
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor (
    private _translate: TranslateService,
    private _privilege: PrivilegeService
  ) {
  }

  public async search (page = 0): Promise<void> {
    try {
      this.loading = true
      const values = this.searchPrivilegeForm.value
      const query = { page }

      if (values.name) { query['name'] = values.name }

      const results = await this._privilege.searchPrivilege(query).toPromise()

      if (results.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${results.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }
      if (results.data.page === 1) {
        this.dataSource = new MatTableDataSource(results.data.data)
      } else {
        this.dataSource = new MatTableDataSource(
          this.dataSource.data.concat(results.data.data)
        )
      }
      this.dataSource.paginator = this.paginator

      if (results.data.hasNext) {
        this.search(page + 1)
      } else {
        this.loading = false
      }
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public showModal (el: Privilege): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_PRIVILEGE'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_PRIVILEGE')} ${el.name}?`,
      buttons: [{ title: this._translate.instant('BUTTON.DELETE') }],
      data: el._id
    })
    this.modal.confirm()
  }

  public async delete (_id: string): Promise<boolean> {
    try {
      const results = await this._privilege.deletePrivilege(_id).toPromise()

      if (results.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${results.message}`
        })
        this.alert.present()
        return false
      }

      this.dataSource.data = this.dataSource.data
        .filter((data) => data._id !== _id)

      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.PRIVILEGE_DELETED')
      })
      this.alert.present()

      return true
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()

      return false
    }
  }
}
