import { ChangeDetectionStrategy, OnInit } from '@angular/core'
import { Component, Input } from '@angular/core'
import { AvgMinMaxValues, PlainValue, ValueChangeValues } from './kpi-card-types'
import { UtilityService } from 'src/app/services/utility.service'

const isPlainValue = (values: any): values is PlainValue =>
  values.value !== undefined

const isValueChangeValues = (values: any): values is ValueChangeValues =>
  values.startValue !== undefined && values.endValue !== undefined

const isAvgMinMaxValues = (values: any): values is AvgMinMaxValues =>
  values.avg !== undefined &&
  values.min !== undefined &&
  values.max !== undefined

@Component({
  selector: 'app-kpi-card',
  templateUrl: './kpi-card.component.html',
  styleUrls: ['./kpi-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiCardComponent implements OnInit {
  @Input() public title: string
  @Input() public unit: string
  @Input() public precision: number
  @Input() public values: PlainValue | AvgMinMaxValues | ValueChangeValues

  constructor (
    private _utility: UtilityService
  ) { }

  ngOnInit () {
    this.title = this._utility.toTranslationString('LABEL', this.title)
  }

  public get value (): string {
    if (isPlainValue(this.values)) {
      return this.values.value.toFixed(this.precision)
    }

    if (isValueChangeValues(this.values)) {
      if (this.values.isPositiveChange) {
        return `+${this.values.difference.toFixed(this.precision)}`
      }

      return this.values.difference.toString()
    }

    if (isAvgMinMaxValues(this.values)) {
      return this.values.avg.toFixed(this.precision)
    }
  }

  public get leftValue (): string {
    if (isValueChangeValues(this.values)) {
      return this.values.startValue.toFixed(this.precision)
    }

    if (isAvgMinMaxValues(this.values)) {
      return this.values.min.toFixed(this.precision)
    }
  }

  public get rightValue (): string {
    if (isValueChangeValues(this.values)) {
      return this.values.endValue.toFixed(this.precision)
    }

    if (isAvgMinMaxValues(this.values)) {
      return this.values.max.toFixed(this.precision)
    }
  }

  public get isPlain (): boolean {
    return isPlainValue(this.values)
  }

  public get isValueChange (): boolean {
    return isValueChangeValues(this.values)
  }

  public get isPositiveValueChange (): boolean {
    if (isValueChangeValues(this.values)) {
      return this.values.isPositiveChange
    }
  }

  public get isNegativeValueChange (): boolean {
    if (isValueChangeValues(this.values)) {
      return this.values.isNegativeChange
    }
  }

  public get isNoValueChange (): boolean {
    if (isValueChangeValues(this.values)) {
      return this.values.isNoChange
    }
  }

  public get isAvgMinMax (): boolean {
    return isAvgMinMaxValues(this.values)
  }
}
