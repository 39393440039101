import { Injectable } from '@angular/core'
import { Monitoring } from '../classes/monitoring.class'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'

/**Service to make all API calls to receive the different Information
 * about the Microservices
 * returns: Observable
 */

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  private baseUrl = `${environment.baseUrl}`

  constructor (private http: HttpClient) { }

  public getInfo (serviceName: string): Observable<Monitoring> {
    return this.http
      .get<Monitoring>(`${this.baseUrl}${serviceName}/management/info`)
      .pipe(map(info => new Monitoring({ info })))
  }

  public getMetrics (serviceName: string): Observable<Monitoring> {
    return this.http
      .get<Monitoring>(`${this.baseUrl}${serviceName}/management/metrics`)
      .pipe(map(metrics => new Monitoring({ metrics })))
  }

  public getHealth (serviceName: string): Observable<Monitoring> {
    return this.http
      .get<Monitoring>(`${this.baseUrl}${serviceName}/management/health`)
      .pipe(map(health => new Monitoring({ health })))
  }
}
