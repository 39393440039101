import { Role } from './role.class'
import { Password } from '../interfaces/password'
import { Confirmation } from '../interfaces/confirmation'

export class Authn {
  _id: string
  password: Password
  confirmation: Confirmation
  roles: Role[]
  email: string
  creationDate: Date

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'password', 'confirmation', 'email']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.roles) {
      this.roles = obj.roles.map(role => new Role(role))
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }
  }
}
