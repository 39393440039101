import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { Modal } from 'src/app/classes/modal.class'
import { FormCanDeactivate } from './form-can-deactivate'

// Guard to check for unsaved changes and show modal/ alert if existing

@Injectable()
export class LeavePageGuard implements CanDeactivate<FormCanDeactivate> {
  public modal: Modal

  canDeactivate (component: FormCanDeactivate): Promise<boolean> | boolean {
    return component.canDeactivate() ||
      (component.getModal().showModal() as Promise<boolean>)
  }
}
