export class Privilege {
  _id: string
  name: string

  constructor (obj: Object) {
    const properties = ['_id', 'name']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }
  }

  public toString (): string {
    return this.name
  }
}
