import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { CustomerPlan } from 'src/app/enums/customer-plan.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from '../../../components/modal/modal.component'
import { RoleService } from 'src/app/services/role.service'
import { Customer } from 'src/app/classes/customer.class'
import { Role } from 'src/app/classes/role.class'
import { ImageService } from 'src/app/services/image.service'
import { SafeUrl } from '@angular/platform-browser'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FileService } from 'src/app/services/file.service'

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent
  extends FormCanDeactivate
  implements OnInit {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public loading = true
  public alert: Alert

  public preview: string
  public plans: Role[]
  public allRoles: Array<Role> = []

  // tslint:disable-next-line: member-ordering
  public createCustomerForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    name: new FormControl('', [Validators.required]),
    website: new FormControl(''),
    plan: new FormControl(null, [Validators.required]),
    rootUser: new FormControl(undefined, [Validators.email]),
    invoicing: new FormGroup({
      logo: new FormControl(undefined, [Validators.required]),
      header: new FormControl('', [Validators.required])
    })
  })

  // tslint:disable-next-line: member-ordering
  public invoicingLogo: string

  constructor (
    public dialogRef: MatDialogRef<CreateCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _translate: TranslateService,
    private _file: FileService,
    private _role: RoleService,
    private _image: ImageService
  ) {
    super()
  }

  get updateMode (): boolean {
    return !!this.data
  }

  async setUpdatePage (): Promise<void> {
    if (!this.data) {
      return
    }
    this.preview = this.data.invoicing.logo

    const customer: Customer = new Customer(this.data)
    this.createCustomerForm.patchValue({
      ...customer,
      plan: customer.plan._id,
      website: customer.website,
      rootUser: customer.rootUser && customer.rootUser.authn.email,
      invoicing: {
        logo: this._file.base64toFile(
          this.data.invoicing.logo,
          'image/jpeg',
          'oldImage.jpeg'
        ),
        header: customer.invoicing.header &&
          customer.invoicing.header.join('\n')
      }
    })
  }

  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public submit () {
    try {
      if (this.createCustomerForm.invalid) {
        return this.markFormGroupTouched(this.createCustomerForm)
      }
      this.loading = true
      const { value } = this.createCustomerForm
      const dataToBeSent = {
        ...value,
        rootUser: value.rootUser && { authn: { email: value.rootUser } },
        customer: value.customer,
        plan: { _id: value.plan },
        invoicing: {
          ...value.invoicing,
          header: value.invoicing.header.split('\n')
        }
      }
      this.dialogRef.close(dataToBeSent)
    } catch (error) {
      console.log(error)
    }
  }

  async ngOnInit () {
    await this.getPlans()
    this.setUpdatePage()
    this.loading = false
  }

  private async getPlans () {
    await this.getRoles()
    this.plans = (this.allRoles || [])
      .sort((a, b) =>
        CustomerPlan[a.name.split('.')[1]] - CustomerPlan[b.name.split('.')[1]]
      )
  }

  public async getRoles (page?: number): Promise<boolean> {
    try {
      const response = await this._role
        .searchRole({ name: 'CUSTOMER.', page }).toPromise()
      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }

      if (!page) {
        this.allRoles = response.data.data
      } else {
        this.allRoles = this.allRoles.concat(response.data.data)
      }
      if (response.data.hasNext) {
        this.getRoles((page || 0) + 1)
      }
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      return false
    }
  }

  public handleInputChange (event) {
    const file = (event.dataTransfer)
      ? event.dataTransfer.files[0]
      : event.target.files[0]

    if (!file) {
      this.createCustomerForm.patchValue({ invoicing: { logo: undefined } })
      this.preview = undefined

      return
    }

    const pattern = /^image\//

    if (!file.type.match(pattern)) {
      document.getElementById('image')['value'] = ''
      alert(this._translate.instant('ERROR.IMAGE_INVALID_FORMAT'))

      return
    }

    const reader = new FileReader()
    reader.onload = this._handleReaderLoaded.bind(this)
    reader.readAsDataURL(file)
    this.createCustomerForm.patchValue({ invoicing: { logo: file } })
    this.createCustomerForm.markAsDirty()
  }

  private _handleReaderLoaded (event: any) {
    const { result } = event.target
    this.preview = (result as string).split(',')[1]
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createCustomerForm]
  }

  public base64ToUrl (base64: string): SafeUrl {
    return this._image.base64ToUrl(base64)
  }

  public cancel () {
    this.dialogRef.close()
  }
}
