export interface MenuItem {
  label: string
  icon?: string
  children?: MenuItem[]
  route?: string
}

export const menuItems: MenuItem[] = [
  {
    label: 'FUNC.ADMIN.CHARGING-HISTORY',
    icon: 'history',
    route: '/charging-history-admin'
  },
  {
    label: 'FUNC.CAR.MANAGE',
    icon: 'car',
    children: [
      {
        label: 'FUNC.CAR.CREATE',
        icon: 'plus',
        route: '/create-car'
      },
      {
        label: 'FUNC.CAR.SEARCH',
        icon: 'search',
        route: '/search-car'
      }
    ]
  },
  {
    label: 'FUNC.PLUG.SEARCH',
    icon: 'plug',
    route: '/search-plug'
  },
  {
    label: 'FUNC.STATION.MANAGE',
    icon: 'building',
    children: [
      {
        label: 'FUNC.STATION.CREATE',
        icon: 'plus',
        route: '/create-station'
      },
      {
        label: 'FUNC.STATION.SEARCH',
        icon: 'search',
        route: '/search-station'
      }
    ]
  },
  {
    label: 'FUNC.ECHARGER.MANAGE',
    icon: 'charging-station',
    children: [
      {
        label: 'FUNC.ECHARGER.CREATE',
        icon: 'plus',
        route: '/create-echarge'
      },
      {
        label: 'FUNC.ECHARGER.SEARCH',
        icon: 'search',
        route: '/search-echarge'
      },
      {
        label: 'FUNC.ECHARGER.VIRTUAL',
        icon: 'cloud',
        route: '/virtual-echarger'
      }
    ]
  },
  {
    label: 'FUNC.USER',
    icon: 'users',
    route: '/search-user'
  },
  {
    label: 'FUNC.VENDOR.SEARCH',
    icon: 'store',
    route: '/search-vendor'
  },
  {
    label: 'FUNC.CUSTOMER',
    icon: 'user',
    route: '/search-customer'
  },
  {
    label: 'FUNC.SECURITY',
    icon: 'shield-alt',
    children: [
      {
        label: 'FUNC.ACTIVITY.CREATE',
        icon: 'plus',
        route: '/create-activity'
      },
      {
        label: 'FUNC.ACTIVITY.SEARCH',
        icon: 'search',
        route: '/search-activity'
      },
      {
        label: 'FUNC.PERMISSION.CREATE',
        icon: 'plus',
        route: '/create-permission'
      },
      {
        label: 'FUNC.PERMISSION.SEARCH',
        icon: 'search',
        route: '/search-permission'
      },
      {
        label: 'FUNC.ROLE.CREATE',
        icon: 'plus',
        route: '/create-role'
      },
      {
        label: 'FUNC.ROLE.SEARCH',
        icon: 'search',
        route: '/search-role'
      }
    ]
  },
  {
    label: 'FUNC.PARK.MANAGE',
    icon: 'parking',
    children: [
      {
        label: 'FUNC.PARK.CREATE',
        icon: 'plus',
        route: '/create-park'
      },
      {
        label: 'FUNC.PARK.SEARCH',
        icon: 'search',
        route: '/search-park'
      }
    ]
  },
  {
    label: 'FUNC.APP.MANAGE',
    icon: 'mobile-alt',
    route: '/banner'
  },
  {
    label: 'FUNC.PRIVILEGE.MANAGE',
    icon: 'user-shield',
    children: [
      {
        label: 'FUNC.PRIVILEGE.CREATE',
        icon: 'plus',
        route: '/create-privilege'
      },
      {
        label: 'FUNC.PRIVILEGE.SEARCH',
        icon: 'search',
        route: '/search-privilege'
      }
    ]
  },
  {
    label: 'FUNC.CHARGING_HISTORY',
    icon: 'history',
    route: '/charging-history'
  },
  {
    label: 'FUNC.DEVICE',
    icon: 'microchip',
    route: '/search-device'
  },
  {
    label: 'FUNC.PAYMENT_PROVIDER',
    icon: 'credit-card',
    route: '/search-paymentprovider'
  },
  {
    label: 'FUNC.MONITORING',
    icon: 'desktop',
    route: '/system-test'
  }
]
