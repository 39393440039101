import { Component, ViewChild } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { StationService } from 'src/app/services/station.service'
import { Station } from 'src/app/classes/station.class'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { DateFormat } from 'src/app/enums/date-format.enum'

@Component({
  selector: 'app-search-park-station',
  templateUrl: './search-park-station.component.html',
  styleUrls: ['./search-park-station.component.scss']
})
export class SearchParkStationComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator

  public searchStationForm: FormGroup = new FormGroup({
    name: new FormControl(),
    address: new FormControl(),
    active: new FormControl()
  })

  public displayedColumns: string[] = ['NAME', 'ADDRESS', 'ACTIVE', 'BUTTONS']
  public dataSource: MatTableDataSource<Station>
  public alert: Alert
  public loading = false

  public DateFormat = DateFormat

  constructor (
    private _translate: TranslateService,
    private _station: StationService
  ) {
  }

  public async search (page?: number): Promise<void> {
    try {
      this.loading = true
      const values = this.searchStationForm.value
      const query = {}

      if (page) { query['page'] = page }
      if (values.name) { query['name'] = values.name }
      if (values.address) { query['address'] = values.address }
      if (values.active !== undefined) { query['active'] = values.active }

      const response = await this._station.searchStation(query).toPromise()

      if (response.invalid) {
        if (
          this.dataSource &&
          this.dataSource.data &&
          this.dataSource.data.length
        ) {
          this.alert = new Alert({
            type: AlertType.WARNING,
            message: `${this._translate.instant('ALERT.MESSAGE.WARNING')}` +
              `${this._translate.instant('ALERT.MESSAGE.PARTIAL_RESUTS')}`
          })
        } else {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
          })
        }
        this.alert.present()
        this.loading = false
        return
      }

      if (response.data.page === 1) {
        this.dataSource = new MatTableDataSource(response.data.data)
      } else {
        this.dataSource = new MatTableDataSource(
          this.dataSource.data.concat(response.data.data))
      }
      this.dataSource.paginator = this.paginator

      if (response.data.hasNext) {
        this.search((page || 0) + 1)
      } else {
        this.loading = false
      }
    } catch (error) {
      if (
        this.dataSource &&
        this.dataSource.data &&
        this.dataSource.data.length
      ) {
        this.alert = new Alert({
          type: AlertType.WARNING,
          message: `${this._translate.instant('ALERT.MESSAGE.WARNING')}` +
            `${this._translate.instant('ALERT.MESSAGE.PARTIAL_RESUTS')}`
        })
      } else {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
        })
      }
      this.alert.present()
      this.loading = false
    }
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }
}
