import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Response } from '../classes/response.class'
import { Banner } from '../classes/banner.class'

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  private baseUrl = `${environment.baseUrl}${environment.archContext.banner}`

  constructor (private http: HttpClient) { }

  public getBanner (): Observable<Response<Banner>> {
    return this.http
    .get<Response<Banner>>(`${this.baseUrl}`)
      .pipe(map(({ message, data }) => {
        if (data) {
          return new Response<Banner>(message, new Banner(data))
        }
        return new Response<null>(message, null)
      }

      ))
  }

  public createBanner (form: Banner): Observable<Response<Banner | null>> {
    return this.http
      .post<Response<Banner>>(this.baseUrl, form)
      .pipe(map(({ message, data }) => {
        if (data) {
          return new Response<Banner>(message, new Banner(data))
        }
        return new Response<null>(message, null)
      }
      ))
  }

  public updateBanner (banner: Banner): Observable<Response<Banner>> {
    return this.http
      .patch<Response<Banner>>(`${this.baseUrl}`, banner)
      .pipe(map(({ message, data }) =>
        new Response<Banner>(message, new Banner(data))
      ))
  }

  public deleteBanner (_id: string): Observable<Response<Banner>> {
    return this.http
      .delete<Response<Banner>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Banner>(message, new Banner(data))
      ))
  }

  public putBanner (banner: Banner): Observable<Response<Banner>> {
    return this.http
    .put<Response<Banner>>(`${this.baseUrl}`, banner)
    .pipe(map(({ message, data }) =>
      new Response<Banner>(message, new Banner(data))
    ))
  }
}
