import { Invoicing } from './invoicing.class'
import { Role } from './role.class'
import { UpdateCustomerDTO } from '../interfaces/update-customer.dto'
import { CreateCustomerDTO } from '../interfaces/create-customer.dto'
import { User } from './user.class'

export class Customer {
  _id: string
  name: string
  plan: Role
  invoicing: Invoicing
  creationDate: Date
  updateDate: Date
  rootUser?: User

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'name', 'description']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.rootUser) {
      this.rootUser = new User(obj.rootUser)
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }

    if (obj.plan) {
      this.plan = new Role(obj.plan)
    }

    if (obj.invoicing) {
      this.invoicing = new Invoicing(obj.invoicing)
    }
  }

  toUpdateDTO (): UpdateCustomerDTO {
    return {
      _id: this._id,
      name: this.name,
      plan: this.plan && this.plan._id,
      invoicing: { ...this.invoicing, logo: undefined },
      invoicinglogo: this.invoicing && this.invoicing.logo,
      rootUser: this.rootUser && this.rootUser.authn.email
    }
  }

  toCreateDTO (): CreateCustomerDTO {
    return {
      name: this.name,
      plan: this.plan && this.plan._id,
      invoicing: { ...this.invoicing, logo: undefined },
      rootUser: this.rootUser && this.rootUser.authn.email,
      invoicinglogo: this.invoicing && this.invoicing.logo
    }
  }
}
