import { Component, ViewChild } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { MatDialog, MatDialogConfig, MatPaginator, MatTableDataSource } from '@angular/material'

import { TranslateService } from '@ngx-translate/core'

import { UserService } from '../../../services/user.service'
import { PrivilegeService } from 'src/app/services/privilege.service'

import { User } from 'src/app/classes/user.class'
import { Privilege } from 'src/app/classes/privilege.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'

import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'
import { CreateUserComponent } from '../create-user/create-user.component'
import { filter, map, switchMap } from 'rxjs/operators'
import { EditUserAccessComponent } from '../edit-user-access/edit-user-access.component'
import { AuthService } from 'src/app/services/auth.service'
import { Permission } from 'src/app/classes/permission.class'

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent {
  @ViewChild('privilegesComponent') privilegesComponent: CheckboxContainerComponent

  canUpdateSecurity$ = this._auth.user$.pipe(
    filter(u => !!u),
    map(u => u.authn.roles
      .reduce((acc, r) =>
        [
          ...acc,
          ...r.permissions
            .filter((p: Permission) => p.app === 'app-admin' && p.active)
            .map((p: Permission) => p.label)
        ],
      [])
      .find(p => p === `USER.SECURITY`)
    )
  )

  public searchUserForm: FormGroup = new FormGroup({
    email: new FormControl(),
    firstname: new FormControl(),
    lastname: new FormControl(),
    genre: new FormControl(),
    newsletter: new FormControl(),
    locale: new FormControl()
  })
  public displayedColumns: string[] = ['EMAIL', 'FIRSTNAME', 'LASTNAME',
    'SEX', 'NEWSLETTER', 'BUTTONS']
  public dataSource: MatTableDataSource<User>
  public loading = false
  public privileges: Privilege[] = []
  public modal: Modal
  public alert: Alert
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor (
    private _translate: TranslateService,
    private _user: UserService,
    private _privilege: PrivilegeService,
    private _dialog: MatDialog,
    private _auth: AuthService
  ) {
    this.getPrivileges()
  }

  public async search (page?: number): Promise<void> {
    try {
      this.loading = true
      const values = this.searchUserForm.value
      const query: any = {}

      if (page) { query.page = page }
      if (values.locale) { query.locale = values.locale }
      if (values.newsletter !== null) { query.newsletter = values.newsletter }
      if (this.privilegesComponent.selected.length) {
        query.privileges = this.privilegesComponent.selected
      }
      if (values.email) { query.email = values.email }
      if (values.firstname) { query.firstname = values.firstname }
      if (values.lastname) { query.lastname = values.lastname }
      if (values.genre) { query.genre = values.genre }

      const results = await this._user.searchUser(query).toPromise()
      if (results.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${results.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }
      if (results.data.page === 1) {
        this.dataSource = new MatTableDataSource(results.data.data)
      } else {
        this.dataSource = new MatTableDataSource(
          this.dataSource.data.concat(results.data.data)
        )
      }
      this.dataSource.paginator = this.paginator

      if (results.data.hasNext) {
        this.search((page || 0) + 1)
      } else {
        this.loading = false
      }
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }

  public async create () {
    this._dialog.open(CreateUserComponent, {
      panelClass: 'maxh-100-vh'
    })
  }

  public async update (user: User) {
    try {
      const dialogConfig = new MatDialogConfig()
      dialogConfig.data = user
      dialogConfig.panelClass = 'maxh-100-vh'
      const dialogRef = this._dialog.open(CreateUserComponent, dialogConfig)
      dialogRef.afterClosed()
        .pipe(
          filter((data) => !!data),
          switchMap(() => this._user.getUser(user.authn._id))
        )
        .subscribe(userResponse => {
          if (userResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${userResponse.message}`
            })
            this.alert.present()
          }

          this.dataSource.data = this.dataSource.data.map(u =>
            u._id === userResponse.data._id ? userResponse.data : u)
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async editSecurity (user: User) {
    try {
      const dialogConfig = new MatDialogConfig()
      dialogConfig.data = user.authn
      dialogConfig.panelClass = 'maxh-100-vh'
      const dialogRef = this._dialog.open(EditUserAccessComponent, dialogConfig)
      dialogRef.afterClosed()
        .pipe(filter((data) => !!data),
        switchMap(() => this._user.getUser(user.authn._id)))
        .subscribe(userResponse => {
          if (userResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${userResponse.message}`
            })
            this.alert.present()
          }

          this.dataSource.data = this.dataSource.data.map(u =>
            u._id === userResponse.data._id ? userResponse.data : u)
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      console.log(error)
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public showModal (el: User): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_USER'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_USER')} ${el.firstname} ${el.lastname}?`,
      buttons: [{ title: this._translate.instant('BUTTON.DELETE') }],
      data: el.authn._id
    })
    this.modal.confirm()
  }

  public async delete (_id: string): Promise<boolean> {
    try {
      const results = await this._user.deleteUser(_id).toPromise()
      if (results.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${results.message}`
        })
        this.alert.present()
        return false
      }

      this.dataSource.data = this.dataSource.data
        .filter(({ authn }) => authn._id !== _id)

      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.USER_DELETED')
      })
      this.alert.present()

      return true
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()

      return false
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    this.loading = false
    return this.alert.present()
  }

  private async getPrivileges (page = 0): Promise<void> {
    try {
      const response = await this._privilege
        .searchPrivilege({ page, sort: { name: 'asc' } })
        .toPromise()

      if (response.valid) {
        this.privileges = this.privileges.concat(response.data.data)
      } else {
        this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      if (response.data.hasNext) {
        this.getPrivileges(page + 1)
      }
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }
}
