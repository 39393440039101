import { Component, OnDestroy, ViewChild } from '@angular/core'
import { EchargerService } from 'src/app/services/echarger.service'
import { ZoneService } from 'src/app/services/zone.service'
import { FormGroup, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { UtilityService } from 'src/app/services/utility.service'
import { StationService } from 'src/app/services/station.service'
import { PrivilegeService } from 'src/app/services/privilege.service'
import { ActivatedRoute } from '@angular/router'
import { Echarger } from 'src/app/classes/echarger.class'
import { Park } from 'src/app/classes/park.class'
import { ParkZone } from 'src/app/interfaces/park-zone'
import { Zone } from 'src/app/classes/zone.class'
import { Alert } from 'src/app/classes/alert.class'
import { Coords } from 'src/app/classes/coords.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { Station } from 'src/app/classes/station.class'
import { Privilege } from 'src/app/classes/privilege.class'
import { CustomerService } from 'src/app/services/customer.service'

@Component({
  selector: 'app-create-park',
  templateUrl: './create-park.component.html',
  styleUrls: ['./create-park.component.scss']
})
export class CreateParkComponent
  extends FormCanDeactivate
  implements OnDestroy {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public createParkForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    name: new FormControl('', Validators.required),
    peakParkPower:
      new FormControl(null, [Validators.required, Validators.min(1)]),
    offerEnergy: new FormControl(false, Validators.required),
    station: new FormGroup({
      station: new FormControl(undefined),
      name: new FormControl({ value: '', disabled: true }),
      coords: new FormGroup({
        lat: new FormControl(undefined),
        lng: new FormControl(undefined)
      }),
      address: new FormControl({ undefined,  disabled: true }),
      formattedAddress: new FormControl({ value: '', disabled: true }),
    }),
    customer: new FormControl(undefined, Validators.required),
    zones: new FormArray([new FormGroup({
      _id: new FormControl(undefined),
      name: new FormControl('', Validators.required),
      coords: new FormGroup({
        lat: new FormControl(undefined, Validators.required),
        lng: new FormControl(undefined, Validators.required)
      }),
      echargers: new FormArray([new FormGroup({
        echarger: new FormControl(null, Validators.required)
      })]),
      peakZonePower:
        new FormControl(undefined, [Validators.required, Validators.min(1)]),
      peakEchargePower: new FormControl(undefined, [Validators.min(1)]),
      continuingEchargePower:
        new FormControl(undefined, [Validators.required, Validators.min(1)]),
      offers: new FormArray([new FormGroup({
        privilege: new FormControl(null),
        maxChargePower:
          new FormControl(undefined, [Validators.required, Validators.min(1)]),
        unitPrice:
          new FormControl(undefined, [Validators.required, Validators.min(0)])
      })])
    })]),
  })
  private subscriptions: Subscription[] = []
  public echargerList: Echarger[] = []
  public echargePool: Echarger[][][] = []
  public currentCharger: string
  // stores the number of zones and echargers, not actual values (in Form)
  public zones: ParkZone[] = []
  public unlinkedZones: Zone[] = []
  public allZones: Zone[] = []
  public station: Station
  public privileges: Privilege[] = []
  public updateMode
  public loading = false
  public alert: Alert
  public customers: any[] = []

  constructor (
    private _customer: CustomerService,
    private _zone: ZoneService,
    private _translate: TranslateService,
    private _echarger: EchargerService,
    private _station: StationService,
    public _utility: UtilityService,
    private _privilege: PrivilegeService,
    private route: ActivatedRoute
  ) {
    super()
    this.init()
  }

  public async init () {
    this.loading = true
    await this.getPrivileges()
    await this.setUpdatePage()
    await this.getCustomers()
  }

  ngOnDestroy () {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe(url => {
      if (!url[0].path.includes('update')) {
        // this.addZoneCard()
        this.echargePool[0] = []
        this.zones.push({
          name: null,
          coords: null,
          peakZonePower: null,
          peakEchargePower: null,
          continuingEchargePower: null,
          offers: [{
            privilege: null,
            maxChargePower: null,
            unitPrice: null
          }],
          echargers: ['0']
        })
        this.route.params.subscribe(async ({ _id }) => {
          await this.getStation(_id)
          await this.getZoneChargers(_id)
          this.addEchargePool(0)
          this.loading = false
        })
        return
      }
      this.updateMode = true

      this.route.params.subscribe(async ({ _id }) => {
        await this.getStation(_id)
        await this.patchValueIntoForm(_id)
      })
    })
  }

  public async saveData (): Promise<void> {
    this.loading = true

    try {
      const park = Object.assign({}, this.createParkForm.value)

      // try to keep the old values for name and peakPower (not dummy ones)
      const station = this.createParkForm.value.station.station
      if (!station) {
        park.name = this.unlinkedZones[0].parkName
        park.peakParkPower = this.unlinkedZones[0].peakParkPower
      }

      const chargers: String[] = []
      park.zones.forEach((zone) => {
        if (!zone.peakEchargePower) {
          delete zone.peakEchargePower
        }

        zone.echargers = zone.echargers.map((echarger) => {
          chargers.push(echarger)
          return echarger.echarger
        })

        zone.offers.forEach((offer) => {
          if (!offer.privilege) {
            delete offer.privilege
          }
        })
      })

      if (this.hasDuplicates(chargers)) {
        this.openErrorAlert(
          `${this._translate.instant('ALERT.DUPLICATE_CHARGERS')}`)
        return this._utility.scrollToTop()
      }

      const query = {
        ...park,
        parkName: park.name,
        station: station,
        created: !this.updateMode
      }

      const response = (this.updateMode)
        ? await this._zone.updatePark(query).toPromise()
        : await this._zone.createPark(query).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.PARK_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })
      if (!this.updateMode) {
        this.clearForm()
      }
      if (
        this.updateMode &&
        this.createParkForm.value.zones.find(el => el._id === null)
      ) {
        // needed to add _id's to new created zones
        this.patchValueIntoForm(query.station)
      }
      this.createParkForm.markAsPristine()

      this.createParkForm.value.zones.forEach((zone) => {
        zone.echargers = zone.echargers.map((e) => ({ echarger: e }))
      })
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.loading = false
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  private async getEchargers (
    page: number = 0,
     accumulatedEchargers: Echarger[] = []) {
    try {
      // Prepare the search query with the expiration date and current page
      const query = { expireDate: UtilityService.expireDate, page }

      const response = await this._echarger.searchEcharger(query).toPromise()

      if (response.valid) {
        // Accumulate eChargers from the current page
        accumulatedEchargers = accumulatedEchargers.concat(response.data.data)

        // Check if there's another page to fetch
        if (response.data.hasNext) {
          // Recursively fetch the next page
          return this.getEchargers(page + 1, accumulatedEchargers)
        } else {
          // If no more pages, process the accumulated eChargers
          const coupledEchargers = []
          this.allZones.forEach((zone) => {
            zone.echargers.forEach(e => coupledEchargers.push(e))
          })

          this.echargerList = accumulatedEchargers
            .filter(echarge => coupledEchargers
              .findIndex((ce) => ce.echarger === echarge._id.toString()) < 0
            )
            .sort((p1, p2) => p1.name.localeCompare(p2.name))
        }
      } else {
        // Handle invalid response
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.loading = false
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private async getStation (_id: string) {
    try {
      const response = await this._station.getStation(_id).toPromise()
      if (response.valid) {
        this.station = response.data
        this.createParkForm.patchValue({
          station: { ...this.station, station: this.station._id }
        })
      } else {
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private async getZoneChargers (_id: string) {
    try {
      // search for all zones since it is needed to only show echargers that
      // don't already have a zone coupled
      const response = await this._zone.searchZone({}).toPromise()
      if (response.valid) {
        this.allZones = response.data
        // stattion already has zone
        if (
          !this.updateMode &&
          this.allZones
            .find((z) => (z.station && z.station._id.toString() === _id ))
        ) {
          this.updateMode = true
          this.patchValueIntoForm(_id)
          this.alert = new Alert({
            type: AlertType.INFO,
            message: this._translate.instant('ALERT.STATION_ALREADY_HAS_ZONES')
          })
          return this.alert.present()
        }
        this.allZones = this.allZones
          .filter(z => z.station ? z.station._id.toString() !== _id : true)
      } else {
        if (response.message !== 'NO_ZONE_FOUND') {
          return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
            + ` ${this._translate.instant(response.message)}`)
        }
      }
      await this.getEchargers()
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public onEchargeChange (
    newId: string,
    zoneIndex: number,
    echargerIndex: number
  ) {
    const zones = this.createParkForm.get('zones').value

    if (newId && this.currentCharger) {
      const freedCharger = this.echargerList
        .find(({_id}) => _id.toString() === this.currentCharger)
      zones.forEach((zone, l) => {
        zone.echargers.forEach((echarger, m) => {
          if (l !== zoneIndex || m !== echargerIndex) {
            if (freedCharger && freedCharger._id) {
              this.echargePool[l][m].splice(
                this.findIndex(this.echargePool[l][m], freedCharger),
                0,
                freedCharger
              )
            }
            this.echargePool[l][m] = this.echargePool[l][m]
              .filter((e) => e._id.toString() !== newId)
          }
        })
      })
    }
  }

  public onEchargeDeletion (zoneIndex: number, echargerIndex: number) {
    const zones = this.createParkForm.get('zones').value

    if ( this.currentCharger) {
      const freedCharger = this.echargerList
        .find(({_id}) => _id.toString() === this.currentCharger)
      zones.forEach((zone, l) => {
        zone.echargers.forEach((echarger, m) => {
          if (l !== zoneIndex || m !== echargerIndex) {
            if (freedCharger && freedCharger._id) {
              this.echargePool[l][m].splice(
                this.findIndex(this.echargePool[l][m], freedCharger),
                0,
                freedCharger
              )
            }
          }
        })
      })
      this.echargePool[zoneIndex].splice(echargerIndex, 1)
    }
  }

  public addEchargePool (zone: number): void {
    const echarges = (this.createParkForm
      .get('zones')['controls'][zone]['controls'].echargers as FormArray
    ).value
    const echargeCount = this.echargePool[zone]
      ? this.echargePool[zone].length
      : 0
    // reuse existing pool
    if ( echargeCount && echargeCount <= 1) {
      const sample = this.echargePool[zone][0]
      this.echargePool[zone][echargeCount] = sample
        .filter(({ _id }) => _id !== echarges[0].echarger)
    } else {
      const usedCharges = this.getUsedChargers()
      const remainingCharges = this.echargerList
        .filter((echarge) => !usedCharges.includes(echarge._id.toString()))
      if (!echargeCount) {
        // create Pool if it was empty before
        this.echargePool[zone] = []
      }
      this.echargePool[zone][echargeCount] = remainingCharges
    }
  }

  public resetEchargePool () {
    const zones = this.createParkForm.get('zones').value
    const usedCharges = this.getUsedChargers()
    const remainingCharges = this.echargerList
      .filter((echarge) => !usedCharges.includes(echarge._id.toString()))

    zones.forEach((zone, i) => {
      this.echargePool[i] = []
      zone.echargers.forEach((echarger, j) => {
        const charge = this.echargerList
          .filter((e) => e._id.toString() === echarger.echarger)[0]
        this.echargePool[i][j] = [...remainingCharges]
        if (charge) {
          this.echargePool[i][j].push(charge)
        }
      })
    })
  }

  public addEchargerRow (zone: number): void {
    if (this.zones[zone]) {
      this.zones[zone].echargers.push('' + this.zones[zone].echargers.length)
    }

    const echargers = (this.createParkForm
      .get('zones')['controls'][zone]['controls'].echargers as FormArray
    )

    this.addEchargePool(zone)

    echargers.push(new FormGroup({
      echarger: new FormControl(null, Validators.required),
    }))
  }

  public removeEchargerRow (zone: number, index: number): void {
    if (this.zones[zone] && this.zones[zone].echargers[index]) {
      this.zones[zone].echargers.splice(index, 1)
    }
    (this.createParkForm
      .get('zones')['controls'][zone]['controls'].echargers as FormArray
    ).removeAt(index)
  }

  public removeZoneCard (index: number) {
    this.zones.splice(index, 1);
    (this.createParkForm.get('zones') as FormArray).removeAt(index)
    this.resetEchargePool()
  }

  public addZoneCard () {
    if (this.zones) {
      this.zones.push({
        name: null,
        coords: null,
        peakZonePower: null,
        peakEchargePower: null,
        continuingEchargePower: null,
        offers: [{
          privilege: null,
          maxChargePower: null,
          unitPrice: null
        }],
        echargers: [' ']
      });

      (this.createParkForm.get('zones') as FormArray).push(new FormGroup({
        _id: new FormControl(undefined),
        name: new FormControl(undefined),
        echargers: new FormArray([new FormGroup({
          echarger: new FormControl(null, Validators.required)
        })]),
        peakZonePower:
          new FormControl(undefined, [Validators.required, Validators.min(1)]),
        peakEchargePower: new FormControl(undefined, Validators.min(1)),
        continuingEchargePower:
          new FormControl(undefined, [Validators.required, Validators.min(1)]),
        offers: new FormArray([new FormGroup({
          privilege: new FormControl(null),
          maxChargePower: new FormControl(
            undefined,
            [Validators.required, Validators.min(1)]
          ),
          unitPrice:
            new FormControl(undefined, [Validators.required, Validators.min(0)])
        })]),
        coords: new FormGroup({
          lat: new FormControl(undefined, Validators.required),
          lng: new FormControl(undefined, Validators.required)
        }),
      }))

      this.addEchargePool(this.zones.length - 1)
    }
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      // search for all zones since it is needed to only show echargers that
      // don't already have a zone coupled
      const response = await this._zone.searchZone({}).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }

      this.allZones = response.data

      let zonesDB: Zone[] = []
      let sample

      if (_id !== 'DUMMY') {
        zonesDB = this.allZones
          .filter(z => z.station ? z.station._id.toString() === _id : false)
        sample = zonesDB[0]
        this.allZones = this.allZones
          .filter(z => z.station ? z.station._id.toString() !== _id : true)
      } else { // dummy block
        zonesDB = this.allZones
          .filter(z => z.station === null)
        this.unlinkedZones = zonesDB
        sample = {
          station: {
            _id: null,
            name: 'DUMMY STATION',
            formattedAddress: 'The void'
          },
          parkName: 'DUMMY PARK', peakParkPower: 404 }
        this.allZones = this
          .allZones.filter(z => z.station !== null)
      }
      await this.getEchargers()
      const park = new Park({
        ...sample,
        _id: sample.station._id,
        name: sample.parkName,
        zones: zonesDB.map((zone) => ({ ...zone }))
      })

      let zones = []

      // controls for zones

      const zoneFormArray =
        this.getFormArray(this.createParkForm.get('zones'))

      // Remove the first zone row if there is no zone data for the station
      // Or remove the overhead rows on reset (added ones need to be removed)
      const zoneCount = zoneFormArray.length
      const parkZones = park.zones.length
      if (parkZones < zoneCount) {
        for (let i = parkZones; i < zoneCount; i++) {
          this.removeZoneCard(zoneCount - 1 - (i - parkZones))
        }

      // case: not reset but normal load or reset with removed rows
      } else if (parkZones !== zoneCount || !park.zones) {
        for (let j = 0; j < parkZones - zoneCount; j++) {
          this.addZoneCard()
        }
      } else {
        this.addEchargePool(0)
      }
      // controls for echarges
      if (zoneFormArray.length) {
        zoneFormArray.value.forEach((zone, index) => {

          // Remove the first echarger row if there is no echarger data for
          // the station or remove the overhead rows on reset (added ones need
          // to be removed)
          const echargerCount =
            this.getFormArray(zoneFormArray.at(index).get('echargers')).length

          const zoneEchargers = park.zones[index]
            ? park.zones[index].echargers.length
            : 0

          if (zoneEchargers < echargerCount) {
            for (let i = zoneEchargers; i < echargerCount; i++) {
              this.removeEchargerRow(
                index,
                echargerCount - 1 - (i - zoneEchargers)
              )
            }

          // case: not reset but normal load or reset with removed rows
          } else if (zoneEchargers !== echargerCount || !zone.echargers) {
            for (let j = 0; j < zoneEchargers - echargerCount; j++) {
              this.addEchargerRow(index)
            }
          }
        })
      }

      this.zones = park.zones
      zones = park.zones

      zones.forEach((zone) => {
        zone.echargers = zone.echargers.map((e) => ({ echarger: e._id }))
      })

      zones.forEach((zone, index) => {
        for (let i = 0; i < zone.offers.length - 1; i++) {
          this.addOfferRow(index)
        }
      })

      if (_id !== 'DUMMY') {
        this.createParkForm.patchValue({
          ...park,
          zones,
          station: { ...zones[0].station, station: zones[0].station._id },
          customer: park.customer ? park.customer._id : undefined
        })
      } else {
        this.createParkForm.get('name').disable()
        this.createParkForm.get('peakParkPower').disable()
        this.createParkForm.patchValue({
          ...park,
          zones: zones,
          station: { ...sample.station, station: sample.station._id },
          customer: park.customer ? park.customer._id : undefined
        })
      }
      this.resetEchargePool()
      this.createParkForm.markAsPristine()
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private openErrorAlert (message: string): void {
    this.loading = false
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  // here necessary and can not be used from service since addressValidation
  // also needs to be set
  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createParkForm]
  }

  public clearForm () {
    // reset echarger and zone controls to only one
    if (!this.zones.length) {
      this.addZoneCard()
    }

    this.createParkForm.reset()
    this.createParkForm
      .patchValue({ station: { ...this.station, station: this.station._id } })
    for (let i = this.zones.length - 1; i > 0; i--) {
      this.removeZoneCard(i)
    }
    for (let j = this.zones[0].echargers.length; j > 0; j--) {
      this.removeEchargerRow(0, j)
    }
  }

  public isEmpty (obj) {
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== '') {
        return false
      }
    }
    return true
  }

  public getUsedChargers () {
    const zones = this.createParkForm.get('zones').value
    const ids: string[] = []
    if (zones.length) {
      zones.forEach((zone) => {
        zone.echargers.forEach((echarger) => {
          if (echarger && echarger.echarger) {
            ids.push(echarger.echarger.toString())
          }
        })
      })
    }
    return ids
  }

  public hasDuplicates<T> (arr: T[]): boolean {
    return new Set(arr).size < arr.length
  }

  // to push into sorted array
  public findIndex (array: Echarger[], charger: Echarger) {
    return array.length && array
      .findIndex((element) => element.name.localeCompare(charger.name) > 0)
  }

  public getFormArray (control: AbstractControl): FormArray {
    return (control as FormArray)
  }

  private async getPrivileges (page = 0): Promise<void> {
    try {
      const response = await this._privilege
        .searchPrivilege({ page, sort: { name: 'asc' } })
        .toPromise()

      if (response.valid) {
        this.privileges = this.privileges.concat(response.data.data)
      } else {
        this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      if (response.data.hasNext) {
        this.getPrivileges(page + 1)
      }
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private async getCustomers (page = 0): Promise<void> {
    try {
      const response = await this._customer
        .searchCustomer({ page }).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.customers = !page
        ? response.data.data
        : this.customers.concat(response.data.data)

      if (response.data.hasNext) {
        this.getCustomers(page++)
      }
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public addOfferRow (zoneIndex: number): void {
    const zone = this.getFormArray(this.createParkForm.get('zones'))
      .at(zoneIndex)
    const offers = this.getFormArray(zone.get('offers'))

    offers.push(new FormGroup({
      privilege: new FormControl(null),
      maxChargePower:
        new FormControl(undefined, [Validators.required, Validators.min(1)]),
      unitPrice:
        new FormControl(undefined, [Validators.required, Validators.min(0)])
    }))

    if (this.zones[zoneIndex].offers.length < offers.length) {
      this.zones[zoneIndex].offers.push({
        privilege: null,
        maxChargePower: undefined,
        unitPrice: undefined
      })
    }
  }

  public removeOfferRow (zoneIndex: number, offerIndex: number): void {
    (
      (this.createParkForm.get('zones') as FormArray)
        .at(zoneIndex)
        .get('offers') as FormArray
    )
      .removeAt(offerIndex)

    this.zones[zoneIndex].offers.splice(offerIndex, 1)
  }

  public setZonePosition (zone: FormControl, coords: Coords) {
    zone.get('coords').setValue(coords)
  }
}
