import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { User } from '../classes/user.class'
import { Response } from '../classes/response.class'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token$ = new BehaviorSubject<string>(localStorage.getItem('token'))

  public user$ = new BehaviorSubject<User>(undefined)

  private baseUrl = `${environment.baseUrl}${environment.archContext.authn}`

  constructor (private http: HttpClient) {}

  public login (email: string, password: string): Observable<any> {
    return this.http.put<any>(this.baseUrl, { email, password })
    .pipe(
      tap(user => {
        if (user && user.message === 'OK') {
          localStorage.setItem('token', user.data)
          this.token$.next(user.data)
        }
      })
    )
  }

  public logout () {
    localStorage.removeItem('token')
    this.user$.next(undefined)
    this.token$.next(undefined)
    localStorage.removeItem('loginInfo')
  }

  public getProfile () {
    return this.http.get<Response<User>>(`${environment.baseUrl}/account/user`)
      .pipe(
        map(({ message, data }) => new Response(message, new User(data))),
        tap(({ data }) => this.user$.next(data))
      )
  }

  public updateProfile (user) {
    return this.http.patch<Response<User>>(`${environment.baseUrl}/account/user`, user)
      .pipe(
        map(({ message, data }) => new Response(message, new User(data))),
        tap(({ data }) => this.user$.next(data))
      )
  }

  public changePassword (oldPassword: string, newPassword: string)
    : Observable<any> {
    return this.http
      .patch<any>(`${this.baseUrl}/password`, {
        oldPassword,
        newPassword
      })
  }

  public summary (): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/summary`)
  }
}
