export class Activity {
  _id: string
  url: string
  method: string
  urlRegex: string

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'method', 'urlRegex']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.url && obj.url.trim().length) {
      this.url = obj.url
    }
  }

  toString (): string {
    return `${this.method} ${this.url}`
  }
}
