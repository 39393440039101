import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core'
import { FormGroup, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms'
import { UtilityService } from 'src/app/services/utility.service'
import { Echarger } from 'src/app/classes/echarger.class'
import { Alert } from 'src/app/classes/alert.class'
import { Coords } from 'src/app/classes/coords.class'
import { FormCanDeactivate } from '../../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { Privilege } from 'src/app/classes/privilege.class'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'

@Component({
  selector: 'app-create-zone',
  templateUrl: './create-zone.component.html',
  styleUrls: ['./create-zone.component.scss']
})
export class CreateZoneComponent
  extends FormCanDeactivate implements AfterViewInit {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  @ViewChild('echargersComponent')
  echargersComponent: CheckboxContainerComponent

  public echargers: Echarger[] = []

  public siteCoords: Coords

  public createZoneForm: FormGroup = new FormGroup({
    _id: new FormControl(),
    name: new FormControl('', Validators.required),
    tariffs: new FormArray([
      new FormGroup({
        privilege: new FormControl(null),
        maxChargePower:
          new FormControl(undefined, [Validators.required, Validators.min(1)]),
        unitPrice:
          new FormControl(undefined, [Validators.required, Validators.min(0)])
      })
    ]),
    coords: new FormGroup({
      lat: new FormControl(undefined, Validators.required),
      lng: new FormControl(undefined, Validators.required)
    })
  })

  public privileges: Privilege[] = []
  public updateMode = false
  public loading = false
  public alert: Alert

  constructor (
    public _utility: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateZoneComponent>,
  ) {
    super()
    // this.init()
  }

  ngAfterViewInit (): void {
    this.init()
  }

  public async init () {
    this.loading = true
    this.privileges = this.data.availablePrivileges
    this.echargers = this.data.availableEchargers
    this.siteCoords = this.data.siteCoords
    this.createZoneForm.patchValue({
      coords: this.siteCoords,
      ...this.data.zone
    })
    this.updateMode = !!this.data.zone
    // a timeout is needed to keep consistent the setSelected
    setTimeout(() => {
      this.echargersComponent
        .setSelected((this.data.zone && this.data.zone.echargers) || [])
    }, 0)
    this.loading = false
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createZoneForm]
  }

  public getFormArray (control: AbstractControl): FormArray {
    return (control as FormArray)
  }

  public addTariffRow (): void {
    const tariffs = this.getFormArray(this.createZoneForm.get('tariffs'))

    tariffs.push(new FormGroup({
      privilege: new FormControl(null),
      maxChargePower:
        new FormControl(undefined, [Validators.required, Validators.min(1)]),
      unitPrice:
        new FormControl(undefined, [Validators.required, Validators.min(0)])
    }))
  }

  public removeTariffRow (tariffIndex: number): void {
    (this.createZoneForm.get('tariffs') as FormArray)
      .removeAt(tariffIndex)
  }

  public saveData () {
    const { value } = this.createZoneForm
    this.dialogRef.close({
      ...value,
      echargers: this.echargersComponent.selected
    })
  }
}
