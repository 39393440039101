import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'formatUnit'
})
export class FormatUnitPipe implements PipeTransform {
  transform (value: string, unit: string): string {
    let s = `${value || 0}`

    if (unit) {
      s += ` ${unit}`
    }

    return s
  }
}
