import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { map, takeWhile } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { ActivatedRoute } from '@angular/router'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { OrderService } from 'src/app/services/order.service'
import { ChargingProcessDetailData } from 'src/app/classes/charging-history-detail-data.class'
import { AdminService } from 'src/app/services/admin.service'
import { UtilityService } from 'src/app/services/utility.service'
import { DateFormat } from 'src/app/enums/date-format.enum'
import { MessageOCPP } from 'src/app/enums/echarger-message.enum'
import { PaymentState } from 'src/app/enums/payment-state.enum'

@Component({
  selector: 'app-charging-history-admin-detail',
  templateUrl: './charging-history-admin-detail.component.html',
  styleUrls: ['./charging-history-admin-detail.component.scss']
})
export class ChargingHistoryAdminDetailComponent {
  public loading = true
  public alert: Alert
  public pageData: Observable<ChargingProcessDetailData>
  public error: string
  public chartOptions = {
    colorScheme: {
      domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
    },
    legend: true,
    showLabels: true,
    animations: true,
    xAxis: true,
    yAxis: true,
    showYAxisLabel: true,
    showXAxisLabel: true,
    xAxisLabel: 'Charging time [min]',
    yAxisLabel: 'Power [kW]',
  }

  public DateFormat = DateFormat
  public Message = MessageOCPP

  constructor (
    private _translate: TranslateService,
    public _order: OrderService,
    public _admin: AdminService,
    private route: ActivatedRoute,
    public _utility: UtilityService
  ) {
    this.pageData = this._admin
      .getAdminOrder(this.route.snapshot.paramMap.get(`_id`)).pipe(
        map((response) => {
          this.loading = false

          if (response.invalid) {
            this.openErrorAlert(this._translate.instant(response.message))
            throw new Error(response.message)
          }

          return new ChargingProcessDetailData(response.data, this._translate)
        }),
        takeWhile(({ transaction }) =>
          (
            transaction.active ||
            (
              transaction.order && (
                (transaction.order.paymentState === PaymentState.PENDING) ||
                (
                  transaction.order.products[0].totalPrice.value > 0 &&
                  !transaction.order.invoice
                )
              )
            )
          ),
          true
        )
      )
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  public async viewInvoice (orderId: string): Promise<void> {
    const response = await this._order.getInvoice(orderId).toPromise()
    const url = window.URL.createObjectURL(response.data)
    const anchor = document.createElement('a')

    anchor.href = url
    anchor.target = '_blank'
    anchor.click()
  }

  public transactionDataId (index: number) {
    return index
  }
}
