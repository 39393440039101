import { Coords } from './coords.class'

export class Place {
  _id: string
  coords: Coords
  city: string
  country: string
  number: string
  street: string
  zip: string

  constructor (obj: any) {
    const properties = ['_id', 'city', 'country', 'number', 'street', 'zip']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.coords) {
      this.coords = new Coords({
        lat: obj.coords[1],
        lng: obj.coords[0]
      })
    }
  }

  get formattedCity (): string {
    let fcity = ''
    if (this.zip) { fcity += this.zip }
    if (this.zip && this.city) { fcity += ' ' }
    if (this.city) { fcity += this.city }
    if (this.country && (this.zip || this.city)) { fcity += ', ' }
    if (this.country) { fcity += this.country }
    return fcity
  }

  get formattedAddress (): string {
    let faddress = ''
    if (this.street) { faddress += this.street }
    if (this.street && this.number) { faddress += ' ' }
    if (this.number) { faddress += this.number }
    return faddress
  }
}
