import { ModalComponent } from '../../components/modal/modal.component'
import { HostListener } from '@angular/core'
import { FormGroup } from '@angular/forms'

// abstract class to allow guard to call the get methods of the different
// components
// implements the window listener for the browser side events (reload/ close)
export abstract class FormCanDeactivate {
  abstract getModal (): ModalComponent

  abstract getForm (): FormGroup[]

  showModal (): any {
    return this.showModal()
  }

  canDeactivate (): boolean {
    return !this.getForm().find(form => form.dirty)
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification ($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true
    }
  }
}
