import { CreatePaymentProviderDTO } from 'src/app/interfaces/create-payment-provider.dto'
import { UpdatePaymentProviderDTO } from 'src/app/interfaces/update-payment-provider.dto'

export class PaymentProvider {
  _id: string
  name: string
  password: string
  description: string
  active: boolean
  creationDate: Date
  updateDate: Date

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'name', 'password', 'active', 'authn',
      'description']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }
  }

  toUpdateDTO (): UpdatePaymentProviderDTO {
    return {
      _id: this._id,
      description: this.description,
      active: this.active
    }
  }

  toCreateDTO (): CreatePaymentProviderDTO {
    return {
      name: this.name,
      password: this.password,
      description: this.description,
      active: this.active
    }
  }
}
