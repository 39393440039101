import { Component } from '@angular/core'
import { MonitoringService } from '../../../services/monitoring.service'
import { Monitoring } from '../../../classes/monitoring.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-system-test',
  templateUrl: './system-test.component.html',
  styleUrls: ['./system-test.component.scss']
})
export class SystemTestComponent {
  public monitoring: Monitoring
  public alert: Alert
  public services: Array<{ name: string, value: string }> = [
    { name: 'arch', value: '' },
    { name: 'account', value: '/account' },
    { name: 'station', value: '/station' },
    { name: 'charge', value: '/echarge' },
    // { name: 'order', value: '/order' },
    { name: 'server echarger', value: '/server-echarger' },
    { name: 'server OCPP', value: '/server-ocpp' }
  ]

  constructor (
    private  _monitoring: MonitoringService,
    private _translate: TranslateService
  ) { }

  /** Method to start the three different API calls
   *  (see monitoring.service for implementation)
   *  @Params: serviceName: the Name of the service seeked after
   *  Note: also triggers the Alert modal if an error is catched
  */
  async setMonitoring (serviceName: string) {
    try {
      // default value
      if (serviceName === '/') {
        return this.monitoring = new Monitoring()
      }

      if (serviceName === '/order') {
        return this.monitoring = new Monitoring(
          { info: { build: { name: 'ms-order', version: '0.0.0' } } }
        )
      }

      const monitoringInfo = await this._monitoring.getInfo(serviceName)
        .toPromise()

      const monitoringMetrics = await this._monitoring
        .getMetrics(serviceName).toPromise()

      const monitoringHealth = await this._monitoring
        .getHealth(serviceName).toPromise()

      this.monitoring = new Monitoring(
        { ...monitoringInfo, ...monitoringMetrics, ...monitoringHealth }
      )

      if (this.alert) {
        this.alert.dismiss()
      }
    } catch (error) {
      this.monitoring = new Monitoring()
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: this._translate.instant('ALERT.MESSAGE.INVALID') + ' '
          + this._translate.instant('FUNC.MICROSERVICES.TEST.ERROR')
      })
      this.alert.present()
    }
  }
}
