import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Pos } from '../classes/pos.class'
import { Response } from '../classes/response.class'

@Injectable({
  providedIn: 'root'
})
export class PosService {
  private baseUrl = `${environment.baseUrl}${environment.orderContext.pos}`

  constructor (private http: HttpClient) { }

  public getAll (): Observable<Response<Pos[]>> {
    return this.http
      .post<Response<Pos[]>>(`${this.baseUrl}/search`, null)
      .pipe(map(({ message, data }) =>
        new Response<Pos[]>(message, data && data.map((pos) => new Pos(pos)))
      ))
  }
}
