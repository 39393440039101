export class Evccid {
  _id: string
  evccid: string
  capacity: number

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'evccid', 'capacity']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }
  }

  toString (): string {
    return this.evccid
  }
}
