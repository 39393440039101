import { Vendor } from './vendor.class'
import { User } from './user.class'
import { Amount } from './amount.class'
import { Payment } from './payment.class'
import { OrderProduct } from './order-product.class'
import { OrderStatus } from './order-status.class'
import { PaymentState } from '../enums/payment-state.enum'

class Invoice {
  _id: string
  number: number
  name: string
  path: string
  date: Date

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['number', 'name', 'path']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.id) {
      this._id = obj.id
    }

    if (obj.date) {
      this.date = new Date(obj.date)
    }
  }

  public toString (): string {
    return this.name
  }
}

export class Order {
  _id: string
  orderNumber: number
  user: User
  vendor: Vendor
  products: OrderProduct[] = []
  status: OrderStatus[] = []
  transactionReference: string
  invoice: Invoice
  payment: Payment
  realAmount: Amount
  plate: string
  creationDate: Date
  updateDate: Date

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['orderNumber', 'vendor', 'plate']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.id) {
      this._id = obj.id
    }

    if (obj.payment) {
      this.payment = new Payment(obj.payment)
    }

    if (obj.realAmount) {
      this.realAmount = new Amount(obj.realAmount)
    }

    if (obj.transactionId) {
      this.transactionReference = obj.transactionId
    }

    if (obj.invoice) {
      this.invoice = new Invoice(obj.invoice)
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }

    for (const status of obj.status || []) {
      this.status.push(new OrderStatus(status))
    }

    for (const product of obj.products || []) {
      this.products.push(new OrderProduct(product))
    }

    if (obj['user']) {
      this.user = obj['user'] instanceof User
        ? obj['user']
        : new User(obj['user'])
    }
  }

  public toString (): string {
    return this.orderNumber.toString()
  }

  public get paymentState (): PaymentState {
    let result
    switch (this.status[this.status.length - 1].description) {
      case 'CAPTURE_SUCCESS':
        result = PaymentState.PAID
        break
      case 'AUTHORISATION_FAIL':
        result = PaymentState.AUTH_FAIL
        break
      case 'CAPTURE_FAIL':
        result = PaymentState.CAPTURE_FAIL
        break
      case 'CREATED':
        result = PaymentState.CANCELED
        break
      default:
        result = PaymentState.PENDING
    }

    return result
  }
}
