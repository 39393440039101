export class DataPage<T> {
  data: T[]
  page: number
  size: number
  total: number

  constructor (obj) {
    if (obj.page) { this.page = obj.page }
    if (obj.data) { this.data = obj.data }
    if (obj.total !== undefined) { this.total = obj.total }
    if (obj.size) { this.size = obj.size }
  }

  get hasNext (): boolean {
    if (this.total < this.size * this.page) {
      return false
    }

    return (this.page - 1) * this.size + this.data.length < this.total
  }
}
