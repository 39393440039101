import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { APP_BASE_HREF, DatePipe } from '@angular/common'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { NgxLoadingModule } from 'ngx-loading'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material'
import { MatDividerModule, MatCardModule, MatButtonModule, MatSelectModule, MatChipsModule } from '@angular/material'
import { MatDatepickerModule, MatNativeDateModule, MatDialogModule } from '@angular/material'
import { MatTableModule } from '@angular/material/table'
import { MatInputModule } from '@angular/material/input'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatExpansionModule } from '@angular/material/expansion'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'
import { MatAutocompleteModule } from '@angular/material/autocomplete'

// Guards
import { AuthGuard } from './guards/auth.guard'
import { LeavePageGuard } from './guards/leave-page/leave-page.guard'

// Helpers
import { JwtInterceptor } from './helpers/jwt.interceptor'
import { ImageCropperModule } from 'ngx-image-cropper'

// Components
import { AppComponent } from './app.component'
import { HeaderComponent } from './components/header/header.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { AddressComponent } from './components/address/address.component'
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component'
import { ModalComponent } from './components/modal/modal.component'
import { AlertComponent } from './components/alert/alert.component'
import { MapComponent } from './components/map/map.component'
import { SummaryComponent } from './components/summary/summary.component'
import { SearchBoxComponent } from './components/search-box/search-box.component'
import { LocationPickerComponent } from './components/location-picker/location-picker.component'

// Pages
import { LoginComponent } from './pages/login/login.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { SignupComponent } from './pages/signup/signup.component'
import { CreateCarComponent } from './pages/cars/create-car/create-car.component'
import { SearchCarComponent } from './pages/cars/search-car/search-car.component'
import { SearchPlugComponent } from './pages/plugs/search-plug/search-plug.component'
import { CreatePlugComponent } from './pages/plugs/create-plug/create-plug.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { PreferencesComponent } from './pages/preferences/preferences.component'
import { CreateActivityComponent } from './pages/security/activities/create-activity/create-activity.component'
import { SearchActivityComponent } from './pages/security/activities/search-activity/search-activity.component'
import { CreatePermissionComponent } from './pages/security/permissions/create-permission/create-permission.component'
import { SearchPermissionComponent } from './pages/security/permissions/search-permission/search-permission.component'
import { CreateRoleComponent } from './pages/security/roles/create-role/create-role.component'
import { SearchRoleComponent } from './pages/security/roles/search-role/search-role.component'
import { CreateUserComponent } from './pages/users/create-user/create-user.component'
import { EditUserAccessComponent } from './pages/users/edit-user-access/edit-user-access.component'
import { SearchUserComponent } from './pages/users/search-user/search-user.component'
import { CreateEchargerComponent } from './pages/echargers/create-echarger/create-echarger.component'
import { SearchEchargerComponent } from './pages/echargers/search-echarger/search-echarger.component'
import { VirtualEchargerComponent } from './pages/echargers/virtual-echarger/virtual-echarger.component'
import { CreateStationComponent } from './pages/stations/create-station/create-station.component'
import { SearchStationComponent } from './pages/stations/search-station/search-station.component'
import { CreateVendorComponent } from './pages/vendors/create-vendor/create-vendor.component'
import { SearchVendorComponent } from './pages/vendors/search-vendor/search-vendor.component'
import { SearchParkComponent } from './pages/park/search-park/search-park.component'
import { CreateParkComponent } from './pages/park/create-park/create-park.component'
import { SearchParkStationComponent } from './pages/park/search-park-station/search-park-station.component'
import { LinkZoneComponent } from './pages/park/link-zone/link-zone.component'
import { CreatePrivilegeComponent } from './pages/privileges/create-privilege/create-privilege.component'
import { SearchPrivilegeComponent } from './pages/privileges/search-privilege/search-privilege.component'

// Services
import { LoadingService } from './services/loading.service'
import { LoadingComponent } from './components/loading/loading.component'
import { SystemTestComponent } from './pages/monitoring/system-test/system-test.component'
import { ToHoursAndMinutesPipe } from './pipes/to-hours-and-minutes.pipe'
import { BytesToMegabytesPipe } from './pipes/bytes-to-megabytes.pipe'
import { ToFormattedAddressPipe } from './pipes/to-formatted-address.pipe'
import { CheckboxContainerComponent } from './components/checkbox-container/checkbox-container.component'
import { ChargingHistoryComponent } from './pages/charging-history/charging-history/charging-history.component'
import { ChargingHistoryDetailComponent } from './pages/charging-history/charging-history-detail/charging-history-detail.component'
import { PageTitleComponent } from './components/page-title/page-title.component'
import { SummaryCardComponent } from './components/summary-card/summary-card.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { SectionTitleComponent } from './components/section-title/section-title.component'
import { NameValuePairComponent } from './components/name-value-pair/name-value-pair.component'
import { NameValuePairsComponent } from './components/name-value-pairs/name-value-pairs.component'
import { KpiCardComponent } from './components/kpi-card/kpi-card.component'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { FormatUnitPipe } from './pipes/format-unit.pipe'
import { BackButtonDirective } from './directives/back-button.directive'
import { BackNavigationService } from './services/back-navigation.service'
import { EchargerDetailComponent } from './pages/echargers/echarger-detail/echarger-detail.component'
import { BannerComponent } from './pages/mobile-app/banner/banner.component'
import { SearchCustomerComponent } from './pages/customers/search/search-customer.component'
import { CreateCustomerComponent } from './pages/customers/create/create-customer.component'
import { RouteReuseStrategy } from '@angular/router'
import { PathBasedReuseStrategy } from './path-based-reuse-strategy'
import { CreateDeviceComponent } from './pages/devices/create/create-device.component'
import { SearchDeviceComponent } from './pages/devices/search/search-device.component'
import { CreatePaymentproviderComponent } from './pages/payment-providers/create/create-payment-provider.component'
import { SearchPaymentproviderComponent } from './pages/payment-providers/search/search-payment-provider.component'
import { ChargingHistoryAdminComponent } from './pages/charging-history-admin/charging-history-admin/charging-history-admin.component'
// tslint:disable-next-line: max-line-length
import { ChargingHistoryAdminDetailComponent } from './pages/charging-history-admin/charging-history-admin-detail/charging-history-admin-detail.component'
import { ExcelService } from './services/excel.service'

export function HttpLoaderFactory (http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    DashboardComponent,
    SignupComponent,
    HeaderComponent,
    CreateCarComponent,
    SearchCarComponent,
    SearchPlugComponent,
    CreatePlugComponent,
    ProfileComponent,
    PreferencesComponent,
    CreateActivityComponent,
    SearchActivityComponent,
    CreatePermissionComponent,
    SearchPermissionComponent,
    CreateRoleComponent,
    SearchRoleComponent,
    CreateUserComponent,
    EditUserAccessComponent,
    SearchUserComponent,
    MapComponent,
    SummaryComponent,
    CreateEchargerComponent,
    SearchEchargerComponent,
    VirtualEchargerComponent,
    EchargerDetailComponent,
    CreateStationComponent,
    SearchStationComponent,
    AddressComponent,
    BreadcrumbComponent,
    CreateVendorComponent,
    SearchVendorComponent,
    ModalComponent,
    AlertComponent,
    LoadingComponent,
    SystemTestComponent,
    ToHoursAndMinutesPipe,
    BytesToMegabytesPipe,
    ToFormattedAddressPipe,
    CheckboxContainerComponent,
    SearchParkComponent,
    CreateParkComponent,
    SearchParkStationComponent,
    LinkZoneComponent,
    SearchBoxComponent,
    BannerComponent,
    SearchCustomerComponent,
    CreateCustomerComponent,
    CreatePrivilegeComponent,
    SearchPrivilegeComponent,
    ChargingHistoryComponent,
    ChargingHistoryDetailComponent,
    PageTitleComponent,
    SummaryCardComponent,
    FormatUnitPipe,
    SectionTitleComponent,
    NameValuePairsComponent,
    NameValuePairComponent,
    KpiCardComponent,
    BackButtonDirective,
    CreateDeviceComponent,
    SearchDeviceComponent,
    CreatePaymentproviderComponent,
    SearchPaymentproviderComponent,
    ChargingHistoryAdminComponent,
    ChargingHistoryAdminDetailComponent,
    LocationPickerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxLoadingModule.forRoot({}),
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatSelectModule,
    ImageCropperModule,
    MatDialogModule,
    NgxSliderModule,
    NgxDaterangepickerMd.forRoot(),
    FlexLayoutModule,
    MatDividerModule,
    NgxChartsModule,
    MatButtonModule,
    MatSelectModule,
    MatChipsModule
  ],
  providers: [
    AuthGuard,
    ExcelService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    LoadingService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: { dateInput: 'LL' },
        display: {
          dateInput: 'DD MMM YYYY',
          monthYearLabel: 'YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY'
        }
      }
    },
    LeavePageGuard,
    DatePipe,
    BackNavigationService,
    { provide: RouteReuseStrategy, useClass: PathBasedReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
