import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Alert } from 'src/app/classes/alert.class'
import { ModalComponent } from '../../../components/modal/modal.component'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-create-vendor',
  templateUrl: './create-vendor.component.html',
  styleUrls: ['./create-vendor.component.scss']
})

export class CreateVendorComponent implements OnInit {
  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public updateMode = false
  public loading = true
  public alert: Alert
  public createVendorForm: FormGroup = new FormGroup({
    _id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    active: new FormControl(true, [Validators.required]),
    description: new FormControl('', [Validators.required])
  })

  constructor (
    public dialogRef: MatDialogRef<CreateVendorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit () {
    if (!this.data) {
      this.loading = false
      return
    }
    this.createVendorForm.patchValue(this.data)
    this.updateMode = true
    this.loading = false
  }

  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public cancel () {
    this.dialogRef.close()
  }

  public submit () {
    if (this.createVendorForm.invalid) {
      return this.markFormGroupTouched(this.createVendorForm)
    }
    this.loading = true
    return this.dialogRef.close(this.createVendorForm.value)
  }
}
