import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { FileService } from './file.service'
import { User } from '../classes/user.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private archUrl = `${environment.baseUrl}${environment.archContext.authn}`
  private accountUrl = `${environment.baseUrl}${environment.accountContext.account}`

  constructor (
    private http: HttpClient,
    private _file: FileService
  ) { }

  public createUser (form: Object): Observable<Response<User>> {
    return this.http
      .post<Response<User>>(`${this.archUrl}/user`, this._file.multipart(form))
      .pipe(map(({ message, data }) =>
        new Response<User>(message, new User(data))
      ))
  }

  public getUser (authn: string): Observable<Response<User>> {
    return this.http
      .get<Response<User>>(`${this.accountUrl}/user/${authn}`)
      .pipe(map(({ message, data }) =>
        new Response<User>(message, new User(data))
      ))
  }

  public updateUser (form): Observable<Response<User>> {
    const query = { ...form, authn: form.authn._id }

    return this.http
      .patch<Response<User>>(`${this.accountUrl}/user/${form.authn._id}`, query)
      .pipe(map(({ message, data }) =>
        new Response<User>(message, new User(data))
      ))
  }

  public deleteUser (_id: string): Observable<Response<User>> {
    return this.http
      .delete<Response<User>>(`${this.archUrl}/user/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<User>(message, new User(data))
      ))
  }

  public searchUser (form?: Object): Observable<Response<DataPage<User>>> {
    return this.http
      .post<Response<DataPage<User>>>(`${this.accountUrl}/user/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<DataPage<User>>(
          message,
          new DataPage({ ...data, data: data.data.map(c => new User(c)) })
        )
      ))
  }
}
