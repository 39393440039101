import { Pipe, PipeTransform } from '@angular/core'

/** Pipe to transform Seconds into the dd.hh.mm format (hh.mm if needed)
 * @Params: time in Seconds
 */

@Pipe({
  name: 'toHoursAndMinutes'
})
export class ToHoursAndMinutesPipe implements PipeTransform {
  transform (uptime: number, showSeconds: boolean): string {
    const days = Math.floor(uptime / 86400)
    const hours = Math.floor((uptime - days * 86400) / 3600.0)
    const minutes = Math.floor((uptime - days * 86400 - hours * 3600) / 60)
    const seconds = Math
      .floor(((uptime - days * 86400 - hours * 3600 - minutes * 60)))

    let timeString = ''

    if (days > 0) {
      timeString = `${days}d ${hours}h ${minutes}min`
    } else if (hours > 0) {
      timeString = `${hours}h ${minutes}min`
    } else if (minutes > 0) {
      timeString = `${minutes}min`
    }

    if (timeString.length && showSeconds) {
      timeString += ' '
    }

    if (!timeString.length || showSeconds) {
      timeString += `${seconds}s`
    }

    return timeString
  }
}
