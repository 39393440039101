import { Component, OnInit } from '@angular/core'
import { FormGroup, Validators, FormControl } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from 'src/app/services/auth.service'
import { LoadingService } from '../../services/loading.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup = new FormGroup ({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl(
      '',
      [Validators.required, Validators.minLength(5)]
    ),
    remember: new FormControl(false)
  })
  private returnUrl: string
  public loginErrorMessage: string

  constructor (
    private router: Router,
    private route: ActivatedRoute,
    private _auth: AuthService,
    private _loading: LoadingService,
    private _translate: TranslateService
  ) { }

  ngOnInit () {
    this.returnUrl = this.route.snapshot
      .queryParams['returnUrl'] || '/dashboard'
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
    if (loginInfo) {
      this.loginForm.patchValue(loginInfo)
    }
    if (loginInfo.remember) {
      this._auth.login(loginInfo.email, loginInfo.password).subscribe((r) => {
        if (r.message === 'OK') {
          this.router.navigateByUrl('/dashboard')
        }
      })
    }
  }

  async login () {
    this.loginErrorMessage = ''
    if (!this.loginForm.valid) {
      return Object.values(this.loginForm.controls)
        .forEach(field => field.markAsTouched({ onlySelf: true }))
    }

    this._loading.isLoading.next(true)
    const { email, password, remember } = this.loginForm.value
    try {
      const token = await this._auth.login(email, password).toPromise()
      if (!token.data || token.message !== 'OK') {
        throw new Error ('Authentification failed')
      }
      if (remember) {
        localStorage.setItem('loginInfo', JSON.stringify(this.loginForm.value))
      } else {
        localStorage.setItem('loginInfo', JSON.stringify({ email }))
      }
      const res: any =  await this._auth.getProfile().toPromise()
      if (!res.data || res.message !== 'OK') {
        throw new Error ('User information pull failed')
      }
      this._translate.use(res.data.locale)
      this.router.navigateByUrl(this.returnUrl)
    } catch (error) {
      this.loginErrorMessage = this._translate.instant(error.error
        ? error.error.message
        : error.message
      )
      this._loading.isLoading.next(false)
    }
  }

  public getYear (): string {
    return `${(new Date()).getFullYear()}`
  }
}
