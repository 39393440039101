import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { UtilityService } from '../../../services/utility.service'
import { Alert } from 'src/app/classes/alert.class'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-create-plug',
  templateUrl: './create-plug.component.html',
  styleUrls: ['./create-plug.component.scss']
})
export class CreatePlugComponent implements OnDestroy, OnInit {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  private subscriptions: Subscription[] = []
  public updateMode = false
  public loading = true

  public createPlugForm: FormGroup = new FormGroup({
    _id: new FormControl(''),
    dataSmall: new FormControl('', [Validators.required]),
    dataLong: new FormControl('', [Validators.required]),
    active: new FormControl(true, [Validators.required])
  })
  public alert: Alert

  constructor (
    public dialogRef: MatDialogRef<CreatePlugComponent>,
    public _utility: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit () {
    if (!this.data) {
      this.loading = false
      return
    }
    this.createPlugForm.patchValue(this.data)
    this.updateMode = true
    this.loading = false
  }

  ngOnDestroy () {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  public cancel () {
    this.dialogRef.close()
  }

  public submit () {
    if (this.createPlugForm.invalid) {
      return this._utility.markFormGroupTouched(this.createPlugForm)
    }
    this.loading = true
    return this.dialogRef.close(this.createPlugForm.value)
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }
}
