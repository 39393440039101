import { Component, ViewChild } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { Country } from 'src/app/enums/country.enum'
import { FacilityType } from 'src/app/enums/facility-type.enum'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'
import { SiteService } from 'src/app/services/site.service'

@Component({
  selector: 'app-search-site',
  templateUrl: './search-site.component.html',
  styleUrls: ['./search-site.component.scss']
})
export class SearchSiteComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator

  @ViewChild('facilitiesComponent') facilitiesComponent: CheckboxContainerComponent

  public facilityType = Object.keys(FacilityType)
    .filter((v) => isNaN(Number(v)))
    .map(f => ({ label: f, value: f , toString: () => f }))

  public countries = Object.keys(Country).filter((v) => isNaN(Number(v)))

  public searchSiteForm: FormGroup = new FormGroup({
    name: new FormControl(),
    address: new FormControl(),
    city: new FormControl(),
    postalCode: new FormControl(),
    state: new FormControl(),
    country: new FormControl(),
    active: new FormControl(),
    publish: new FormControl()
  })

  public displayedColumns: string[] = [
    'NAME',
    'ADDRESS',
    'ACTIVE',
    'PUBLISH',
    'BUTTONS'
  ]
  public dataSource: MatTableDataSource<any>
  public modal: Modal
  public alert: Alert
  public loading = false

  constructor (
    private _translate: TranslateService,
    private _site: SiteService
  ) {
  }

  public async search (page?: number): Promise<void> {
    try {
      this.loading = true
      const values = this.searchSiteForm.value
      const query = {}

      if (page) { query['page'] = page }
      if (values.name) { query['name'] = values.name }
      if (values.address) { query['address'] = values.address }
      if (values.city) { query['city'] = values.city }
      if (values.postalCode) { query['postalCode'] = values.postalCode }
      if (values.state) { query['state'] = values.state }
      if (values.country) { query['country'] = values.country }
      if (values.active !== undefined) { query['active'] = values.active }
      if (values.publish !== undefined) { query['publish'] = values.publish }
      if (this.facilitiesComponent.selected.length) {
        query['facilities'] = this.facilitiesComponent.selected
      }

      const response = await this._site.searchSite(query).toPromise()

      if (response.invalid) {
        if (this.dataSource.data.length) {
          this.alert = new Alert({
            type: AlertType.WARNING,
            message: `${this._translate.instant('ALERT.MESSAGE.WARNING')}` +
              `${this._translate.instant('ALERT.MESSAGE.PARTIAL_RESUTS')}`
          })
        } else {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
          })
        }
        this.alert.present()
        this.loading = false
        return
      }

      if (response.data.page === 1) {
        this.dataSource = new MatTableDataSource(response.data.data)
      } else {
        this.dataSource = new MatTableDataSource(
          this.dataSource.data.concat(response.data.data))
      }
      this.dataSource.paginator = this.paginator

      if (response.data.hasNext) {
        this.search((page || 0) + 1)
      } else {
        this.loading = false
      }
    } catch (error) {
      if (
        this.dataSource &&
        this.dataSource.data &&
        this.dataSource.data.length
      ) {
        this.alert = new Alert({
          type: AlertType.WARNING,
          message: `${this._translate.instant('ALERT.MESSAGE.WARNING')}` +
            `${this._translate.instant('ALERT.MESSAGE.PARTIAL_RESUTS')}`
        })
      } else {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
        })
      }
      this.alert.present()
      this.loading = false
    }
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public showModal (el: any): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_STATION'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_STATION')} ${el.name}?`,
      buttons: [{ title: this._translate.instant('BUTTON.DELETE') }],
      data: el._id
    })

    this.modal.confirm()
  }

  public async delete (_id: string): Promise<boolean> {
    try {
      const response = await this._site.deleteSite(_id).toPromise()
      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        return false
      }

      this.dataSource.data = this.dataSource.data
        .filter((station) => station._id !== _id)

      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.STATION_DELETED')
      })
      this.alert.present()
      return true
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      return false
    }
  }
}
