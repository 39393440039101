import { BannerMessage } from '../interfaces/banner-message'

export class Banner {
  _id: string
  active: boolean
  description: string
  creationDate: Date
  updateDate: Date
  expireDate: Date
  messages: BannerMessage[]

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'active', 'description', 'messages']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }

    if (obj.expireDate) {
      this.expireDate = new Date(obj.expireDate)
    }

  }

  get expired (): boolean {
    return new Date().getTime() > this.expireDate.getTime()
  }

  toString (): string {
    return this.description
  }
}
