export enum MessageEloaded {
  GUN_STAT = '40',
  CHARGE_INFO = '41',
  CHARGE_CONTROL = '45'
}

export enum MessageOCPP {
  BOOT_NOTIFICATION = 'BootNotification',
  DATA_TRANSFER = 'DataTransfer',
  HEARTBEAT = 'Heartbeat',
  METER_VALUES = 'MeterValues',
  REMOTE_START_TRANSACTION = 'RemoteStartTransaction',
  REMOTE_STOP_TRANSACTION = 'RemoteStopTransaction',
  RESET = 'Reset',
  START_TRANSACTION = 'StartTransaction',
  STATUS_NOTIFICATION = 'StatusNotification',
  STOP_TRANSACTION = 'StopTransaction',
  TRIGGER_MESSAGE = 'TriggerMessage',
  UNLOCK_CONNECTOR = 'UnlockConnector'
}
