import { ModalButton } from '../interfaces/modal-button'

export class Modal {
  title: string
  body: string
  buttons: ModalButton[]
  type: string
  data: Object | null

  constructor (obj: Object) {
    const properties = ['title', 'body', 'buttons', 'type', 'data']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }
  }

  present () {
    this.type = 'present'
  }

  confirm () {
    this.type = 'confirm'
  }

  dismiss () {
    delete this.type
  }
}
