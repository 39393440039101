import { Health, Metrics, Info } from '../interfaces/monitoring'

export class Monitoring {
  health: Health
  metrics: Metrics
  info: Info
  found: Boolean = true

  constructor (obj?: Object) {
    if (!obj) { return }

    const properties = ['info', 'metrics', 'health', 'git']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }
  }
}
