import { Component } from '@angular/core'
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  user$ = this._auth.user$

  constructor (
    private _auth: AuthService,
    private router: Router
  ) { }

  public toggleSidebar () {
    const sidebar = document.getElementById('sidebar-container')
    if (sidebar.classList.contains('visible')) {
      sidebar.classList.remove('visible')
    } else {
      sidebar.classList.add('visible')
    }
  }

  public logout () {
    this._auth.logout()
    this.router.navigate(['/login'])
  }
}
