export class OrderStatus {
  description: string
  creationDate: Date

  constructor (obj: any = {}) {
    if (obj.description) {
      this.description = obj.description
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }
  }
}
