import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Transaction, Connection } from '../classes/transaction.class'
import { DataPage } from '../classes/data-page.class'

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private baseUrl =
    `${environment.baseUrl}${environment.echargeContext.transaction}`

  private connectionUrl =
    `${environment.baseUrl}${environment.echargeContext.connection}`

  private chargeUrl =
    `${environment.baseUrl}${environment.echargeContext.echarge}`

  constructor (private http: HttpClient) { }

  public searchTransaction (form): Observable<Response<DataPage<Transaction>>> {
    return this.http
      .post<Response<DataPage<Transaction>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<DataPage<Transaction>>(
          message,
          new DataPage(
            { ...data, data: data.data
              ? data.data.map(t => new Transaction(t))
              : []
            }
          ))))
  }

  public getTransaction (_id: string): Observable<Response<Transaction>> {
    return this.http
      .get<Response<Transaction>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Transaction>(message, new Transaction(data))))
  }

  public deleteTransaction (_id: string): Observable<Response<Transaction>> {
    return this.http
      .delete<Response<Transaction>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Transaction>(message, new Transaction(data))))
  }

  public getEchargersConnection (
    echarger_id: string
  ): Observable<Response<Connection>> {
    return this.http
      .get<Response<Connection>>(`${this.connectionUrl}/${echarger_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Connection>(message, new Connection(data))
      ))
  }

  // TODO create response class
  public startCharge (form): Observable<Response<String>> {
    return this.http
      .post<Response<String>>(`${this.chargeUrl}/start`, form)
      .pipe(map(({ message, data }) =>
        new Response<String>(message, data)
      ))
  }

  // TODO create response class
  public stopCharge (echarger_id): Observable<Response<any>> {
    return this.http
      .get<Response<any>>(`${this.chargeUrl}/stop/${echarger_id}`)
      .pipe(map(({ message, data }) =>
        new Response<any>(message, data)
      ))
  }

  // TODO create response class
  public chargeDetail (echarger_id): Observable<Response<any>> {
    return this.http
      .get<Response<any>>(`${this.chargeUrl}/detail/${echarger_id}`)
      .pipe(map(({ message, data }) =>
        new Response<any>(message, data)
      ))
  }

  public wipePendingTransactions (echarger_id): Observable<Response<null>> {
    return this.http
      .delete<Response<null>>(`${this.baseUrl}/wipe/${echarger_id}`)
      .pipe(map(({ message }) =>
        new Response<null>(message, null)
      ))
  }
}
