import { Component, OnDestroy, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import { FormBuilder, FormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ActivityService } from '../../../../services/activity.service'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Activity } from '../../../../classes/activity.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'

@Component({
  selector: 'app-search-activity',
  templateUrl: './search-activity.component.html',
  styleUrls: ['./search-activity.component.scss']
})
export class SearchActivityComponent implements OnDestroy {

  public searchActivityForm: FormGroup
  private subscriptions: Subscription[] = []
  public displayedColumns: string[] = ['URL', 'METHOD', 'URLREGEX', 'BUTTONS']
  public dataSource: MatTableDataSource<Activity>
  public loading = false
  public modal: Modal
  public alert: Alert
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor (
    private fb: FormBuilder,
    private _translate: TranslateService,
    private _activity: ActivityService
  ) {
    this.createForm()
  }

  ngOnDestroy () {
    this.subscriptions.forEach((subscribtion) => subscribtion.unsubscribe())
  }

  public search (page?: number): void {
    this.loading = true
    const activity = new Activity(this.searchActivityForm.value)

    const query = {}
    if (page) {
      query['page'] = page
      query['url'] = activity.url
      query['method'] = activity.method
      query['urlRegex'] = activity.urlRegex
    }

    this.subscriptions
    .push(this._activity.searchActivity(page ? query : activity)
      .subscribe(
      (success) => {
        if (!success.isValid()) {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${success.message}`
          })
          this.alert.present()
          this.loading = false
          return
        }

        if (success.data.page === 1) {
          this.dataSource = new MatTableDataSource(success.data.data)
        } else {
          this.dataSource = new MatTableDataSource(
          this.dataSource.data.concat(success.data.data))
        }
        this.dataSource.paginator = this.paginator
        if (success.data.hasNext) {
          this.search((page || 0) + 1)
        } else {
          this.loading = false
        }
      }, error => {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
        })
        this.alert.present()
        this.loading = false
      }))
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  private createForm (): void {
    this.searchActivityForm = this.fb.group({
      url: ['', []],
      method: [null, []],
      urlRegex: ['', []]
    })
  }

  public showModal (el: Activity): void {

    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_ACTIVITY'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_ACTIVITY')} ${el.url}?`,
      buttons: [
        {
          title: this._translate.instant('BUTTON.DELETE')
        }
      ],
      data: el._id
    })

    this.modal.confirm()
  }

  delete (_id: string): void {
    this.subscriptions.push(this._activity.deleteActivity(_id).subscribe(
      (success) => {
        if (!success.isValid) {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${success.message}`
          })
          this.alert.present()
          return
        }

        this.dataSource.data = this.dataSource.data
          .filter((activity) => activity._id !== _id)

        this.alert = new Alert({
          type: AlertType.SUCCESS,
          message: this._translate.instant('ALERT.MESSAGE.ACTIVITY_DELETED')
        })
        this.alert.present()
      }, error => {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }))
  }
}
