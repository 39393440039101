import { CurrencySymbol } from '../enums/currency-symbol.enum'

export class Amount {
  value: number
  currency: string
  decimalValue: number

  constructor (obj: any = {}) {
    if (obj.value !== undefined) {
      this.value = obj.value
    }

    if (obj.currency) {
      this.currency = obj.currency
    }

    if (obj.decimalValue !== undefined) {
      this.decimalValue = obj.decimalValue
    }
  }

  equals (b: Amount): boolean {
    return b.currency === this.currency && b.value === this.value
  }

  toString (): string {
    return `${this.decimalValue.toFixed(2)} ${CurrencySymbol[this.currency]}`
  }
}
