import { Component, Input, OnChanges } from '@angular/core'
import { Permission } from 'src/app/classes/permission.class'
import { MenuItem, menuItems } from './MenuItems'
import { User } from 'src/app/classes/user.class'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnChanges {
  @Input() user: User
  public validatedMenu: MenuItem[] = menuItems

  ngOnChanges () {
    this.buildValidatedMenu()
  }

  buildValidatedMenu () {
    if (!this.user ||
      !this.user.authn ||
      !this.user.authn.roles
      ) {
      return []
    }

    // take only the active and app-admin's permissions of all the roles
    const adminPermissions = this.user.authn.roles.reduce(
      (acc, role) => {
        return [
          ...acc,
          ...role.permissions
          .filter((p: Permission) => p.active && p.app === `app-admin`)
        ]
      },
    [])

    this.validatedMenu = menuItems.reduce((acc, menuItem) => {
      // check children menu items if they are valid
      if (menuItem.children && menuItem.children.length) {
        const validChildren = menuItem.children.filter(child =>
          this.validateMenuItem(adminPermissions, child))
        if (validChildren.length) {
          return [ ...acc, { ...menuItem, children: validChildren }]
        }
      }

      // check the menuItem itself since it is designed
      // to have a route nd no children
      if (menuItem.route && this.validateMenuItem(adminPermissions, menuItem)) {
        return [ ...acc, menuItem ]
      }
      return acc
    }, [])
  }

  validateMenuItem (permissions: Permission[], menuItem: MenuItem) {
    if (menuItem.label.startsWith(`FUNC.SITE`)) {
      return true
    }
    // find this item in the list pf permissions
    return permissions.some(p =>
      p.label.toUpperCase() === menuItem.label.toUpperCase().replace(`FUNC.`, ``))
  }
}
