import { Coords } from './coords.class'

export class Station {
  _id: string
  name: string
  coords: Coords
  formattedAddress: string
  address: string
  city: string
  postalCode: string
  state: string
  country; string
  active: boolean
  publish: boolean
  chargingWhenClosed: boolean
  openingTimes: OpeningTimes
  creationDate: Date
  updateDate: Date
  facilities: Facility[] = []

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'name', 'formattedAddress', 'address', 'city', 'postalCode',
      'state', 'country', 'active', 'publish', 'chargingWhenClosed', 'openingTimes', 'facilities']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['coords']) {
      this.coords = new Coords(obj['coords'])
    }

    if (obj['creationDate']) {
      this.creationDate = new Date(obj['creationDate'])
    }

    if (obj['updateDate']) {
      this.updateDate = new Date(obj['updateDate'])
    }
  }
}

interface OpeningTimes {
  twentyfourseven: boolean
  regularHours?: Hours[]
}

interface Hours {
  weekday: number
  periodBegin: string
  periodEnd: string
}

interface Facility {
  type: string
  label?: string
  description?: string
}
