import { Transaction } from './transaction.class'
import { ValueChangeValues, AvgMinMaxValues, PlainValue } from '../components/kpi-card/kpi-card-types'
import { TranslateService } from '@ngx-translate/core'

export class ChargingProcessDetailData {
  chartPowerData: any
  stateOfChargeChangeValues: ValueChangeValues
  powerValues: AvgMinMaxValues
  energyValues: PlainValue

  constructor (
    public transaction: Transaction,
    public _translate: TranslateService
  ) {
    this.transaction.data = this.transaction.data.reverse()

    const powerOverTime = transaction.data
      .map((data: any) => ({
        name: data.chargeTime !== undefined
          ? data.chargeTime
          : this.chargeTime(data.date),
        value: data.currentPower
      }))
      .filter(({ value }) => value > 0)

    this.chartPowerData = [{
      name: this._translate.instant('LABEL.POWER'),
      series: powerOverTime
    }]

    if (
      transaction.initialSoC !== undefined &&
      transaction.soc !== undefined
    ) {
      this.stateOfChargeChangeValues =
        new ValueChangeValues(transaction.initialSoC, transaction.soc)
    }

    this.powerValues = powerOverTime.reduce((previous, current, index) => {
      const avg = ((previous.avg * index) + current.value) / (index + 1)
      const min = Math.min(previous.min, current.value)
      const max = Math.max(previous.max, current.value)

      return new AvgMinMaxValues(avg, min, max)
    }, new AvgMinMaxValues(0, Number.MAX_SAFE_INTEGER, 0))

    this.energyValues = new PlainValue(transaction.energy)
  }

  chargeTime = (now: Date) =>
    Math.round((((
      now.getTime() - this.transaction.startDate.getTime()
    ) % 86400000) % 3600000) / 60000)
}
