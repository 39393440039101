import { Component, ViewChild } from '@angular/core'
import { FormGroup, Validators, FormControl, FormArray, AbstractControl, ValidationErrors } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { UtilityService } from '../../../services/utility.service'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { Coords } from 'src/app/classes/coords.class'
import { Country } from 'src/app/enums/country.enum'
import { Weekday } from 'src/app/enums/weekday.enum'
import { FacilityType } from 'src/app/enums/facility-type.enum'
import { CustomerService } from 'src/app/services/customer.service'
import { Customer } from 'src/app/classes/customer.class'
import { tzlib_get_timezones } from 'timezones-ical-library'
import { SiteService } from 'src/app/services/site.service'
import { MatDialog } from '@angular/material'
import { CreateZoneComponent } from './create-zone/create-zone.component'
import { EchargerService } from 'src/app/services/echarger.service'
import { Echarger } from 'src/app/classes/echarger.class'
import { PrivilegeService } from 'src/app/services/privilege.service'
import { Privilege } from 'src/app/classes/privilege.class'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-create-site',
  templateUrl: './create-site.component.html',
  styleUrls: ['./create-site.component.scss']
})

export class CreateSiteComponent
  extends FormCanDeactivate {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public echargers: Echarger[] = []

  public customers: Customer[] = []

  public privileges: Privilege[] = []

  public timezones: string[] = []

  private periodRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/

  public countries = Object.keys(Country).filter((v) => isNaN(Number(v)))

  public weekdays = Object.keys(Weekday).filter((v) => isNaN(Number(v)))

  public facilityType = Object.keys(FacilityType)
    .filter((v) => isNaN(Number(v)))

  public originalCoords: Coords

  public createSiteForm = new FormGroup({
    _id: new FormControl(undefined),
    customer: new FormControl(null, Validators.required),
    name: new FormControl('', Validators.required),
    coords: new FormGroup({
      lat: new FormControl('', Validators.required),
      lng: new FormControl('', Validators.required)
    }),
    address: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    postalCode: new FormControl(),
    state: new FormControl(),
    country: new FormControl(undefined, Validators.required),
    active: new FormControl(true, Validators.required),
    publish: new FormControl(true, Validators.required),
    parkingType: new FormControl(null),
    timeZone: new FormControl(null, Validators.required),
    openingTimes: new FormGroup({
      twentyfourseven: new FormControl(true, Validators.required),
      regularHours: new FormArray(
        this.weekdays.map((e, i) => new FormGroup({
          weekday: new FormControl(i + 1, Validators.required),
          open: new FormControl(false),
          periodBegin: new FormControl(
            '00:00',
            [Validators.required, Validators.pattern(this.periodRegex)]),
          periodEnd: new FormControl(
            '00:00',
            [Validators.required, Validators.pattern(this.periodRegex)])
        }, this.validPeriod))
      )
    }, this.openingTimesValidator),
    chargingWhenClosed: new FormControl(false),
    facilities: new FormArray([]),
    zones: new FormControl([])
  })
  public loading = false
  public updateMode = false

  public alert: Alert

  constructor (
    private _site: SiteService,
    private _customer: CustomerService,
    private _translate: TranslateService,
    public _utility: UtilityService,
    private route: ActivatedRoute,
    private _dialog: MatDialog,
    private _privilege: PrivilegeService,
    private _echarger: EchargerService
  ) {
    super()
    this.init()
  }

  get facilitiesForm (): FormArray {
    return this.createSiteForm.get('facilities') as FormArray
  }

  addFacility () {
    this.facilitiesForm.push(new FormGroup({
      type: new FormControl(null, Validators.required),
      label: new FormControl(),
      description: new FormControl()
    }))
  }

  removeFacility (i: number) {
    this.facilitiesForm.removeAt(i)
  }

  openingTimesValidator (control: AbstractControl): ValidationErrors | null {
    const { twentyfourseven, regularHours } = control.value
    if (twentyfourseven) {
      return null
    }
    if (regularHours.filter(({ open }) => open).length) {
      return null
    }
    return { regularHours: true }
  }

  validPeriod (control: AbstractControl): ValidationErrors | null {
    const { open, periodBegin, periodEnd } = control.value
    if (!open) {
      return null
    }
    const beginTime = new Date(`2000-01-01T${periodBegin}:00.000Z`).getTime()
    const endTime = new Date(`2000-01-01T${periodEnd}:00.000Z`).getTime()
    if (beginTime < endTime) {
      return null
    }
    return { validPeriod: true }
  }

  async init () {
    this.loading = true
    this.timezones = (tzlib_get_timezones() as string[])
      .filter(t => t.startsWith(`Europe`) || t.startsWith(`Asia`))
      .map(t => t.replace('_', ' '))
      .sort()
    await this.loadCustomers()
    await this.loadEchargers()
    await this.loadPrivileges()
    await this.setUpdatePage()
    this.createSiteForm.get('openingTimes.twentyfourseven').valueChanges
      .subscribe((v) => v
        ? this.createSiteForm.get('openingTimes.regularHours').disable()
        : this.createSiteForm.get('openingTimes.regularHours').enable())
  }

  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe(url => {
      if (!url[0].path.includes('update')) {
        this.loading = false
        this.originalCoords = new Coords({})
        return
      }
      this.updateMode = true

      this.route.params.subscribe(async ({ _id }) => {
        await this.patchValueIntoForm(_id)
      })
    })
  }

  public async saveData (): Promise<void> {
    this.loading = true
    try {
      // important to stringify and parse to make a deep copy of the object.
      const site = this.removeEmpty(
        JSON.parse(JSON.stringify(this.createSiteForm.value)))

      if (site.openingTimes.twentyfourseven) {
        delete site.openingTimes.regularHours
      } else {
        site.openingTimes.regularHours = site.openingTimes.regularHours
          .filter(({ open }) => open)
          .map(d => ({ ...d, open: undefined }))
      }
      const response = (this.updateMode)
        ? await this._site.updateSite(site).toPromise()
        : await this._site.createSite(site).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.STATION_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })
      if (!this.updateMode) {
        this.createSiteForm.reset()
      }
      this.createSiteForm.markAsPristine()
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    } finally {
      this.loading = false
    }
  }

  async patchValueIntoForm (_id: string) {
    try {
      const response = await this._site.getSite(_id).toPromise()
      if (response.invalid) {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      const patch = {
        ...response.data,
        customer: response.data.customer._id,
        zones: (response.data.zones || []).map(z => ({
          ...z,
          echargers: (z.echargers || []).map(({ _id }) => _id)
        }))
      }
      if (patch.facilities && patch.facilities.length) {
        new Array(patch.facilities.length)
          .fill('').forEach(() => this.addFacility())
      }
      this.originalCoords = patch.coords
      patch.openingTimes.regularHours = this.createSiteForm
        .get('openingTimes.regularHours').value
        .map(day => (patch.openingTimes.regularHours || [])
            .find(d => d.weekday === day.weekday) || day)
        .map(d => ({ ...d, open: d.periodBegin !== d.periodEnd }))
      this.createSiteForm.patchValue(patch)
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    } finally {
      this.loading = false
    }
  }

  resetForm () {
    this.loading = true
    const { _id } = this.createSiteForm.value
    while (this.facilitiesForm && this.facilitiesForm.length) {
      this.facilitiesForm.removeAt(0)
    }
    this.createSiteForm.reset()
    this.createSiteForm.get('openingTimes').patchValue({
      twentyfourseven: true,
      regularHours: this.weekdays.map((_, i) =>
        ({ weekday: i + 1, periodBegin: '00:00', periodEnd: '00:00' }))
    }
    )
    if (this.updateMode) {
      return this.patchValueIntoForm(_id)
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  public updateZone (i?: number) {
    try {
      const dialogRef = this._dialog.open(CreateZoneComponent, {
        panelClass: 'maxh-100-vh',
        data: {
          availableEchargers: this.echargers,
          availablePrivileges: this.privileges,
          siteCoords: this.createSiteForm.value.coords,
          zone: i !== undefined && this.createSiteForm.value.zones[i]
        }
      })

      dialogRef.afterClosed().pipe(filter(r => !!r)).subscribe(r => {
        this.createSiteForm.patchValue({
          zones: i !== undefined
            ? this.createSiteForm.value.zones
              .map((z, index) => i === index ? r : z)
            : [...this.createSiteForm.value.zones, r]
        })
      })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant(`ALERT.MESSAGE.SYSTEM_ERROR`)} ${error.statusText}`
      })
    }
  }

  public removeZone (i: number) {
    this.createSiteForm.patchValue({
      zones: this.createSiteForm.value
        .zones.filter((e, index) => index !== i)
    })
  }

  // here necessary and can not be used from service since addressValidation
  // also needs to be set
  public markFormGroupTouched (formGroup: FormGroup | FormArray): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createSiteForm]
  }

  removeEmpty (obj): any {
    return Object.keys(obj)
      .filter((k) => obj[k] !== null && obj[k] !== undefined)
      .reduce((acc, k) => {
        if (typeof obj[k] !== `object`) {
          acc[k] = obj[k]
        } else {
          if (Array.isArray(obj[k])) {
            acc[k] = obj[k]
            .filter(e => e)
            .map(e => {
              return typeof e !== `object`
               ? e
               : this.removeEmpty(e)
            })
          } else {
            acc[k] = this.removeEmpty(obj[k])
          }
        }
        return acc
      }, {})
  }

  private async loadCustomers (page = 0) {
    const res = await this._customer.searchCustomer({ page }).toPromise()
    if (res.invalid) {
      throw new Error(res.message)
    }
    this.customers = page
      ? [...this.customers, ...res.data.data]
      : res.data.data
    if (res.data.hasNext) {
      this.loadCustomers(page + 1)
    }
  }

  private async loadEchargers (page = 0) {
    try {
      const response = await this._echarger
        .searchEcharger({ page }).toPromise()
      if (response.valid) {
        this.echargers = page
          ? [...this.echargers, ...response.data.data]
          : response.data.data
        this.echargers.sort((e1, e2) => e1.name.localeCompare(e2.name))

        if (response.data.hasNext) {
          await this.loadEchargers(page + 1)
        }
      } else {
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private async loadPrivileges (page = 0): Promise<void> {
    try {
      const response = await this._privilege
        .searchPrivilege({ page, sort: { name: 'asc' } })
        .toPromise()

      if (response.valid) {
        this.privileges = page
          ? this.privileges.concat(response.data.data)
          : response.data.data
      } else {
        this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      if (response.data.hasNext) {
        this.loadPrivileges(page + 1)
      }
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }
}
