import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Router } from '@angular/router'
import { AuthService } from '../services/auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (
    private router: Router,
    private _auth: AuthService
    ) { }

  async canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const currentUser = this._auth.user$.value

    if (currentUser) {
      // user present so can continue
      return true
    }

    const token = this._auth.token$.value

    if (!token) {
      // no token present, so can't continue
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
      return false
    }

    // since token is present, but user is not, retreive the user
    const user = await this._auth.getProfile().toPromise()
    // the user was logged in and is accessing app-admin through a link
    if (user && user.message === 'OK') {
      return true
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
    return false
  }
}
