import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Echarger } from 'src/app/classes/echarger.class'
import { Response } from 'src/app/classes/response.class'
import { map, switchMap, retry } from 'rxjs/operators'
import { Observable, timer } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { DataPage } from '../classes/data-page.class'

@Injectable({
  providedIn: 'root'
})
export class EchargerService {
  private baseUrl =
    `${environment.baseUrl}${environment.locationContext.echarger}`

  private chargeUrl = `${environment.baseUrl}${environment.locationContext.charge}`

  constructor (
    private http: HttpClient
  ) { }

  public createCharge (echarger: any): Observable<Response<Echarger>> {
    return this.http
      .post<Response<Echarger>>(this.baseUrl, {
        ...echarger,
        vendor: echarger.vendor._id
      })
      .pipe(map(({ message, data }) =>
        new Response<Echarger>(message, data && new Echarger(data))
      ))
  }

  public searchEcharger (form): Observable<Response<DataPage<Echarger>>> {
    return this.http
      .post<Response<DataPage<Echarger>>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<DataPage<Echarger>>(
          message,
          data && new DataPage(
            { ...data, data: data.data.map(c => new Echarger(c)) }
          ))
      ))
  }

  public updateEcharger (echarger: any): Observable<Response<Echarger>> {
    return this.http
      .patch<Response<Echarger>>(`${this.baseUrl}/${echarger._id}`, { ...echarger, vendor: echarger.vendor._id })
      .pipe(map(({ data, message }) =>
        new Response<Echarger>(message, data && new Echarger(data))
      ))
  }

  public getEcharger (_id: string): Observable<Response<Echarger>> {
    return this.http
      .get<Response<Echarger>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Echarger>(message, new Echarger(data))
      ))
  }

  public deleteEcharger (_id: string): Observable<Response<Echarger>> {
    return this.http
      .delete<Response<Echarger>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Echarger>(message, new Echarger(data))
      ))
  }

  public sendCommand (form): Observable<Response<string>> {
    return this.http
      .post<Response<string>>(`${this.chargeUrl}/sendCommand`, form)
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcRequestRegister (
    openSocketNr: number,
    protocolVersion: string
  ): Observable<Response<string>> {
    return this.http
      .post<Response<string>>(
        `${this.baseUrl}/virtual/command/${openSocketNr}`,
        { cmdId: 44, protocol: protocolVersion }
      )
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcRequestPrecharge (openSocketNr: number, form)
    : Observable<Response<string>> {
    return this.http
      .post<Response<string>>(
        `${this.baseUrl}/virtual/command/${openSocketNr}`,
        { cmdId: 42, ...form }
      )
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcResponseControl (openSocketNr: number, form)
    : Observable<Response<string>> {
    return this.http
      .post<Response<string>>(
        `${this.baseUrl}/virtual/command/${openSocketNr}`,
        { cmdId: 45, ...form }
      )
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcResponsePlugStatus (openSocketNr: number, form)
    : Observable<Response<string>> {
    return this.http
      .post<Response<string>>(
        `${this.baseUrl}/virtual/command/${openSocketNr}`,
        { cmdId: 43, ...form }
      )
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcResponseChargeInfo (openSocketNr: number, form)
    : Observable<Response<string>> {
    return this.http
      .post<Response<string>>(
        `${this.baseUrl}/virtual/command/${openSocketNr}`,
        { cmdId: 41, ...form }
      )
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcConnect (
    selectedzoneId: string,
    echargerCode: string,
    prechargeLimit: number
  ): Observable<Response<{ echarger: string, socket: number }>> {
    return this.http
      .post<Response<{ echarger: string, socket: number }>>(
        `${this.baseUrl}/virtual/connect`,
        { zoneId: selectedzoneId, echargerCode, prechargeLimit }
      )
      .pipe(map(({ message, data }) =>
        new Response<{ echarger: string, socket: number }>(message, data)
      ))
  }

  public virtualEcDisconnect (openSocketNr: number)
    : Observable<Response<string>> {
    return this.http
      .get<Response<string>>(`${this.baseUrl}/virtual/disconnect/${openSocketNr}`)
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcGetContinuosLogs (
    openSocketNr: number,
    filter: string,
    tail: number
  ): Observable<Response<string>> {
    return timer(0, 2000).pipe(
      switchMap(() =>
        this.http.post<Response<string>>(
          `${this.baseUrl}/virtual/logs/${openSocketNr}`,
          { filter, tail }
        ),
      ),
      retry(3),
      map(({ message, data }) =>
        new Response<string>(message, data)
      )
    )
  }
}
