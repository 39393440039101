import { Injectable } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  static expireDate = new Date(
    new Date(2999, 11, 31).getTime() -
    new Date(2999, 11, 31).getTimezoneOffset() * 60 * 1000
  )

  // to show all unfullfilled validators on submit
  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  // to scroll to the top of the page after submit
  public scrollToTop () {
    document.querySelector('#page').scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  public isEmptyObject (obj): boolean {
    for (const key in obj) {
      if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
        return false
      }
    }

    return true
  }

  public toTranslationString (prefix: string, input: string): string {
    return `${prefix}.${input.toUpperCase().replace(/ /g, '_')}`
  }
}
