import { Component, OnDestroy, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { EchargerService } from 'src/app/services/echarger.service'
import { UtilityService } from '../../../services/utility.service'
import { UserService } from 'src/app/services/user.service'
import { ActivatedRoute } from '@angular/router'
import { Echarger } from 'src/app/classes/echarger.class'
import { MeterValue, Transaction } from 'src/app/classes/transaction.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { MessageEloaded, MessageOCPP } from 'src/app/enums/echarger-message.enum'
import { CommandEloaded, CommandOCPP, MessageTriggerOCPP } from 'src/app/enums/echarger-command.enum'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { TransactionService } from 'src/app/services/transaction.service'
import { DateFormat } from 'src/app/enums/date-format.enum'
import { environment } from 'src/environments/environment'
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'app-echarger-detail',
  templateUrl: './echarger-detail.component.html',
  styleUrls: ['./echarger-detail.component.scss']
})
export class EchargerDetailComponent implements OnDestroy {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  private subscriptions: Subscription[] = []
  public loading = true
  public alert: Alert
  public echarger: Echarger
  public transactions: Transaction[]
  public selectedCommand: string = null
  // For the current buttons under echarger Information
  public selectedGun = 1
  public selectedMessage: string = null
  public transactionIds: String[] = []
  public knownUsers: { id: string, email: string }[] = []
  public firstTransactions: string[] = []
  public transactionIndex = 0
  public interval
  public connection
  public commands
  public messages

  // making the enums visible to template
  public Command
  public MessageTrigger
  public Message

  public DateFormat = DateFormat

  public echargerForm = new FormGroup({
    notes: new FormControl()
  })

  // Url for ChargingWeb
  public chargingWebUrl = `${environment.chargingWeb.baseUrl}${environment.chargingWeb.charging}`

  constructor (
    private _translate: TranslateService,
    private _echarger: EchargerService,
    private _transaction: TransactionService,
    public _utility: UtilityService,
    public _user: UserService,
    private route: ActivatedRoute
  ) {
    this.setUpdatePage()
  }

  ngOnDestroy () {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    clearInterval(this.interval)
  }

  private async setUpdatePage (): Promise<void> {
    this.route.params.subscribe(async ({ _id }) => {
      const responseEcharger = await this._echarger.getEcharger(_id).toPromise()

      if (responseEcharger.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')} ` +
          `${this._translate.instant(responseEcharger.message)}`
        )
      }

      this.echarger = responseEcharger.data
      this.echargerForm.patchValue({ notes: this.echarger.notes })

      await this.patchValueIntoForm()

      if (this.echarger.protocol.name === 'ELOADED') {
        this.Command = CommandEloaded
        this.Message = MessageEloaded
      } else if (this.echarger.protocol.name === 'OCPP') {
        this.Command = CommandOCPP
        this.Message = MessageOCPP
        this.MessageTrigger = MessageTriggerOCPP
        this.messages = Object.keys(this.MessageTrigger)
      }

      this.commands = Object.keys(this.Command)

      this.interval = setInterval(() => {
        this.patchValueIntoForm()
      }, 2000)
    })
  }

  public async updateNotes () {
    try {
      const { notes } = this.echargerForm.value
      const { _id } = this.echarger

      const echargerResponse = await this._echarger
        .getEcharger(_id).toPromise()

      if (!echargerResponse.data) {
        throw new Error(echargerResponse.message)
      }

      const echarger = new Echarger({
        ...echargerResponse.data,
        notes,
        plugs: echargerResponse.data.plugs
          .map(p => ({ ...p, number: p.number ? 1 : 0 }))
      })

      await this._echarger
        .updateEcharger({ ...echarger, protocol: echarger.protocol.name })
        .toPromise()

      this.echargerForm.get('notes').markAsPristine()

      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(`ALERT.MESSAGE.ECHARGER_UPDATED`)
      })

      return this.alert.present()
    } catch (error) {
      this.loading = false
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public transactionsFound (gun?: number): boolean {
    return gun !== undefined
      ? !!(this.transactions && this.transactions
        .filter((transaction) => transaction.gun === gun).length)
      : !!(this.transactions && this.transactions
        .filter((transaction) => transaction).length)
  }

  public async sendCommand (gun: number): Promise<void> {
    try {
      if (!this.selectedCommand) {
        return
      }

      this.loading = true

      const command = this.selectedCommand

      const form: any = { echarger: this.echarger._id, command, gun }

      if (
        this.selectedCommand === this.Command.TRIGGER_MESSAGE &&
        this.selectedMessage
      ) {
        form.requestedMessage = this.selectedMessage
      }

      const response = await this._echarger.sendCommand(form).toPromise()

      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        if (this.transactions) {
          const transaction = this.transactions.find((t) => t.gun === gun)

          if (transaction) {
            transaction.status = 'Error'
          }
        }
        this.alert.present()
        this.loading = false
        console.log('ERROR', response.message)
        return
      }

      console.log(response.data)

      this.loading = false

    } catch (error) {
      this.loading = false
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async startCharge (gun: number): Promise<void> {
    try {
      this.loading = true
      const response = await this._transaction
        .startCharge({ echarger: this.echarger._id, gun }).toPromise()

      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        this.loading = false
        console.log('ERROR', response.message)
        return
      }
      console.log(response.data)
      this.transactionIds[gun] = response.data.toString()
    } catch (error) {
      console.log(error)
      this.loading = false
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async stopCharge (gun: number): Promise<void> {
    try {
      this.loading = true

      if (!this.transactionIds[gun]) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} NO_CHARGE_TO_STOP`
        })
        this.alert.present()
        this.loading = false
        console.log('ERROR', 'NO_CHARGE_TO_STOP')
        return
      }

      const response = await this._transaction
        .stopCharge(this.transactionIds[gun]).toPromise()

      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        this.loading = false
        console.log('ERROR', response.message)
        return
      }
      console.log('CHARGE_STOPPED')
      this.loading = false
    } catch (error) {
      console.log(error)
      this.loading = false
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async patchValueIntoForm (): Promise<void> {
    this.loading = false

    const responseConnection = await this._transaction
      .getEchargersConnection(this.echarger._id).toPromise()

    if (responseConnection.invalid) {
      if (responseConnection.message === 'ERROR.NO_CONNECTION_FOUND') {
        responseConnection.data.transactions = []
      } else {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')} ` +
          `${this._translate.instant(responseConnection.message)}`
        )
      }
    }

    this.connection = responseConnection.data

    // if one tab has been manually closed, a ! is put before the id and the id
    // needs to be kept so it doesnt reopen
    const closedIndex = this.firstTransactions.findIndex(f => f.startsWith('!'))

    if (
      closedIndex !== -1 &&
      this.transactions.findIndex(t =>
        t._id === this.firstTransactions[closedIndex].substring(1)
      ) !== -1
    ) {
      for (let i = 0; i < 8; i++) {
        this.firstTransactions[i] = i === closedIndex
          ? this.firstTransactions[i]
          : ''
      }
    } else {
      this.firstTransactions = ['', '', '', '', '', '', '', '', '']
    }

    const finishedTransactions: Transaction[] = []
    const data = this.connection.transactions

    data.forEach((trans) => {
      // Add Charging transaction ID's if page is reloaded/ transaction was
      // already there
      if (
        !trans.status.includes('COMMAND') &&
        (
          !this.transactionIds ||
          this.transactionIds.findIndex(id => id === trans._id.toString()) < 0
        )
      ) {
        console.log('MANUALLY ADDED CHARGE ID')
        this.transactionIds[trans.gun] = trans._id.toString()
      }
    })

    if (this.transactions) {
      this.transactions.forEach((transaction) => {
        if (!data.find(({ _id }) =>
          _id.toString() === transaction._id.toString())
        ) {
          finishedTransactions.push(transaction)
        }
      })
    }

    data.forEach(async (transaction) => {
      if (!transaction.user) {
        return
      }

      const index = this.knownUsers
        .findIndex((k) => k.id.toString() === transaction.user.toString())
      // get User Information from ID
      if (index < 0) {
        const response = await this._user.getUser(transaction.user).toPromise()
        if (response.invalid) {
          this.knownUsers.push({
            id: transaction.user,
            email: transaction.user
          })
          // non vital error, resuming possible -> no return
        } else {
          this.knownUsers.push({
            id: transaction.user,
            email: response.data.authn.email
          })
          transaction.user = response.data.authn.email
        }
      } else {
        transaction.user = this.knownUsers[index].email
      }
    })

    await finishedTransactions.forEach(async (transaction) => {
      // transaction finished -> ChargeDetail for final data
      if (transaction.active) {
        const response = await this._transaction
          .chargeDetail(transaction._id).toPromise()

        console.log('CHARGEDETAIL REQUESTED')

        if (response.invalid) {
          console.log(response.message)

          transaction.status = 'ERROR'

          return this.openErrorAlert(
            `${this._translate.instant('ALERT.MESSAGE.INVALID')} ` +
            `${this._translate.instant(response.message)}`
          )
        }

        response.data.echarger = this.echarger
        const d: Transaction = new Transaction(response.data)
        d.user = transaction.user
        d.active = false
        transaction = d
        this.firstTransactions[d.gun - 1] = d._id
        data.unshift(transaction)
      } else {
        data.push(transaction)
      }
    })

    data.sort((a, b) =>
      (a.gun === b.gun && a.data.length > 0 && b.data.length > 0)
        ? b.startDate.getTime() - a.startDate.getTime()
        : a.gun - b.gun
    )

    data.forEach((t) => {
      if (this.firstTransactions[t.gun - 1] === '') {
        this.firstTransactions[t.gun - 1] = t._id
      }
    })
    this.transactions = data
  }

  identify (index, item) {
    return item._id
  }

  public calculateKW (current: number, voltage: number): number {
    return Math.round(current * voltage / 100) / 10
  }

  private openErrorAlert (message: string): void {
    this.loading = false
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  private openWarningAlert (message: string): void {
    this.loading = false
    this.alert = new Alert({ type: AlertType.WARNING, message })
    return this.alert.present()
  }

  public notAutoOpen (index: number, id: string, closed: boolean) {
    console.log(closed)
    const tr = this.firstTransactions[index]
    if (tr === id || tr.substring(1) === id) {
      if (closed) {
        this.firstTransactions[index] = '!' + id
      } else {
        // remove ! if tab is openend again, doesnt need an extra icon since
        // only one can be active
        if (tr.startsWith('!')) {
          this.firstTransactions[index] = tr.substring(1)
        }
      }
    }
  }

  public async wipePendingTransactions (): Promise<void> {
    const response = await this._transaction
      .wipePendingTransactions(this.echarger._id).toPromise()

    if (response.invalid) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`)
    } else {
      this.openWarningAlert(
        this._translate.instant('ALERT.MESSAGE.WIPED_PENDING_TRANSACTIONS')
      )
    }
  }

  getFromSampledValues (mv: MeterValue, measurand: string) {
    return mv.sampledValue.find(el => el.measurand === measurand)
  }

  sampledDataToObject (mv: MeterValue) {
    return mv.sampledValue.reduce((acc, el) => {
      acc[`${el.measurand}`] = el
      return acc
    }, {})
  }

  public copyPublicKey (index: number): void {
    const cb = 'clipboard'
    const clipboard = window.navigator[cb]

    clipboard.writeText(this.echarger.plugs[index].publicKey)
  }

  public copyMessage (message: string): void {
    const cb = 'clipboard'
    const clipboard = window.navigator[cb]

    clipboard.writeText(message)
  }

  public getInstalledGuns (plugs) {
    return plugs
      .map((p, i) => ({ ...p, physicalNumber: i + 1 }))
      .filter(p => p.number)
  }

  public noAlarms (alarms: any[]) {
    return alarms.every(a => a === 'No alarm')
  }

  public existPublicKeys () {
    return this.echarger.plugs.some(({ publicKey }) => !!publicKey)
  }
}
