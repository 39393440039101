import { Pipe, PipeTransform } from '@angular/core'

/** Basic pipe to convert B in MB
 * Output: MB
 */

@Pipe({
  name: 'bytesToMegabytes'
})
export class BytesToMegabytesPipe implements PipeTransform {
  transform (bytes: number): string {
    return `${Math.round(bytes / Math.pow(2, 20))}`
  }
}
