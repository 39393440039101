import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { UtilityService } from 'src/app/services/utility.service'

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryCardComponent implements OnInit {
  @Input() public name: string
  @Input() public value: string

  @ViewChild(TemplateRef) template

  constructor (
    private _utility: UtilityService
  ) { }

  ngOnInit () {
    this.name = this._utility.toTranslationString('LABEL', this.name)
  }
}
