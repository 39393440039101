import { StationPlug } from '../interfaces/station-plug'
import { Vendor } from './vendor.class'
import { EchargerProtocol } from '../enums/echarger-protocol.enum'

export class Protocol {
  name: EchargerProtocol
  version: string

  constructor (obj: any) {
    const properties = ['name', 'version']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }
  }
}

export class Echarger {
  _id: string
  name: string
  plugs: Array<StationPlug>
  code: string
  ipv4Address: string
  notes: string
  protocol: Protocol
  vendor: Vendor
  legal: string
  creationDate: Date
  active: boolean
  updateDate: Date
  prechargeLimit: number

  constructor (obj: any) {
    if (typeof obj === 'string') {
      this._id = obj
      return
    }

    const properties = ['_id', 'name', 'plugs',
      'code', 'ipv4Address', 'legal', 'active', 'notes', 'prechargeLimit']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.protocol) {
      this.protocol = new Protocol(obj.protocol)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }

    if (obj.vendor) {
      this.vendor = obj.vendor instanceof Vendor
        ? obj.vendor
        : new Vendor(obj.vendor)
    }
  }
}
