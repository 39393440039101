import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormGroup, Validators, ValidationErrors, AbstractControl, FormControl } from '@angular/forms'
import { Alert } from 'src/app/classes/alert.class'
import { ModalComponent } from '../../../components/modal/modal.component'
import { Device } from 'src/app/classes/device.class'
import { Role } from 'src/app/classes/role.class'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CustomerService } from 'src/app/services/customer.service'
import { PosService } from 'src/app/services/pos.service'
import { SiteService } from 'src/app/services/site.service'

@Component({
  selector: 'app-create-device',
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.scss']
})
export class CreateDeviceComponent implements OnInit {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public loading = true
  public alert: Alert
  public preview: string
  public plans: Role[]
  public customers: any
  public sites: any
  public pos: any

  // tslint:disable-next-line: member-ordering
  public createDeviceForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    name: new FormControl('', [Validators.required]),
    password: new FormControl(
      '',
      [Validators.required, Validators.minLength(5)]
    ),
    passwordConfirm: new FormControl(
      '',
      [Validators.required, this.matchPassword('password')]
    ),
    customer: new FormControl(undefined),
    site: new FormControl(undefined),
    pos: new FormControl(undefined),
    active: new FormControl(true)
  })

  constructor (
    public dialogRef: MatDialogRef<CreateDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _customer: CustomerService,
    private _site: SiteService,
    private _pos: PosService
  ) { }

  async ngOnInit () {
    await this.loadCustomers()
    await this.loadSites()

    const pos = await this._pos.getAll().toPromise()
    this.pos = pos.data

    this.setUpdatePage()

    this.loading = false
  }

  async setUpdatePage (): Promise<void> {
    if (this.data) {
      const device: Device = new Device(this.data)
      this.createDeviceForm.get('name').disable()
      this.createDeviceForm.removeControl('password')
      this.createDeviceForm.removeControl('passwordConfirm')
      this.createDeviceForm.patchValue({
        ...device,
        customer: this.data.customer && this.data.customer._id,
        pos: this.data.pos && this.data.pos.id,
        site: this.data.site && this.data.site._id
      })
    }
  }

  get updateMode (): boolean {
    return !!this.data
  }

  private matchPassword (
    passwordControlName: string
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null =>
      (
        !control.value ||
        !control.parent.get(passwordControlName).value ||
        control.parent.get(passwordControlName).value === control.value
      )
        ? null
        : { matchPassword: true }
  }

  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public submit () {
    if (this.createDeviceForm.invalid) {
      return this.markFormGroupTouched(this.createDeviceForm)
    }

    this.loading = true
    this.dialogRef.close(this.createDeviceForm.value)
  }

  public cancel () {
    this.dialogRef.close()
  }

  private async loadCustomers (page = 0) {
    const res = await this._customer.searchCustomer({ page }).toPromise()
    if (res.invalid) {
      throw new Error(res.message)
    }
    this.customers = page
      ? [...this.customers, ...res.data.data]
      : res.data.data
    if (res.data.hasNext) {
      this.loadCustomers(page + 1)
    }
  }

  private async loadSites (page = 0) {
    const res = await this._site.searchSite({ page }).toPromise()
    if (res.invalid) {
      throw new Error(res.message)
    }
    this.sites = page
      ? [...this.sites, ...res.data.data]
      : res.data.data
    if (res.data.hasNext) {
      this.loadSites(page + 1)
    }
  }
}
