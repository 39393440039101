export enum CommandEloaded {
  GUN_STAT = 'GUN_STAT',
  CHARGE_INFO = 'CHARGE_INFO',
  CHARGE_START = 'CHARGE_START',
  CHARGE_STOP = 'CHARGE_STOP',
  CHARGE_ALLOW = 'CHARGE_ALLOW',
  CHARGE_DENY = 'CHARGE_DENY'
}

export enum CommandOCPP {
  REMOTE_START_TRANSACTION = 'RemoteStartTransaction',
  REMOTE_STOP_TRANSACTION = 'RemoteStopTransaction',
  RESET = 'Reset',
  TRIGGER_MESSAGE = 'TriggerMessage',
  UNLOCK_CONNECTOR = 'UnlockConnector'
}

export enum MessageTriggerOCPP {
  BOOT_NOTIFICATION = 'BootNotification',
  HEARTBEAT = 'Heartbeat',
  STATUS_NOTIFICATION = 'StatusNotification',
  METER_VALUES = 'MeterValues'
}
