export class Coords {
  lat: number
  lng: number

  constructor (c: any) {
    this.lat = c.lat
    this.lng = c.lng
  }

  get valid (): boolean {
    return (this.lat !== undefined && this.lng !== undefined)
  }

  get invalid (): boolean {
    return !this.valid
  }
}

export class Bounds {
  ne: Coords
  sw: Coords

  constructor (obj) {
    this.ne = new Coords(obj.ne)
    this.sw = new Coords(obj.sw)
  }
}
