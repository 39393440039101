import { Echarger, Protocol } from './echarger.class'
import { Order } from './order.class'
import { TransactionState } from '../enums/transaction-state.enum'
import { Customer } from './customer.class'
import { EchargerProtocol } from '../enums/echarger-protocol.enum'
import { MessageEloaded, MessageOCPP } from '../enums/echarger-message.enum'
import { UnitOfMeasure } from '../enums/unit-of-measure'

const normalizeUnits = (value, unit) => {
  switch (unit) {
    case UnitOfMeasure.WH:
      // Wh -> kWh
      // Used Accuracy: One decimal points
      // simplification of Math.round((value / 1000) * 10) / 10
      return Math.round(value / 100) / 10
    case UnitOfMeasure.W:
      // W -> kW
      return Math.round(value / 100) / 10
    default:
      return value
  }
}

class SampledValue {
  value: string
  context: string
  format: string
  measurand: string
  phase: string
  location: string
  unit: string

  constructor (obj: Object) {
    if (!obj) {
      return
    }

    const properties = ['value', 'context', 'format', 'measurand', 'phase',
      'location', 'unit']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }
  }
}

export class MeterValue {
  timestamp: Date
  sampledValue: SampledValue[]

  constructor (obj: Object) {
    if (!obj) {
      return
    }

    if (obj['timestamp']) {
      this.timestamp = new Date(obj['timestamp'])
    }

    this.sampledValue = obj['sampledValue']
      .map((value) => new SampledValue(value))
  }
}

class TransactionData {
  status: string
  date: Date

  // ELOADED
  message: string
  type: string
  errorMessage: string
  errorCode: string
  other: string

  // type = 40
  chargerDate: Date
  guns: number
  gun1: string
  gun2: string
  gun3: string
  gun4: string
  imsuDSWVersion: string
  imsuDHWVersion: string
  imeuSWVersion: string
  imeuHWVersion: string
  imlu1SWVersion: string
  imlu2SWVersion: string
  imiu1SWVersion: string
  imiu2SWVersion: string
  protocolVersion: string

  // type = 41
  evccid: string
  chargeStatus: string
  targetCurrent: number
  targetVoltage: number
  evMaxCurrent: number
  evMaxVoltage: number
  presentCurrent: number
  presentVoltage: number
  presentPower: number
  gunMaxCurrent: number
  soc: number
  bulkSoc: number
  fullSoc: number
  startAmmeter: number
  realAmmeter: number
  chargeEnergy: number
  chargeTime: number
  connectorStatus: string
  rectifier: {
    pressureInput: number,
    temperatureInput: number,
    temperatureExist: number,
    pumpSpeed: number
  }
  oil: {
    pressureInput: number,
    temperatureInput: number,
    temperatureExist: number,
    temperatureInputConnectors: number,
    temperatureExistConnectors: number,
    pumpSpeed: number,
    pumpStatus: string
  }
  gunTemperaturePositiveBus: number
  gunTemperatureNegativeBus: number
  alarms: any[]
  chargeStopReason: string
  remainingTimeFullSoc: number

  // type = 45
  cmdType: string

  // OCPP
  action: string

  // action = StartTransaction
  connectorId: number
  idTag: string
  meterStart: number
  reservationId: number
  timestamp: Date

  // action = MeterValues
  // connectorId: number
  transactionId: number
  meterValue: MeterValue[]

  // action = StatusNotification
  // connectorId: number
  // errorCode: string
  info: string
  // chargeStatus: string
  // timestamp: Date
  vendorId: string
  vendorErrorCode: string

  // action = RemoteStartTransaction
  requestStatus: string

  // action = RemoteStopTransaction
  // requestStatus: string

  // action = Reset
  // requestStatus: string

  // action = TriggerMessage
  // requestStatus: string

  // action = UnlockConnector
  // requestStatus: string

  // action = BootNotification
  chargePointModel: string
  chargePointSerialNumber: string
  chargePointVendor: string
  firmwareVersion: string
  iccid: string
  imsi: string
  meterSerialNumber: string
  meterType: string

  // action = Heartbeat
  currentTime: Date

  // action = StopTransaction
  // idTag: string
  meterStop: number
  // timestamp: Date
  // transactionId: string
  reason: string
  transactionData: MeterValue[]

  // action = DataTransfer.req
  // vendorId: string
  messageId: string
  data: string

  // action = DataTransfer.conf
  // requestStatus: string
  // data: string

  constructor (obj: Object, protocol: string) {
    if (!obj) {
      return
    }

    const properties = ['status']

    if (protocol === EchargerProtocol.ELOADED) {
      this.rectifier = {
        pressureInput: undefined,
        temperatureInput: undefined,
        temperatureExist: undefined,
        pumpSpeed: undefined
      }

      this.oil = {
        pressureInput: undefined,
        temperatureInput: undefined,
        temperatureExist: undefined,
        temperatureInputConnectors: undefined,
        temperatureExistConnectors: undefined,
        pumpSpeed: undefined,
        pumpStatus: undefined
      }

      properties.push('message')
    } else if (protocol === EchargerProtocol.OCPP) {
      properties.push('action')

      switch (obj['action']) {
        case MessageOCPP.START_TRANSACTION:
          properties.push(
            'connectorId',
            'idTag',
            'meterStart',
            'reservationId',
            'timestamp'
          )
          break
        case MessageOCPP.METER_VALUES:
          properties.push(
            'connectorId',
            'transactionId'
          )

          this.meterValue = obj['meterValue']
            .map((value) => new MeterValue(value))
          break
        case MessageOCPP.STATUS_NOTIFICATION:
          properties.push(
            'connectorId',
            'errorCode',
            'info',
            'chargeStatus',
            'timestamp',
            'vendorId',
            'vendorErrorCode'
          )
          break
        case MessageOCPP.REMOTE_START_TRANSACTION:
        case MessageOCPP.REMOTE_STOP_TRANSACTION:
        case MessageOCPP.RESET:
        case MessageOCPP.TRIGGER_MESSAGE:
        case MessageOCPP.UNLOCK_CONNECTOR:
          properties.push('requestStatus')
          break
        case MessageOCPP.BOOT_NOTIFICATION:
          properties.push(
            'chargePointModel',
            'chargePointSerialNumber',
            'chargePointVendor',
            'firmwareVersion',
            'iccid',
            'imsi',
            'meterSerialNumber',
            'meterType'
          )
          break
        case MessageOCPP.STOP_TRANSACTION:
          properties.push(
            'idTag',
            'meterStop',
            'timestamp',
            'transactionId',
            'reason'
          )

          this.transactionData = (obj['transactionData'] || [])
            .map((value) => new MeterValue(value))

          break
        case MessageOCPP.DATA_TRANSFER:
          properties.push(
            'vendorId',
            'messageId',
            'data',
            'requestStatus'
          )
          break
      }
    }

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['date']) {
      this.date = new Date(obj['date'])
    }

    if (obj['timestamp']) {
      this.timestamp = new Date(obj['timestamp'])
    }

    if (obj['currentTime']) {
      this.currentTime = new Date(obj['currentTime'])
    }

    if (protocol === EchargerProtocol.ELOADED) {
      const info = obj['info']

      if (info) {
        this.type = info.cid2

        const infoProperties = ['other']

        switch (this.type) {
          case MessageEloaded.GUN_STAT:
            infoProperties.push(
              'guns',
              'imsuDSWVersion',
              'imsuDHWVersion',
              'imeuSWVersion',
              'imeuHWVersion',
              'imlu1SWVersion',
              'imlu2SWVersion',
              'imiu1SWVersion',
              'imiu2SWVersion',
              'protocolVersion'
            )
            break
          case MessageEloaded.CHARGE_INFO:
            infoProperties.push(
              'evccid',
              'chargeStatus',
              'targetCurrent',
              'targetVoltage',
              'evMaxCurrent',
              'evMaxVoltage',
              'evErrorCode',
              'presentCurrent',
              'presentVoltage',
              'gunMaxCurrent',
              'soc',
              'bulkSoc',
              'fullSoc',
              'startAmmeter',
              'realAmmeter',
              'chargeTime',
              'connectorStatus',
              'chargeStopReason',
              'remainingTimeFullSoc'
            )
            break
          case MessageEloaded.CHARGE_CONTROL:
            infoProperties.push('cmdType')
        }

        const ret = obj['rtn']
        // TODO check the object mapping
        if (ret) {
          if (ret.description) {
            this.errorMessage = ret.description
          }

          if (ret.value !== '00') {
            this.errorCode = ret.value
          }
        }

        for (const property in info) {
          if (infoProperties.includes(property)) {
            this[property] = info[property]
          }
        }

        switch (this.type) {
          case MessageEloaded.GUN_STAT:
            for (let i = 0; i < this.guns; i++) {
              if (info[`gun${ i + 1 }`]) {
                this[`gun${ i + 1 }`] = info[`gun${ i + 1 }`]
              }
            }

            if (info.date) {
              this.chargerDate = info.date
            }

            break
          case MessageEloaded.CHARGE_INFO:
            if (
              this.startAmmeter !== undefined &&
              this.realAmmeter !== undefined
            ) {
              this.chargeEnergy =
                (
                  Math.round(this.realAmmeter * 10) -
                  Math.round(this.startAmmeter * 10)
                ) / 10
            }

            if (info.pressureOfInputLiquidSideRectifier !== undefined) {
              this.rectifier.pressureInput =
                info.pressureOfInputLiquidSideRectifier
            }

            if (info.temperatureOfInputLiquidSideRectifier !== undefined) {
              this.rectifier.temperatureInput =
                info.temperatureOfInputLiquidSideRectifier
            }

            if (info.temperatureOfExistLiquidSideRectifier !== undefined) {
              this.rectifier.temperatureExist =
                info.temperatureOfExistLiquidSideRectifier
            }

            if (info.speedOfPumpRectifier !== undefined) {
              this.rectifier.pumpSpeed = info.speedOfPumpRectifier
            }

            if (info.pressureOfInputLiquidSideOil !== undefined) {
              this.oil.pressureInput = info.pressureOfInputLiquidSideOil
            }

            if (info.temperatureOfInputLiquidSideOil !== undefined) {
              this.oil.temperatureInput = info.temperatureOfInputLiquidSideOil
            }

            if (info.temperatureOfExistLiquidSideOil !== undefined) {
              this.oil.temperatureExist = info.temperatureOfExistLiquidSideOil
            }

            if (info.inputTemperatureOilConnectors !== undefined) {
              this.oil.temperatureInputConnectors =
                info.inputTemperatureOilConnectors
            }

            if (info.existTemperatureOilConnectors !== undefined) {
              this.oil.temperatureExistConnectors =
                info.existTemperatureOilConnectors
            }

            if (info.speedOfPumpOil !== undefined) {
              this.oil.pumpSpeed = info.speedOfPumpOil
            }

            if (info.oilPumpStatus !== undefined) {
              this.oil.pumpStatus = info.oilPumpStatus
            }

            if (info.gunTemperaturePositiveBus !== undefined) {
              this.gunTemperaturePositiveBus = info.gunTemperaturePositiveBus
            }

            if (info.gunTemperatureNegativeBus !== undefined) {
              this.gunTemperatureNegativeBus = info.gunTemperatureNegativeBus
            }

            if (info.alarm1) {
              this.alarms = this.alarms || []
              this.alarms.push(info.alarm1)
            }

            if (info.alarm2) {
              this.alarms = this.alarms || []
              this.alarms.push(info.alarm2)
            }

            if (info.alarm3) {
              this.alarms = this.alarms || []
              this.alarms.push(info.alarm3)
            }

            if (info.alarm4) {
              this.alarms = this.alarms || []
              this.alarms.push(info.alarm4)
            }

            break
        }
      }
    }
  }

  get currentPower (): number {
    if (this.presentCurrent && this.presentVoltage) {
      return Math.round(this.presentCurrent * this.presentVoltage / 100) / 10
    }

    if (this.meterValue && this.meterValue[0].sampledValue) {
      const power = this.meterValue[0].sampledValue
        .find(({ measurand }) => measurand === 'Power.Active.Import')

      if (power) {
        return normalizeUnits(power.value, power.unit)
      }

      const current = this.meterValue[0].sampledValue
          .find(({ measurand }) => measurand === 'Current.Import')

      const voltage = this.meterValue[0].sampledValue
          .find(({ measurand }) => measurand === 'Voltage')

      if (current && voltage) {
        return Math.round(
            normalizeUnits(current.value, current.unit) *
            normalizeUnits(voltage.value, voltage.unit) / 100
          ) / 10
      }
    }
  }
}

export class Transaction {
  _id: string
  customer: Customer
  orderNumber: number
  echarger: Echarger
  protocol: Protocol
  gun: number
  command: string
  user: string
  status: string
  data: TransactionData[] = []
  startDate: Date
  stopDate: Date
  approved: boolean
  powerLimit: number

  // inputs
  targetSoC: number
  allowedCurrent: number

  // EV options
  evOptions: {
    powerLimit: number,
    allowedCurrent: number
  }

  // data extraction
  evccid: string
  soc: number
  initialSoC: number
  energy: number
  chargeTime: number

  evseid: string

  // data extraction (system)
  sysMaxCurrent: number
  sysMaxVoltage: number
  active = true

  order: Order

  prechargeDuration: number

  constructor (obj: Object) {
    if (!obj) {
      return
    }

    const properties = ['_id', 'orderNumber', 'gun', 'command', 'user',
      'status', 'data', 'targetSoC', 'allowedCurrent', 'evccid', 'initialSoC',
      'soc', 'energy', 'chargeTime', 'evseid', 'approved', 'powerLimit',
      'evOptions', 'prechargeDuration']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['echarger']) {
      this.echarger = new Echarger(obj['echarger'])
    }

    if (obj['protocol']) {
      this.protocol = new Protocol(obj['protocol'])
    }

    if (obj['startDate']) {
      this.startDate = new Date(obj['startDate'])
    }

    if (obj['stopDate']) {
      this.stopDate = new Date(obj['stopDate'])
      this.active = false
    }

    if (Array.isArray(obj['data'])) {
      this.data = obj['data'].map((data) =>
        new TransactionData(data, this.protocol.name))

      if (obj['data'].length) {
        if (
          obj['data'][0]['info'] &&
          obj['data'][0]['info']['sysMaxCurrent'] !== undefined
        ) {
          this.sysMaxCurrent = obj['data'][0]['info']['sysMaxCurrent']
        }

        if (
          obj['data'][0]['info'] &&
          obj['data'][0]['info']['sysMaxVoltage'] !== undefined
        ) {
          this.sysMaxVoltage = obj['data'][0]['info']['sysMaxVoltage']
        }
      }
    }

    if (obj['order']) {
      this.order = new Order(obj['order'])
      if (!this.order.products[0].details.stopDate) {
        this.active = false
      }
    }

    if (obj['customer']) {
      this.customer = new Customer(obj['customer'])
    }
  }

  get transactionState (): TransactionState {
    return this.order
      ? this.active
        ? TransactionState.IN_PROGRESS
        : TransactionState.COMPLETED
      : TransactionState.CANCELED
  }
}

export class Connection {
  _id: string
  echarger: Echarger
  transactions: Transaction[]
  online = false

  constructor (obj: Object) {
    if (!obj) {
      return
    }

    const properties = ['_id', 'transactions', 'online']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['echarger']) {
      this.echarger = new Echarger(obj['echarger'])
    }

    if (Array.isArray(obj['transactions'])) {
      this.transactions = obj['transactions']
        .map((transaction) => {
          if (this.echarger) {
            transaction.echarger = this.echarger
          }

          return new Transaction(transaction)
        })
    }
  }
}
