import { Component, ViewChild } from '@angular/core'
import { PlugService } from 'src/app/services/plug.service'
import { FormGroup, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Plug } from 'src/app/classes/plug.class'
import { Vendor } from 'src/app/classes/vendor.class'
import { EchargerService } from 'src/app/services/echarger.service'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Echarger } from 'src/app/classes/echarger.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { EchargerProtocol } from 'src/app/enums/echarger-protocol.enum'
import { OCPPLegalType } from 'src/app/enums/ocpp-legal-type.enum'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'
import { VendorService } from 'src/app/services/vendor.service'

@Component({
  selector: 'app-search-echarger',
  templateUrl: './search-echarger.component.html',
  styleUrls: ['./search-echarger.component.scss']
})
export class SearchEchargerComponent {
  @ViewChild('plugsComponent') plugsComponent: CheckboxContainerComponent
  @ViewChild(MatPaginator) paginator: MatPaginator

  public searchEchargerForm: FormGroup = new FormGroup({
    name: new FormControl(),
    code: new FormControl(),
    ipv4Address: new FormControl(),
    protocol: new FormControl(),
    legal: new FormControl({ value: null, disabled: true }),
    vendor: new FormControl()
  })
  public plugs: Plug[] = []
  public vendors: Vendor[] = []
  public displayedColumns: string[] =
    ['NAME', 'PROTOCOL', 'CODE', 'EVSEIDS', 'VENDOR', 'IPV4ADDRESS', 'PRECHARGELIMIT', 'BUTTONS']
  public dataSource: MatTableDataSource<Echarger>
  public values = []
  public loading = false
  public loadingSearch = false
  public PROTOCOL = EchargerProtocol
  public protocols = Object.values(this.PROTOCOL)
  public LEGAL_TYPES = OCPPLegalType
  public legalTypes = Object.values(this.LEGAL_TYPES)

  public modal: Modal
  public alert: Alert

  constructor (
    private _translate: TranslateService,
    private _plug: PlugService,
    private _echarger: EchargerService,
    private _vendor: VendorService
  ) {
    this.getPlugs()
    this.getVendors()
  }

  public async search () {
    try {
      this.loadingSearch = true
      const query = { ...this.searchEchargerForm.value }

      if (query.plugs) { query['plugs'] = this.plugsComponent.selected }
      if (query.protocol) { query['protocol'] = { name: query['protocol'] } }

      const response = await this._echarger.searchEcharger(query).toPromise()

      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        this.loadingSearch = false
        return
      }

      this.dataSource = new MatTableDataSource(response.data)
      this.dataSource.paginator = this.paginator
      this.loadingSearch = false
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loadingSearch = false
    }
  }

  public showModal (el: Echarger): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_ECHARGER'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_ECHARGER')} ${el.name}?`,
      buttons: [{ title: this._translate.instant('BUTTON.DELETE') }],
      data: el._id
    })

    this.modal.confirm()
  }

  public async delete (_id: string): Promise<boolean> {
    try {
      const response = await this._echarger.deleteEcharger(_id).toPromise()
      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        return false
      }

      this.dataSource.data = this.dataSource.data.filter(d => d._id !== _id)
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.ECHARGER_DELETED')
      })
      this.alert.present()
      return true
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      return false
    }
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  private async getPlugs (): Promise<boolean> {
    try {
      const response = await this._plug
        .searchPlug({ active: true })
        .toPromise()

      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })

        this.alert.present()

        return false
      }

      this.plugs = response.data.data
        .sort((p1, p2) => p1.dataLong.localeCompare(p2.dataLong))

      return true
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })

      this.alert.present()

      return false
    }
  }

  private async getVendors (): Promise<boolean> {
    try {
      const response = await this._vendor.getAllVendors().toPromise()

      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })

        this.alert.present()

        return false
      }

      this.vendors = response.data.data
        .sort((v1, v2) => v1.name.localeCompare(v2.name))

      return true
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })

      this.alert.present()

      return false
    }
  }

  public getEvseids (echarger: Echarger) {
    return echarger.plugs
      .reduce((ac, e, i) => e.number ? [...ac, `${echarger.code}${i + 1}`] : ac, [])
  }

  public protocolChanged (protocol: string) {
    const legal = this.searchEchargerForm.get('legal')

    if (protocol === this.PROTOCOL.OCPP) {
      legal.enable()
    } else {
      legal.disable()
      legal.setValue(null)
    }
  }
}
